import IconBase, { IconBaseProps } from '../IconBase';

export const CustomLightIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V4C12.75 4.41421 12.4142 4.75 12 4.75C11.5858 4.75 11.25 4.41421 11.25 4V3C11.25 2.58579 11.5858 2.25 12 2.25ZM5.10541 5.10541C5.39831 4.81252 5.87318 4.81252 6.16607 5.10541L6.87318 5.81252C7.16607 6.10541 7.16607 6.58029 6.87318 6.87318C6.58029 7.16607 6.10541 7.16607 5.81252 6.87318L5.10541 6.16607C4.81252 5.87318 4.81252 5.39831 5.10541 5.10541ZM18.8946 5.10541C19.1875 5.39831 19.1875 5.87318 18.8946 6.16607L18.1875 6.87318C17.8946 7.16607 17.4197 7.16607 17.1268 6.87318C16.8339 6.58029 16.8339 6.10541 17.1268 5.81252L17.8339 5.10541C18.1268 4.81252 18.6017 4.81252 18.8946 5.10541ZM12 7.75C9.65279 7.75 7.75 9.65279 7.75 12C7.75 14.3472 9.65279 16.25 12 16.25C12.6046 16.25 13.1798 16.1237 13.7005 15.8961C15.2013 15.2402 16.25 13.7426 16.25 12C16.25 9.65279 14.3472 7.75 12 7.75ZM14.75 17.051C16.5374 16.0758 17.75 14.1795 17.75 12C17.75 8.82436 15.1756 6.25 12 6.25C8.82436 6.25 6.25 8.82436 6.25 12C6.25 14.1795 7.46262 16.0758 9.25 17.051V18.9996C9.25 20.5184 10.4812 21.7496 12 21.7496C13.5188 21.7496 14.75 20.5184 14.75 18.9996L14.75 17.051ZM13.25 17.6137C12.8476 17.703 12.4293 17.75 12 17.75C11.5707 17.75 11.1524 17.703 10.75 17.6137V18.9996C10.75 19.69 11.3096 20.2496 12 20.2496C12.6904 20.2496 13.25 19.69 13.25 18.9996L13.25 17.6137ZM2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H4C4.41421 11.25 4.75 11.5858 4.75 12C4.75 12.4142 4.41421 12.75 4 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12ZM19.25 12C19.25 11.5858 19.5858 11.25 20 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H20C19.5858 12.75 19.25 12.4142 19.25 12Z"
            fill="currentColor"
        />
    </IconBase>
);
