import IconBase, { IconBaseProps } from '../IconBase';

export const TriangleLeftIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1489 17.5045L8.07322 13.1761C7.30893 12.5244 7.30893 11.4715 8.07322 10.8197L13.1489 6.49135C14.3835 5.4385 16.5 6.19054 16.5 7.6779L16.5 16.3347C16.5 17.8221 14.3835 18.5574 13.1489 17.5045Z"
            fill="currentColor"
        />
    </IconBase>
);
