import { SvgIcon } from '@mui/material';
import { DotLoader } from '../../elements/DotLoader/DotLoader';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

const icon = { width: 20, height: 20 };
export const ButtonDotLoader = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon fontSize="small" inheritViewBox sx={icon} {...props}>
        <DotLoader />
    </SvgIcon>
);
