import { Avatar } from '@mui/material';
import logo from '../../../elements/IconComponents/clerk-logo-header.svg';

export const AssistantAvatar = () => (
    <Avatar
        src={logo}
        alt="AI Assistant"
        sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'grey.200',
        }}
    />
);
