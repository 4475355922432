import { OptionBase } from '../../../../elements/AdvancedSelect';
import { ianaTimeZones } from '../../../../utils/iana-timezones';

export const HOURS: OptionBase[] = [
    { id: '1', label: '01' },
    { id: '2', label: '02' },
    { id: '3', label: '03' },
    { id: '4', label: '04' },
    { id: '5', label: '05' },
    { id: '6', label: '06' },
    { id: '7', label: '07' },
    { id: '8', label: '08' },
    { id: '9', label: '09' },
    { id: '10', label: '10' },
    { id: '11', label: '11' },
    { id: '12', label: '12' },
];

export const AMPM: OptionBase[] = [
    { id: 'am', label: 'AM' },
    { id: 'pm', label: 'PM' },
];

export const TIMEZONES: OptionBase[] = ianaTimeZones.map((tz) => ({
    id: tz,
    label: tz,
}));
