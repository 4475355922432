import { SxProps } from '@mui/material';

export const loadingSpinner: SxProps = {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    width: 1,
};

export const loadingSpinnerProgress: SxProps = { width: 1 };
