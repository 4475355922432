import { PagedData } from '../types/PagedData';

export function findPageIndexInPagedData<T>(
    pagedData: PagedData<T>,
    predicate: (item: T) => boolean,
): [pageIndex: number, itemIndex: number] {
    for (let i = 0; i < pagedData.pages.length; i++) {
        const itemIndex = pagedData.pages[i].findIndex(predicate);
        if (itemIndex > -1) {
            return [i, itemIndex];
        }
    }
    return [-1, -1];
}

export function deleteDataInPagedData<T>(
    pagedData: PagedData<T>,
    predicate: (item: T) => boolean,
) {
    const [pageIndex, itemIndex] = findPageIndexInPagedData<T>(
        pagedData,
        predicate,
    );

    if (pageIndex > -1 && itemIndex > -1) {
        const newPage = pagedData.pages[pageIndex].slice();

        //remove data at index
        newPage.splice(itemIndex, 1);

        // swap old page with new page
        pagedData.pages[pageIndex] = newPage;
    }
    return pagedData;
}
