import { useMutation } from '@tanstack/react-query';
import { setRole } from '../api/roles';
import { queryClient } from './queryClient';
import { TEAMMATES_KEY } from './user';

export const useUpdateRole = () =>
    useMutation({
        mutationFn: setRole,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [TEAMMATES_KEY] });
        },
    });
