import { SxProps, Theme } from '@mui/material';
import { legacyMobileBreakpoint } from '../../theme/breakpoints';

export const inbox: SxProps<Theme> = (theme) => ({
    display: 'grid',
    minHeight: 0,
    height: 1,
    gridTemplateColumns: 'auto 1fr',

    '&.contactInfoShown': {
        gridTemplateColumns: 'auto 1fr auto',
    },

    '&.discussionShown': {
        gridTemplateColumns: 'auto 1fr 1fr',
    },

    [theme.breakpoints.down(legacyMobileBreakpoint)]: {
        height: 'calc(100vh - 120px)',
        gridTemplateColumns: '1fr',

        '&.contactInfoShown, &.discussionShown': {
            gridTemplateColumns: '1fr',
        },
    },
});

export const conversationsList: SxProps<Theme> = (theme) => ({
    height: 1,
    width: 300,

    '&.mobileHidden': {
        [theme.breakpoints.down(legacyMobileBreakpoint)]: {
            display: 'none',
        },
    },
});
