import IconBase, { IconBaseProps } from '../IconBase';

export const ShopifyLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M19.2165 5.35921C19.2015 5.25015 19.1053 5.18983 19.0259 5.18324C18.9466 5.17666 17.398 5.15329 17.398 5.15329C17.398 5.15329 16.1028 3.90441 15.9749 3.7773C15.8469 3.65019 15.597 3.68875 15.5 3.7171C15.4985 3.71752 15.2569 3.79181 14.8491 3.91715C14.7811 3.69769 14.6808 3.42815 14.5377 3.15731C14.0765 2.28386 13.4014 1.82152 12.5853 1.82034C12.5844 1.82034 12.5836 1.82034 12.5826 1.82034C12.526 1.82034 12.4696 1.82562 12.4132 1.83072C12.3892 1.80203 12.365 1.7736 12.3394 1.74647C11.9842 1.36893 11.5279 1.185 10.9819 1.2009C9.92777 1.2309 8.87853 1.9866 8.02748 3.32868C7.42839 4.27326 6.97327 5.45987 6.84384 6.37825C5.63378 6.7503 4.78714 7.01064 4.76883 7.01634C4.15801 7.20665 4.13881 7.22572 4.05871 7.79706C3.99808 8.22961 2.40002 20.5002 2.40002 20.5002L15.7947 22.8L21.6 21.3673C21.6 21.3673 19.2315 5.46826 19.2165 5.35921ZM7.59424 6.14748C7.95643 4.46605 9.37547 1.94353 11.0022 1.89708C11.2496 1.89193 11.458 1.94585 11.636 2.06216C11.3514 2.20893 11.076 2.41987 10.8177 2.69493C10.1482 3.40799 9.63535 4.51516 9.43084 5.5828C8.79262 5.77902 8.16846 5.97093 7.59424 6.14748ZM12.438 4.65835C11.7363 4.87405 10.9702 5.10958 10.2017 5.34584C10.4181 4.5241 10.8276 3.70596 11.3309 3.16997C11.5181 2.97059 11.78 2.74809 12.0901 2.62106C12.3819 3.22464 12.445 4.08029 12.438 4.65835ZM13.1387 4.44294C13.1309 3.91302 13.0671 3.17596 12.8185 2.5388C13.6183 2.68924 14.012 3.58767 14.1782 4.12336C13.87 4.21808 13.519 4.32599 13.1387 4.44294Z"
            fill="#95BF47"
        />
        <path
            d="M19.0259 5.1834C18.9466 5.17682 17.398 5.15344 17.398 5.15344C17.398 5.15344 16.1028 3.90456 15.9749 3.77745C15.9271 3.73003 15.8624 3.70578 15.795 3.69531L15.7954 22.8L21.6 21.3675C21.6 21.3675 19.2316 5.46842 19.2165 5.35936C19.2015 5.25031 19.1053 5.18998 19.0259 5.1834Z"
            fill="#5E8E3E"
        />
        <path
            d="M12.5776 8.14681L11.9033 10.6511C11.9033 10.6511 11.1512 10.3111 10.2596 10.367C8.95214 10.449 8.93825 11.2678 8.95129 11.473C9.02251 12.593 11.9902 12.8377 12.157 15.4611C12.2883 17.525 11.0544 18.9367 9.27652 19.0482C7.14308 19.1819 5.96832 17.932 5.96832 17.932L6.42039 16.0224C6.42039 16.0224 7.60262 16.908 8.54899 16.8487C9.16715 16.8099 9.38802 16.3108 9.36558 15.9577C9.27265 14.4967 6.85609 14.5827 6.70342 12.1821C6.57493 10.1617 7.91119 8.11488 10.8597 7.93001C11.9958 7.85884 12.5776 8.14681 12.5776 8.14681Z"
            fill="white"
        />
    </IconBase>
);
