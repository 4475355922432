import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const SearchIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                d="M17.3424 17.959C17.2602 17.9594 17.1789 17.9433 17.103 17.9119C17.0272 17.8804 16.9584 17.8341 16.9007 17.7756L12.984 13.8673C11.6198 15.0042 9.86972 15.5713 8.09795 15.4507C6.32618 15.33 4.66915 14.5309 3.4716 13.2196C2.27406 11.9083 1.62822 10.1857 1.66846 8.41027C1.7087 6.63486 2.43192 4.94332 3.68765 3.68759C4.94338 2.43186 6.63492 1.70864 8.41034 1.6684C10.1857 1.62816 11.9083 2.274 13.2197 3.47154C14.531 4.66909 15.3301 6.32612 15.4507 8.09789C15.5714 9.86966 15.0043 11.6197 13.8674 12.984L17.784 16.8923C17.9011 17.0095 17.9668 17.1683 17.9668 17.334C17.9668 17.4996 17.9011 17.6584 17.784 17.7756C17.7263 17.8341 17.6575 17.8804 17.5817 17.9119C17.5058 17.9433 17.4245 17.9594 17.3424 17.959ZM8.59236 2.95897C7.47984 2.95897 6.3923 3.28887 5.46728 3.90695C4.54225 4.52503 3.82128 5.40354 3.39554 6.43137C2.9698 7.45921 2.8584 8.59021 3.07544 9.68135C3.29249 10.7725 3.82821 11.7748 4.61489 12.5614C5.40156 13.3481 6.40383 13.8838 7.49498 14.1009C8.58612 14.3179 9.71712 14.2065 10.745 13.7808C11.7728 13.355 12.6513 12.6341 13.2694 11.709C13.8875 10.784 14.2174 9.69649 14.2174 8.58397C14.2152 7.0928 13.6218 5.66334 12.5674 4.60893C11.513 3.55451 10.0835 2.96117 8.59236 2.95897Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
