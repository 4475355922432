import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const CollapseIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.33031 9.23408L9.21591 12.6179C9.65042 13.1274 10.3523 13.1274 10.7868 12.6179L13.6724 9.23408C14.3743 8.411 13.873 7 12.8814 7H7.11021C6.11863 7 5.62841 8.411 6.33031 9.23408Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
