import cc from 'classcat';
import inboxStyles from '../../components/ChatComponents/index.module.scss';
import ContactPanel from '../ContactPanel';
import styles from './ChatEditContactContainer.module.scss';

interface ChatEditContactContainerProps {
    contactPhone: string;
    isContactInfoShown: boolean;
    onBack: () => void;
}

export function ChatEditContactContainer({
    contactPhone,
    isContactInfoShown,
    onBack,
}: ChatEditContactContainerProps) {
    return (
        <div className={styles['contact-panel-container']}>
            <ContactPanel
                contactPhone={contactPhone}
                className={cc([
                    inboxStyles.editContact,
                    {
                        [inboxStyles['mobile-visible']]: isContactInfoShown,
                    },
                ])}
                onBack={onBack}
            />
        </div>
    );
}
