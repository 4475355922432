import { OptionBase } from '../../../../elements/AdvancedSelect';

export type LastContactedOption = {
    duration: number;
} & OptionBase;

export const TIME_VALUES: LastContactedOption[] = [
    {
        id: '1',
        label: '1',
        duration: 1,
    },
    {
        id: '3',
        label: '3',
        duration: 3,
    },
    {
        id: '5',
        label: '5',
        duration: 5,
    },
    {
        id: '10',
        label: '10',
        duration: 10,
    },
    {
        id: '15',
        label: '15',
        duration: 15,
    },
    {
        id: '30',
        label: '30',
        duration: 30,
    },
    {
        id: 'never',
        label: 'Never',
        duration: 0,
    },
];

export type UnitOption = {
    unit: 'hour' | 'minute' | 'day' | 'month';
} & OptionBase;

export const UNITS: UnitOption[] = [
    {
        id: 'minute',
        label: 'Minutes',
        unit: 'minute',
    },
    {
        id: 'hour',
        label: 'Hours',
        unit: 'hour',
    },
    {
        id: 'day',
        label: 'Days',
        unit: 'day',
    },
    {
        id: 'month',
        label: 'Months',
        unit: 'month',
    },
];
