import styles from './DialogCloseButton.module.scss';
import Icon from '../../icons/Icon';

export interface DialogCloseButtonProps {
    onClick: () => void;
}

export const DialogCloseButton = (props: DialogCloseButtonProps) => (
    <button className={styles['root']} onClick={props.onClick}>
        <Icon name="close" />
    </button>
);
