import IconBase, { IconBaseProps } from '../IconBase';

export const MailIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M15 18L17 20L21 16M21 12V9.8C21 9.07362 21 8.50425 20.9736 8.04005M11 19H7.8C6.11984 19 5.27976 19 4.63803 18.673C4.07354 18.3854 3.6146 17.9265 3.32698 17.362C3 16.7202 3 15.8802 3 14.2V9.8C3 9.05259 3 8.47142 3.02878 8M20.9736 8.04005C20.9389 7.43053 20.8586 7.00232 20.673 6.63803C20.3854 6.07354 19.9265 5.6146 19.362 5.32698C18.7202 5 17.8802 5 16.2 5H7.8C6.11984 5 5.27976 5 4.63803 5.32698C4.07354 5.6146 3.6146 6.07354 3.32698 6.63803C3.14546 6.99429 3.06471 7.41168 3.02878 8M20.9736 8.04005L15.5693 11.645C14.2887 12.4963 13.6483 12.9219 12.9565 13.0873C12.3451 13.2335 11.7078 13.2335 11.0964 13.0873C10.4045 12.9219 9.76421 12.4963 8.48356 11.645L3.02878 8"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
