import { Message } from '../../../api/types';
import Icon from '../../../icons/Icon';
import dayjs from 'dayjs';

import { checkIsUntilReview } from '../../ConversationRow/Assistant/check-is-until-review';

type Props = {
    message: Pick<Message, 'senderType' | 'timestamp'>;
};

export const ScheduleTime = ({ message }: Props) => {
    if (checkIsUntilReview(message)) {
        return (
            <>
                <Icon name="mess-schedule" size="12px" />
                Waiting for review
            </>
        );
    }

    return (
        <>
            <Icon name="mess-schedule" size="12px" />
            Scheduled for {dayjs(message.timestamp).format('MMM D')} at{' '}
            {dayjs(message.timestamp).format('hh:mm A')}
        </>
    );
};
