import IconBase, { IconBaseProps } from '../IconBase';

export const SleepIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M12 14V11M12 6C7.58172 6 4 9.35786 4 13.5C4 17.6421 7.58172 21 12 21C16.4183 21 20 17.6421 20 13.5C20 11.5561 19.2112 9.78494 17.9172 8.4525M12 6C14.3448 6 16.454 6.94572 17.9172 8.4525M12 6V3M20 6.5L17.9172 8.4525M12 3H8.8M12 3H15.2"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
