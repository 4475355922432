import cc from 'classcat';
import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
    steps: string[];
    currentStep: number;
};

export const ProgressBar = (props: ProgressBarProps) => {
    const dotClasses = (index: number) =>
        cc([
            styles['root__step-dot'],
            index < props.currentStep && styles['root__step-dot_completed'],
            index === props.currentStep &&
                styles['root__step-dot_current_step'],
        ]);

    const nameClasses = (index: number) =>
        cc([
            styles['root__step-name'],
            index < props.currentStep && styles['root__step-name_completed'],
        ]);

    const barClasses = (index: number) =>
        cc([
            styles['root__step-bar'],
            index < props.currentStep && styles['root__step-bar_completed'],
        ]);
    return (
        <div className={styles['root']}>
            {props.steps.map((step, index) => (
                <div key={index} className={styles['root__step']}>
                    <div className={styles['root__step-container']}>
                        <span className={dotClasses(index)} />
                        <span className={nameClasses(index)}>{step}</span>
                    </div>
                    {index < props.steps.length - 1 && (
                        <div className={barClasses(index)} />
                    )}
                </div>
            ))}
        </div>
    );
};
