import { Components, Theme } from '@mui/material';

export const MuiToggleButtonGroup: Components<Theme>['MuiToggleButtonGroup'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            backgroundColor: theme.palette.custom.gray.disabledBackground,
            boxShadow: theme.shadows[1],
        }),

        grouped: ({ theme }) => ({
            '&:not(:first-of-type)': {
                marginRight: 0,
            },
            '&:not(:first-of-type), &:not(:last-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
        }),
    },
};
