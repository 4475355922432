import { ContactPropertyType } from '../../../api/types';
import { UserPropertyModel } from '../../../components/UserInfoProperty/types';

export const CSV_PARSER_OPTIONS = {
    header: true,
    preview: 1,
};

export const ACTION_OPTIONS = [
    { id: 'skip', label: 'Skip' },
    { id: 'existing-property', label: 'Existing Property' },
    { id: 'new-property', label: 'New Property' },
];

export const FILEDS_OPTIONS = [
    { id: 'name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'email', label: 'Email' },
];

export const PROP_TYPES_OPTIONS = [
    { id: ContactPropertyType.Date, label: 'Date' },
    { id: ContactPropertyType.Email, label: 'Email' },
    { id: ContactPropertyType.MultiSelect, label: 'MultiSelect' },
    { id: ContactPropertyType.Number, label: 'Number' },
    { id: ContactPropertyType.Phone, label: 'Phone' },
    { id: ContactPropertyType.Select, label: 'Select' },
    { id: ContactPropertyType.Text, label: 'Text' },
    { id: ContactPropertyType.URL, label: 'URL' },
    { id: ContactPropertyType.Boolean, label: 'Boolean' },
];

export type Usage = 'skip' | 'existing-property' | 'new-property';

type ItemType = 'field' | 'property';

export interface Column {
    id?: string;
    columnName: string;
    usage: Usage;
    itemType?: ItemType;
    field?: string;
    propertyName?: string;
    propertyType?: ContactPropertyType;
    options?: string[];
    dateFormat?: string;
    externalId?: string;
}

export const getPropertiesList = (properties: UserPropertyModel[]) => {
    const propsOptions = properties.map((p) => ({
        id: p.name,
        label: p.name,
    }));
    return FILEDS_OPTIONS.concat(propsOptions);
};
