import IconBase, { IconBaseProps } from '../IconBase';

export const TriangleDownIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.49547 10.8511L10.8239 15.9268C11.4756 16.6911 12.5285 16.6911 13.1803 15.9268L17.5086 10.8511C18.5615 9.61649 17.8095 7.5 16.3221 7.5H7.66531C6.17794 7.5 5.44262 9.61649 6.49547 10.8511Z"
            fill="currentColor"
        />
    </IconBase>
);
