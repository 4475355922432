import IconBase, { IconBaseProps } from '../IconBase';

export const TimeFrameIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.25 3C11.25 2.58579 11.5858 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 9.99674 2.85496 8.1325 3.89229 6.58281C4.1227 6.23859 4.58853 6.14633 4.93274 6.37674C5.27696 6.60715 5.36922 7.07298 5.13881 7.41719C4.26158 8.7277 3.75 10.303 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.69647 16.9549 4.16255 12.75 3.78363V6C12.75 6.41421 12.4142 6.75 12 6.75C11.5858 6.75 11.25 6.41421 11.25 6V3ZM7.46967 7.46967C7.76256 7.17678 8.23744 7.17678 8.53033 7.46967L12.5303 11.4697C12.8232 11.7626 12.8232 12.2374 12.5303 12.5303C12.2374 12.8232 11.7626 12.8232 11.4697 12.5303L7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967Z"
            fill="#667085"
        />
    </IconBase>
);
