import styles from './HintText.module.scss';

interface HintTextProps {
    text: string;
    variant: 'default' | 'error' | 'success';
}

export const HintText = (props: HintTextProps) => {
    return (
        <span className={styles['hint-text']} data-variant={props.variant}>
            {props.text}
        </span>
    );
};
