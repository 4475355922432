import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const PendingIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                d="M7.99992 14.6667C11.6809 14.6667 14.6666 11.681 14.6666 8.00004C14.6666 4.31908 11.6809 1.33337 7.99992 1.33337C4.31896 1.33337 1.33325 4.31908 1.33325 8.00004C1.33325 11.681 4.31896 14.6667 7.99992 14.6667ZM7.52371 4.19052C7.52371 3.92861 7.738 3.71432 7.99992 3.71432C8.26183 3.71432 8.47612 3.92861 8.47612 4.19052V7.77147L10.6785 9.53338C10.8833 9.69767 10.9166 9.99765 10.7523 10.2024C10.6594 10.3191 10.5214 10.381 10.3809 10.381C10.2761 10.381 10.1713 10.3476 10.0833 10.2762L7.70231 8.37148C7.59041 8.28099 7.52375 8.14529 7.52375 8.00004V4.19052H7.52371Z"
                fill="white"
            />
        </svg>
    </SvgIcon>
);
