import { SxProps } from '@mui/material';

export const statusText: SxProps = {
    display: 'inline-flex',
    alignItems: 'center',
};

export const statusIcon: SxProps = { width: 16, height: 16, marginRight: 1 };

export const image: SxProps = {
    width: 40,
};
