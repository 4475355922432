import { Box, SxProps } from '@mui/material';

const divider: SxProps = {
    width: 4,
    mx: 1.5,
    height: 4,
    borderRadius: '50%',
    backgroundColor: 'custom.gray.input',
};

export const DotDivider = () => <Box role="presentation" sx={divider} />;
