import client from './http';
import { ProfanityRecord } from './types';

export function getProfanity() {
    return client()
        .get<ProfanityRecord>('/profanity')
        .then((res) => res.data);
}

export function createProfanity(blackList: string[]) {
    return client()
        .post<ProfanityRecord>('/profanity', { blackList })
        .then((res) => res.data);
}

export function updateProfanity(blackList: string[]) {
    return client()
        .put<ProfanityRecord>('/profanity', { blackList })
        .then((res) => res.data);
}

export function deleteWordFromProfanity(wordToDelete: string) {
    return client()
        .post<ProfanityRecord>('/profanity/delete-word', { wordToDelete })
        .then((res) => res.data);
}
