import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            padding: theme.spacing(6),
        }),
    },
};
