import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles';

const HEIGHT = 6;

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
    defaultProps: {
        arrow: true,
        PopperProps: {
            disablePortal: true,
        },
    },
    styleOverrides: {
        tooltip: ({ theme }) => ({
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '133.333%',
            letterSpacing: '0.2px',
            padding: theme.spacing(1, 2),
            backgroundColor: theme.palette.primary.dark,
        }),
        popper: {
            '&[data-popper-placement*="top"] .MuiTooltip-arrow': {
                marginBottom: -HEIGHT,
            },
            '&[data-popper-placement*="bottom"] .MuiTooltip-arrow': {
                marginTop: -HEIGHT,
            },
            '&[data-popper-placement*="left"] .MuiTooltip-arrow': {
                marginRight: -HEIGHT,
            },
            '&[data-popper-placement*="right"] .MuiTooltip-arrow': {
                marginLeft: -HEIGHT,
            },
        },
        arrow: ({ theme }) => ({
            color: theme.palette.primary.dark,
            height: HEIGHT,
        }),
    },
};
