export const TOS = () => (
    <div>
        <p>Last updated Aug 9, 2022</p>
        <p>
            Thanks for signing up for Clerk Chat! We make it easy for you to
            communicate with your customers and employees via SMS, MMS, voice
            calls, and more.
        </p>
        <p>
            Be sure to give these Terms of Service a careful read, as they are a
            binding agreement between you, us, and any company you represent or
            identify as a user when you create an account. Depending on where
            your company and users are located (and other factors), strict laws
            may apply that dictate what kind of calls you’re permitted to make,
            what kind of text messages you’re allowed to send, whether and how
            recipients need to consent before you contact them, and more.
        </p>
        <p>
            We think it’s smart to get professional legal advice before
            communicating with your customers or others via calls or text
            messages. As we detail in Section 4.5 below, you bear full
            responsibility for making sure you are in compliance with all
            applicable laws regarding text messaging, voice calls, and any other
            services enabled by our apps.
        </p>
        <p>Thanks and happy messaging!</p>
        <p>The Clerk Chat Team</p>
        <p>
            The website located at{' '}
            <a href="https://clerk.chat">https://clerk.chat</a> (the “Website”),
            the Slack-based Clerk Chat application (the “Slack App”), and hosted
            services enabled or available via the Website and the Apps
            (collectively, the “Services”) are copyrighted works belonging to
            Clerk Chat Inc., doing business as Clerk Chat (“Clerk Chat”,
            “Clerk”, “Company,” “us,”, “our,” and “we”). Certain features of the
            Website, the Apps, and the Services (collectively, the “Company
            Properties”) may be subject to additional guidelines, terms, or
            rules, which will be posted on the Companies Properties in
            connection with such features. All such additional terms,
            guidelines, and rules are incorporated by reference into these
            Terms.
        </p>
        <p>
            THESE TERMS OF USE (THESE “TERMS”) SET FORTH A LEGALLY BINDING
            AGREEMENT THAT GOVERNS YOUR USE OF THE COMPANY PROPERTIES. BY
            ACCESSING OR USING THE COMPANY PROPERTIES, YOU ARE ACCEPTING THESE
            TERMS (PERSONALLY AND ON BEHALF OF ANY COMPANY OR OTHER LEGAL ENTITY
            THAT YOU REPRESENT WHEN USING THE COMPANY PROPERTIES OR THAT YOU
            NAME AS THE USER WHEN YOU CREATE AN ACCOUNT), AND YOU REPRESENT AND
            WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER
            INTO THESE TERMS AND TO BIND ANY COMPANY OR OTHER LEGAL ENTITY YOU
            REPRESENT TO THESE TERMS. YOU MAY NOT ACCESS OR USE THE COMPANY
            PROPERTIES OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD.
            IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO
            NOT ACCESS AND/OR USE THE COMPANY PROPERTIES.
        </p>
        <p>
            THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 12.2) ON AN
            INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR
            CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE
            EVENT OF A DISPUTE.
        </p>
        <h2>
            <strong>1. Definitions</strong>
        </h2>
        <p>
            “Authorized Users” means the individuals or entities that download
            and/or use the Apps, for example business owners or customer service
            agents who use the Services to send and receive messages.
        </p>
        <p>
            “Content” means all information, data, text, software, music, sound,
            photographs, graphics, video, messages, tags and/or other materials
            accessible through the Company Properties.
        </p>
        <p>
            “Company Properties” means the Website, the Apps, and the Services,
            collectively.
        </p>
        <p>
            “Customer” means you, the individual user of our Company Properties
            and any company or other legal entity, as applicable, that you
            represent while using the Company Properties or identify as the user
            when creating an Account to use the Services.
        </p>
        <p>
            “Recipients” means the individuals or entities that receive and/or
            reply via SMS, MMS or other messaging types to messages sent from
            the Services. Recipients who download and/or use the Web or Mobile
            Apps are also Authorized Users.
        </p>
        <p>
            “Services” means the functionality provided via the Software that
            (1) enables Authorized Users to send and receive Short Message
            Service (SMS) messages and may enable (depending on the type of
            Communications Account enabled) Multimedia Messaging Service (MMS)
            messages and other messaging types, (2) enables Authorized Users to
            organize, manage, and respond to communications, (3) provisions a
            phone number for use as an SMS, MMS, and/or voice response line, and
            (4) enables other features such as automated responses and other
            functionality which may be added to the Services over time.
        </p>
        <p>
            “User Content” means any Content you, or another Authorized User or
            Recipient uploads, posts, e-mails, sends in text messages, transmits
            or otherwise makes available (“Make Available”) through the Company
            Properties.
        </p>
        <p>
            The “Website” means the Company’s external-facing Website at{' '}
            <a href="https://clerk.chat">https://clerk.chat</a> and associated
            publicly-available sub-pages.
        </p>
        <h2>
            <strong>2. Accounts</strong>
        </h2>
        <p>
            2.1 Account Creation. In order to use certain features of the
            Company Properties, you must enable a number or service and provide
            certain information about yourself through the Slack App install.
            You represent and warrant that all information you submit, including
            your Slack Account name and other information about the company you
            represent, is truthful and accurate and that you will maintain the
            accuracy of such information. You may delete your Slack App at any
            time, for any reason, by following the instructions on the Company
            Properties or contacting us at 888-572-5375 or{' '}
            <a href="mailto:support@clerk.chat">support@clerk.chat</a>. Company
            may suspend or terminate your Account in accordance with Section 10.
        </p>
        <p>
            2.2 Account Responsibilities. You are responsible for maintaining
            the confidentiality of your Slack App login information and are
            fully responsible for all activities that occur under your Slack
            Account. You agree to immediately notify Company of any unauthorized
            use, or suspected unauthorized use of your Account or any other
            breach of security by emailing{' '}
            <a href="mailto:support@clerk.chat">support@clerk.chat</a>. Company
            cannot and will not be liable for any loss or damage arising from
            your failure to comply with the above requirements.
        </p>
        <h2>
            <strong>3. Access to the Company Properties</strong>
        </h2>
        <p>
            3.1 License. Subject to these Terms, Company grants you a
            non-transferable, non-exclusive, revocable, limited license to use
            and access the Company Properties on mobile devices or computers
            that you own or control solely for your own personal or internal
            business purposes, and not for further resale.
        </p>
        <p>
            3.2 User Restrictions. The rights granted to you in these Terms are
            subject to the following restrictions: (a) you shall not license,
            sell, rent, lease, transfer, assign, distribute, host, or otherwise
            commercially exploit the Company Properties, whether in whole or in
            part; (b) you shall not modify, make derivative works of,
            disassemble, reverse compile or reverse engineer any part of the
            Company Properties; (c) you shall not access the Company Properties
            in order to build a similar or competitive website, product, or
            service; and (d) except as expressly permitted herein, no part of
            the Company Properties may be copied, reproduced, distributed,
            republished, downloaded, displayed, posted or transmitted in any
            form or by any means. Unless otherwise indicated, any future
            release, update, or other addition to functionality of the Company
            Properties shall be subject to these Terms. All copyright and other
            proprietary notices on the Company Properties (or on any Content
            displayed on the Company Properties) must be retained on all copies
            thereof.
        </p>
        <p>
            3.3 Modifications. Company reserves the right, at any time, to
            modify, suspend, or discontinue the Company Properties in whole or
            in part. You agree that Company will not be liable to you or to any
            third party for any modification, suspension, or discontinuation of
            the Company Properties or any part thereof. You have the right to
            terminate your subscription as set forth in Sections 8.6 and 10.4.
        </p>
        <p>
            3.4 Ownership. Excluding any User Content (defined below), you
            acknowledge that all the intellectual property rights, including
            copyrights, patents, trademarks, and trade secrets, in the Company
            Properties and its Content are owned by Company or its licensors.
            Neither these Terms (nor your access to the Company Properties)
            transfers to you or any third party any rights, title or interest in
            or to such intellectual property rights, except for the limited
            access rights expressly set forth in Section 3.1. Company reserves
            all rights not granted in these Terms. There are no implied licenses
            granted under these Terms.
        </p>
        <p>
            3.5 Updates. You understand that the Company Properties are
            evolving. As a result, the Company may require you to accept updates
            to the Company Properties that you have installed on your computer
            or mobile device. You acknowledge and agree that the Company may
            update the Company Properties with or without notifying you. You may
            need to update third-party software (e.g. your devices operating
            system) from time to time to continue to use the Company Properties.
        </p>
        <h2>
            <strong>4. Content</strong>
        </h2>
        <p>
            4.1 User Content. You agree that you, and not the Company, are
            entirely responsible for all User Content that you Make Available
            through the Company Properties and that other users of the Company
            Properties and Recipients, and not the Company, are similarly solely
            responsible for all User Content that they Make Available through
            the Company Properties. You assume all risks associated with your
            use of User Content and for the use of your User Content by others,
            including any reliance on its accuracy, completeness or usefulness.
            We make no guarantees regarding the accuracy, currency, suitability,
            or quality of any Content. Your interactions with other Company
            Properties users are solely between you and such users. You agree
            that Company will not be responsible for any loss or damage incurred
            as a result of any such interactions. If there is a dispute between
            you and any Company Properties user, we are under no obligation to
            become involved. You hereby represent and warrant that your User
            Content does not violate our Acceptable Use Policy (defined in
            Section 4.5). You may not represent or imply to others that your
            User Content is in any way provided, sponsored or endorsed by
            Company. Company is not obligated to back up any User Content, and
            any User Content that violates our Acceptable Use Policy may be
            deleted by Company at any time without prior notice. You are solely
            responsible for creating and maintaining your own backup copies of
            your User Content if you desire.
        </p>
        <p>
            4.2 No Obligation to Pre-Screen Content. You acknowledge and agree
            that the Company has the right, but not the obligation, to
            pre-screen, refuse or remove any Content, including User Content,
            and you consent to such monitoring. You acknowledge and agree that
            you have no expectation of privacy concerning the transmission of
            your User Content or any User Content you receive from others in
            connection with your use of the Company Properties, including
            without limitation any chat, text, or voice communications. In the
            event that the Company pre-screens, refuses or removes any User
            Content, you acknowledge that the Company will do so for the
            Company’s benefit, not yours. Without limiting the foregoing, the
            Company shall have the right to remove any User Content that
            violates the Terms or that it otherwise deems objectionable.
        </p>
        <p>
            4.3 Storage, Accuracy and Deletion of Content. Unless expressly
            agreed to by the Company in writing elsewhere, the Company has no
            obligation to store any of the User Content that you Make Available
            on or receive through the Company Properties. The Company has no
            responsibility or liability for the deletion or accuracy of any
            Content, including User Content or for the failure to store,
            transmit or receive transmission of any Content or communications.
        </p>
        <p>
            4.4 User Content License. You hereby grant (and you represent and
            warrant that you have the right to grant) to Company an irrevocable,
            nonexclusive, royalty-free and fully paid, worldwide right and
            license to reproduce, distribute, display, prepare derivative works
            of, incorporate into other works, and otherwise use your User
            Content, and to grant sublicenses of the foregoing rights, in any
            media now existing or hereafter developed (including, without
            limitation, electronic storage), for the sole purpose of providing
            the Services to you. Company may also exercise the foregoing license
            for the purpose of improving its products and services. You hereby
            irrevocably waive (and agree to cause to be waived) any claims and
            assertions of moral rights or rights of attribution with respect to
            your User Content, as per above.
        </p>
        <p>
            4.5 Acceptable Use Policy. The following terms constitute our
            “Acceptable Use Policy”:
        </p>
        <p>
            (a) You agree not to use the Company Properties to collect, upload,
            transmit, display, distribute or Make Available any User Content:
            (i) that falsely impersonates any person or entity or falsely states
            or misrepresents your affiliation with any person or entity; (ii) in
            violation of any legal, contractual or fiduciary obligation or
            third-party right, including any copyright, trademark, patent, trade
            secret, moral right, privacy right, or right of publicity; (iii)
            that is unlawful, harassing, abusive, tortious, threatening,
            harmful, vulgar, defamatory, false, intentionally misleading, trade
            libelous, pornographic, obscene, or patently offensive; (iv) that
            promotes racism, bigotry, hatred, intimidation or violence against
            any group or individual; (v) that is harmful to minors; or (vi) that
            promotes illegal activities.
        </p>
        <p>
            (b) In addition, you agree not to: (i) upload, transmit, distribute
            or Make Available through the Company Properties any computer
            viruses, worms, or any software intended to damage or alter a
            computer system or data; (ii) send through the Company Properties
            unsolicited or unauthorized advertising, promotional materials, junk
            mail, spam, chain letters, pyramid schemes, or any form of
            unreasonably duplicative messages, whether commercial or otherwise;
            (iii) use the Company Properties to harvest, collect, gather or
            assemble information or data regarding other users, including e-mail
            addresses, without their consent; (iv) interfere with, disrupt, or
            create an undue burden on servers or networks connected to the
            Company Properties, or violate the regulations, policies or
            procedures of such networks; (v) attempt to gain unauthorized access
            to the Company Properties (or to other computer systems or networks
            connected to or used with the Company Properties), whether through
            password mining or any other means; (vi) harass or interfere with
            any other user’s use and enjoyment of the Company Properties; (vii)
            use software or automated agents or scripts to produce multiple
            accounts on the Company Properties, or to generate automated
            searches, requests, or queries to (or to strip, scrape, or mine data
            from) the Company Properties (provided, however, that we
            conditionally grant to the operators of public search engines
            revocable permission to use spiders to copy materials from the
            Company Properties for the sole purpose of and solely to the extent
            necessary for creating publicly available searchable indices of the
            materials, but not caches or archives of such materials, subject to
            the parameters set forth in our robots.txt file), (viii) use Company
            Properties in violation of any law or regulation, including without
            limitation, the federal Telephone Consumer Protection Act of 1991
            (47 U.S.C. § 227), as amended, Controlling the Assault of
            Non-Solicited Pornography and Marketing Act of 2003 (15 U.S.C. § 103
            et. seq), the United States’ National Do Not Call Registry, Rules
            for Carriers Subpart L “Restrictions on Telemarketing, Telephone
            Solicitation, and Facsimile Advertising” (47 U.S.C. § 64.1200), and
            (if applicable) the Canada Anti-SPAM Legislation (S.C. 2010, c. 23
            as amended); (viii) use, without the Company’s prior express written
            consent in each instance, the Company Properties to provision an
            emergency reporting or response system (e.g., Enhanced 911 or E911
            emergency service); (ix) to support the operation of a life support
            system, nuclear facility, aircraft navigation system, air traffic
            control system or other system critical to human life or safety or
            (x) assist or encourage any other person or entity to engage in any
            of the foregoing prohibited activities.
        </p>
        <p>
            4.6 Your Phone Numbers. The Company may offer the opportunity to
            enable you to use one or more existing phone numbers with the
            Services. If you use such a feature, you represent and warrant that
            (i) any phone number you register or associate with your Account is
            not a mobile number, (ii) you will only use the Services for legal
            purposes and will comply with all applicable laws and regulations in
            connection with your use of the Services and any number you register
            or associate with your Account, (iii) you have procured any and all
            necessary rights and licenses, and have the full power and
            authority, necessary to use any phone number you register or
            associate with your Account, and (iv) you will not use the services
            on a phone number that has been exchanged with, rented, or purchased
            from a third party unless you have received permission from the
            third party to do so.
        </p>
        <p>
            4.7 Enforcement. If Company becomes aware of any possible violations
            by you of the Terms, Company reserves the right to investigate such
            violations. If, as a result of the investigation, Company believes
            that unlawful activity has occurred, Company reserves the right to
            refer the matter to, and to cooperate with, any and all relevant
            legal authorities. Company is entitled, except to the extent
            prohibited by applicable law, to disclose any information or
            materials on or in Company Properties, including your User Content,
            in Company’s possession in connection with your use of Company
            Properties, to (1) comply with applicable laws, legal process or
            governmental requests; (2) enforce the Terms, (3) respond to any
            claims that User Content violates the rights of third parties, (4)
            respond to your requests for customer service, or (5) protect the
            rights, property or personal safety of Company, its users or the
            public, and all enforcement or other government officials, as
            Company in its sole discretion believes to be necessary or
            appropriate. We further reserve the right to take appropriate action
            against you in our sole discretion if you violate the Acceptable Use
            Policy or any other provision of these Terms, including, without
            limitation removing or modifying your User Content and terminating
            your Account.
        </p>
        <p>
            4.8 Feedback. You agree that your submission of any ideas,
            suggestions, feedback and/or proposals to Company (“Feedback”) is at
            your own risk and that Company has no obligations (including without
            limitation obligations of confidentiality) with respect to such
            Feedback. Company will treat any Feedback you provide to Company as
            non-confidential and non-proprietary. You agree that you will not
            submit to Company any information or ideas that you consider to be
            confidential or proprietary. You represent and warrant that you have
            all rights necessary to submit the Feedback. You hereby grant to
            Company a fully paid, royalty-free, perpetual, irrevocable,
            worldwide, non-exclusive right and license to use, reproduce,
            perform, display, distribute, adapt, modify, re-format, create
            derivative works of, and otherwise commercially or non-commercially
            exploit in any manner, any and all Feedback, and to sublicense the
            foregoing rights, for any purpose.
        </p>
        <h2>
            <strong>5. Indemnification</strong>
        </h2>
        <p>
            5.1 You agree to indemnify, defend, and hold Company, its parents,
            subsidiaries, affiliates, officers, employees, agents,
            representatives, partners and licensors (collectively, “Company
            Parties”) harmless from any claims, suits, actions, damages, losses,
            costs, liabilities and expenses, including reasonable attorneys’
            fees (“Claims”) relating to or arising out of: (a) User Content; (b)
            your breach of the Terms, (c) your violation of any rights of any
            Recipients or other users of the Company Properties, or (d) your
            violation of any applicable laws, rules or regulations. Company
            reserves the right, at its own cost, to assume the exclusive defense
            and control of any matter otherwise subject to indemnification by
            you, in which event you will fully cooperate with Company in
            asserting any available defenses. You agree that the provisions in
            this section will survive any termination of your Account, the Terms
            or your access to Company Properties.
        </p>
        <h2>
            <strong>6. DISCLAIMER OF WARRANTIES AND CONDITIONS</strong>
        </h2>
        <p>
            6.1 (a) YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT
            PERMITTED BY APPLICABLE LAW, YOUR USE OF COMPANY PROPERTIES IS AT
            YOUR SOLE RISK, AND COMPANY PROPERTIES ARE PROVIDED ON AN “AS IS”
            AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. COMPANY PARTIES EXPRESSLY
            DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY
            KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        </p>
        <p>
            (b) COMPANY PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION
            THAT: (1) COMPANY PROPERTIES WILL MEET YOUR REQUIREMENTS; (2) YOUR
            USE OF COMPANY PROPERTIES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
            ERROR-FREE; OR (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF
            COMPANY PROPERTIES WILL BE ACCURATE OR RELIABLE.
        </p>
        <p>
            (c) ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH
            COMPANY PROPERTIES IS ACCESSED AT YOUR OWN RISK, AND YOU SHALL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY, INCLUDING, BUT
            NOT LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO
            ACCESS COMPANY PROPERTIES, OR ANY OTHER LOSS THAT RESULTS FROM
            ACCESSING SUCH CONTENT.
        </p>
        <p>
            (c) THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER
            DISRUPTIONS. COMPANY MAKES NO WARRANTY, REPRESENTATION OR CONDITION
            WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO, THE QUALITY,
            EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF SERVICES.
        </p>
        <p>
            (d) NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
            COMPANY OR THROUGH COMPANY PROPERTIES WILL CREATE ANY WARRANTY NOT
            EXPRESSLY MADE HEREIN.
        </p>
        <p>
            (e) FROM TIME TO TIME, COMPANY MAY OFFER NEW “BETA” FEATURES OR
            TOOLS WITH WHICH ITS USERS MAY EXPERIMENT. SUCH FEATURES OR TOOLS
            ARE OFFERED SOLELY FOR EXPERIMENTAL PURPOSES AND WITHOUT ANY
            WARRANTY OF ANY KIND, AND MAY BE MODIFIED OR DISCONTINUED AT
            COMPANY’S SOLE DISCRETION. THE PROVISIONS OF THIS SECTION APPLY WITH
            FULL FORCE TO SUCH FEATURES OR TOOLS.
        </p>
        <p>
            (f) SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
            WARRANTIES, REPRESENTATIONS OR CONDITIONS, THE LIMITATION OR
            EXCLUSION OF IMPLIED WARRANTIES, OR LIMITATIONS ON HOW LONG AN
            IMPLIED WARRANTY MAY LAST, SO SOME OF THE ABOVE LIMITATIONS MAY NOT
            APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>
        <p>
            6.2 NO LIABILITY FOR CONDUCT OF OTHER USERS AND THIRD PARTIES. YOU
            ACKNOWLEDGE AND AGREE THAT COMPANY PARTIES ARE NOT LIABLE, AND YOU
            AGREE NOT TO SEEK TO HOLD COMPANY PARTIES LIABLE, FOR THE CONDUCT OF
            OTHER USERS OR ANY OTHER THIRD PARTIES, INCLUDING OPERATORS OF
            EXTERNAL SITES, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES
            RESTS ENTIRELY WITH YOU. YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR
            COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF COMPANY
            PROPERTIES. YOU UNDERSTAND THAT COMPANY DOES NOT MAKE ANY ATTEMPT TO
            VERIFY THE STATEMENTS OF USERS OF COMPANY PROPERTIES.
        </p>
        <h2>
            <strong>7. LIMITATION OF LIABILITY</strong>
        </h2>
        <p>
            7.1 DISCLAIMER OF CERTAIN DAMAGES. YOU UNDERSTAND AND AGREE THAT IN
            NO EVENT SHALL COMPANY PARTIES BE LIABLE FOR ANY LOSS OF PROFITS,
            REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
            DAMAGES ARISING OUT OF OR IN CONNECTION WITH COMPANY PROPERTIES, OR
            DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR USE, BUSINESS
            INTERRUPTION, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, WHETHER
            OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
            ARISING OUT OF OR IN CONNECTION WITH: (1) THE USE OR INABILITY TO
            USE COMPANY PROPERTIES; (2) THE COST OF PROCUREMENT OF SUBSTITUTE
            GOODS OR SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR
            SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED FOR TRANSACTIONS
            ENTERED INTO THROUGH COMPANY PROPERTIES; (3) UNAUTHORIZED ACCESS TO
            OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (4) STATEMENTS OR
            CONDUCT OF ANY THIRD PARTY ON COMPANY PROPERTIES; OR (5) ANY OTHER
            MATTER RELATED TO COMPANY PROPERTIES, WHETHER BASED ON WARRANTY,
            COPYRIGHT, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY,
            STATUTORY LAW OR ANY OTHER LEGAL THEORY. THE FOREGOING LIMITATIONS
            ON LIABILITY SHALL NOT APPLY TO LIABILITY OF A COMPANY PARTY FOR (A)
            DEATH OR PERSONAL INJURY CAUSED BY A COMPANY PARTY’S NEGLIGENCE OR
            WILLFUL MISCONDUCT; OR (B) ANY INJURY CAUSED BY A COMPANY PARTY’S
            FRAUD OR FRAUDULENT MISREPRESENTATION. THE FOREGOING LIMITATIONS OF
            LIABILITY APPLY TO THE FULLEST EXTENT ALLOWED BY LAW.
        </p>
        <p>
            7.2 CAP ON LIABILITY. UNDER NO CIRCUMSTANCES WILL THE AGGREGATE
            LIABILITY OF THE COMPANY PARTIES TO YOU IN CONNECTION WITH ANY CLAIM
            RELATING TO THESE TERMS OR THE COMPANY PROPERTIES EXCEED THE GREATER
            OF (A) THE TOTAL AMOUNT PAID TO COMPANY BY YOU DURING THE
            SUBSCRIPTION PERIOD PRIOR TO THE ACT, OMISSION OR OCCURRENCE GIVING
            RISE TO SUCH LIABILITY, AND (B) ONE HUNDRED DOLLARS ($100). THE
            FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY OF A COMPANY
            PARTY FOR (A) DEATH OR PERSONAL INJURY CAUSED BY A COMPANY PARTY’S
            NEGLIGENCE OR WILLFUL MISCONDUCT; OR FOR (B) ANY INJURY CAUSED BY A
            COMPANY PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION. THE FOREGOING
            CAPS ON LIABILITY APPLY TO THE FULLEST EXTENT ALLOWED BY LAW.
        </p>
        <p>
            7.3 BASIS OF THE BARGAIN. THE LIMITATIONS OF DAMAGES AND LIABILITY
            SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
            BETWEEN COMPANY AND YOU.
        </p>
        <h2>
            <strong>8. Fees and Purchase Terms</strong>
        </h2>
        <p>
            8.1 General Purpose of Terms: Sale of Service, not Software. The
            purpose of the Terms is for you to secure access to the Services.
            All fees set forth within and paid by you under the Terms shall be
            considered solely in furtherance of this purpose. In no way are
            these fees paid considered payment for the sale, license, or use of
            Company’s Software, and, furthermore, any use of Company’s Software
            by you in furtherance of the Terms will be considered merely in
            support of the purpose of the Terms.
        </p>
        <p>
            8.2 Payment. You agree to pay all fees and charges to your Account
            in accordance with the fees, charges and billing terms in effect at
            the time a fee or charge is due and payable. You must provide
            Company with a valid credit card (Visa, MasterCard, or any other
            issuer accepted by us) (“Payment Provider”), or purchase order
            information as a condition to signing up for the applicable
            Services. Your Payment Provider agreement governs your use of the
            designated credit card, and you must refer to that agreement and not
            the Terms to determine your related rights and liabilities. We
            currently use Stripe Inc. (“Stripe”) as providers for payment
            services (e.g., credit card transaction processing, merchant
            settlement, and related services). By purchasing any of our
            Services, you agree to be bound by Stripe’s U.S. Terms of Service
            available at{' '}
            <a href="https://stripe.com/us/terms">
                https://stripe.com/us/terms
            </a>{' '}
            and Privacy Policy available at{' '}
            <a href="https://stripe.com/us/privacy">
                https://stripe.com/us/privacy
            </a>
            . You hereby consent to provide and authorize the Company, Stripe,
            and any other payment services provider we may engage to share any
            information and payment instructions you provide to the extent
            required to complete the payment transactions in accordance with
            these Terms, including personal, financial, credit card payment, and
            transaction information. By providing Company with your credit card
            number and associated payment information, you agree that Company is
            authorized to invoice your Account for all fees and charges due and
            payable to Company hereunder and that no additional notice or
            consent is required. You agree to immediately notify Company of any
            change in your billing address or the credit card account used for
            payment hereunder. Company reserves the right at any time to change
            its prices and billing methods, either upon posting on Company
            Properties or by e-mail delivery to you.
        </p>
        <p>
            8.3 Service Subscription Fees. You will be responsible for payment
            of the applicable fee for any Services (each, a “Service
            Subscription Fee”) at the time you create your Account and select
            your service tier (each, a “Service Commencement Date”). Except as
            set forth in the Terms, all fees for the Services are
            non-refundable.
        </p>
        <p>
            8.4 Taxes. Unless otherwise noted on the Company Properties,
            Company’s fees are net of any applicable Sales Tax. If any Services,
            or payments for any Services, under the Terms are subject to Sales
            Tax in any jurisdiction and you have not remitted the applicable
            Sales Tax to Company, you will be responsible for the payment of
            such Sales Tax and any related penalties or interest to the relevant
            tax authority, and you will indemnify Company for any liability or
            expense we may incur in connection with such Sales Taxes. Upon our
            request, you will provide us with official receipts issued by the
            appropriate taxing authority, or other such evidence that you have
            paid all applicable taxes. For purposes of this section, “Sales Tax”
            shall mean any sales or use tax, and any other tax measured by sales
            proceeds, that Company is permitted to pass to its customers.
        </p>
        <p>
            8.5 Withholding Taxes. You agree to make all payments of fees to
            Company free and clear of, and without reduction for, any
            withholding taxes. Any such taxes imposed on payments of fees to
            Company will be your sole responsibility, and you will provide
            Company with official receipts issued by the appropriate taxing
            authority, or such other evidence as we may reasonably request, to
            establish that such taxes have been paid.
        </p>
        <p>
            8.6 Automatic Renewal. Unless otherwise noted on the Company
            Properties, paid subscriptions will automatically renew on a monthly
            basis, and your payment card or other payment method on file will be
            charged the Company’s then-current subscription fee for the plan you
            select on the date that you sign up and at the beginning of each
            subsequent monthly renewal period (each a “Renewal Commencement
            Date”) until you cancel. Renewal Commencement Dates ordinarily will
            fall on the monthly anniversary date of the date you signed up for
            your paid service plan. However, if you sign up for a paid plan on
            the 29th, 30th or 31st of a particular month, your subsequent
            Renewal Commencement Date for any months that have fewer than 29, 30
            or 31 days, as applicable, will be the last day of the applicable
            month. You agree that your Account will be subject to this automatic
            renewal feature unless you cancel your subscription prior to the
            Renewal Commencement Date by contacting the Company at 888-572-5375
            or <a href="mailto:billing@Clerk.Chat">billing@Clerk.Chat</a>. If
            you cancel your subscription, you may use your subscription until
            the end of your then-current subscription term; your subscription
            will not be renewed after your then-current term expires. However,
            you will not be eligible for a prorated refund of any portion of the
            subscription fee paid for the then-current subscription period. By
            subscribing, you authorize Company to charge your Payment Provider
            now, and again at the beginning of any subsequent subscription
            period. Upon renewal of your subscription, if Company does not
            receive payment from your Payment Provider, (i) you agree to pay all
            amounts due on your Account upon demand, and/or (ii) you agree that
            Company may either terminate or suspend your subscription and
            continue to attempt to charge your Payment Provider until payment is
            received (upon receipt of payment, your Account will be activated
            and for purposes of automatic renewal, your new subscription
            commitment period will begin as of the day payment was received).
        </p>
        <p>
            8.7 Free Trials and Other Promotions. Any free trial or other
            promotion that provides Registered User level access to the Services
            must be used within the specified time of the trial. Unless
            otherwise noted in the applicable offer description, at the end of
            the trial period, your use of that Service will expire and any
            further use of the Service is prohibited unless you pay the
            applicable subscription fee. If you are inadvertently charged for a
            subscription, please contact Company to have the charges reversed.
        </p>
        <h2>
            <strong>9. Copyright Policy</strong>
        </h2>
        <p>
            Company respects the intellectual property rights of others and asks
            that users of our Company Properties do the same. In connection with
            our Company Properties, we have adopted and implemented a policy
            respecting copyright law that provides for the removal of any
            infringing materials and for the termination, in appropriate
            circumstances, of users of our Company Properties who are repeat
            infringers of intellectual property rights, including copyrights. If
            you believe that one of our users is, through the use of our Company
            Properties, unlawfully infringing the copyright(s) in a work, and
            wish to have the allegedly infringing material removed, the
            following information in the form of a written notification
            (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated
            Copyright Agent:
        </p>
        <ol>
            <li>your physical or electronic signature;</li>
            <li>
                identification of the copyrighted work(s) that you claim to have
                been infringed;
            </li>
            <li>
                identification of the material on our Services that you claim is
                infringing and that you request us to remove;
            </li>
            <li>
                sufficient information to permit us to locate such material;
            </li>
            <li>your address, telephone number, and e-mail address;</li>
            <li>
                a statement that you have a good faith belief that use of the
                objectionable material is not authorized by the copyright owner,
                its agent, or under the law; and
            </li>
            <li>
                a statement that the information in the notification is
                accurate, and under penalty of perjury, that you are either the
                owner of the copyright that has allegedly been infringed or that
                you are authorized to act on behalf of the copyright owner.
            </li>
        </ol>
        <p>
            Please note that, pursuant to 17 U.S.C. § 512(f), any
            misrepresentation of material fact (falsities) in a written
            notification automatically subjects the complaining party to
            liability for any damages, costs and attorney’s fees incurred by us
            in connection with the written notification and allegation of
            copyright infringement.
        </p>
        <h2>
            <strong>10. Term and Termination.</strong>
        </h2>
        <p>
            10.1 Term. The Terms commence on the date when you accept them (as
            described in the preamble above) and remain in full force and effect
            while you use Company Properties, unless terminated earlier in
            accordance with the Terms.
        </p>
        <p>
            10.2 Prior Use. Notwithstanding the foregoing, if you used Company
            Properties prior to the date you accepted the Terms, you hereby
            acknowledge and agree that the Terms commenced on the date you first
            used Company Properties (whichever is earlier) and will remain in
            full force and effect while you use Company Properties, unless
            earlier terminated in accordance with the Terms.
        </p>
        <p>
            10.3 Termination of Services by Company. We may suspend or terminate
            your rights to use the Company Properties (including your Account)
            at any time if (a) you breach any of your obligations under this
            agreement, including, without limitation, your payment obligations
            or the Acceptable Use Policy in Section 4.5, (b) you violate any
            applicable law, rule or regulation, (c) Company is required to do so
            by law (e.g., where the provision of the Website, the Application,
            the Software or the Services is, or becomes, unlawful) or (d) we
            determine that for any reason, in our discretion, that your use of
            the Company Properties will cause substantial harm to Company or any
            of its affiliates, licensors, or other users. You agree that Company
            shall not be liable to you or any third party for any termination of
            your Account.
        </p>
        <p>
            10.4 Termination of Services by You. If you want to terminate the
            Services provided by Company, you may do so by (a) notifying Company
            at any time and (b) closing your Account for all of the Services
            that you use. Your notice must be sent, in writing, to Company’s
            address set forth below in Section 12.10. THE SERVICES WILL CONTINUE
            AT THE END OF EACH SUBSCRIPTION PERIOD UNLESS YOU CANCEL YOUR
            SUBSCRIPTION IN ACCORDANCE WITH THE PROCEDURE SET FORTH IN SECTION
            8.6.
        </p>
        <p>
            10.5 Effect of Termination. Termination of any Service includes
            removal of access to such Service and barring of further use of the
            Service. Termination of all Services may also include deletion of
            your password and all related information, files and Content
            associated with your Account (or any part thereof), including Your
            User Content. Upon termination of any Service, your right to use
            such Service will automatically terminate immediately. You
            understand that any termination of Services may involve deletion of
            your User Content associated therewith from our live databases.
            Company will not have any liability whatsoever to you for any
            suspension or termination, including for deletion of Content. All
            provisions of the Terms which by their nature should survive, shall
            survive termination of Services and your access to the Company
            Services, including without limitation, ownership provisions, User
            Content licenses, warranty disclaimers, indemnification obligations,
            all provisions relating to dispute resolution and arbitration,
            governing law and limitations of liability.
        </p>
        <p>
            10.6 No Subsequent Registration. If your registration(s) with or
            ability to access Company Properties is discontinued by Company due
            to your violation of any portion of the Terms, then you agree that
            you shall not attempt to re-register with or access Company
            Properties through use of a different user name or otherwise, and
            you acknowledge that you will not be entitled to receive a refund
            for fees related to those Company Properties to which your access
            has been terminated. In the event that you violate the immediately
            preceding sentence, Company reserves the right, in its sole
            discretion, to immediately take any or all of the actions set forth
            herein without any notice or warning to you.
        </p>
        <h2>
            <strong>11. General</strong>
        </h2>
        <p>
            11.1 Changes. These Terms are subject to occasional revision, and if
            we make any substantial changes, we may notify you by sending you an
            e-mail to the last e-mail address you provided to us (if any),
            and/or by posting notice of the changes on our Company Properties.
            You are responsible for providing us with your most current e-mail
            address. In the event that the last e-mail address that you have
            provided us is not valid, or for any reason is not capable of
            delivering to you the notice described above, our dispatch of the
            e-mail containing such notice will nonetheless constitute effective
            notice of the changes described in the notice. Unless otherwise
            stated on the Company Properties or applicable notice, any changes
            to these Terms will be effective upon posting of the changes on our
            Company Properties. Your continued use of our Company Properties
            following posting of such changes shall indicate your
            acknowledgement of such changes and agreement to be bound by the
            amended Terms.
        </p>
        <p>
            11.2 Dispute Resolution. Please read this Arbitration Agreement
            carefully. It is part of your contract with Company and affects your
            rights. It contains procedures for MANDATORY BINDING ARBITRATION AND
            A CLASS ACTION WAIVER.
        </p>
        <ol>
            <li>
                Applicability of Arbitration Agreement. All claims and disputes
                (excluding claims for injunctive or other equitable relief as
                set forth below) in connection with these Terms or the use,
                operation, marketing or advertising of the Company Properties
                that cannot be resolved informally or in small claims court
                shall be resolved by binding arbitration on an individual basis
                under the terms of this Arbitration Agreement. Unless otherwise
                agreed to, all arbitration proceedings shall be held in English.
                This Arbitration Agreement applies to you and the Company, and
                to any subsidiaries, affiliates, agents, employees, predecessors
                in interest, successors, and assigns, as well as all authorized
                or unauthorized users or beneficiaries of Services or goods
                provided under the Terms.
            </li>
            <li>
                Notice Requirement and Informal Dispute Resolution. Before
                either party may seek arbitration, the party must first send to
                the other party a written Notice of Dispute (“Notice”)
                describing the nature and basis of the claim or dispute, and the
                requested relief. A Notice to the Company should be sent to:
                Clerk Chat Inc., 16192 Coastal Highway. Lewes, DE 19958. After
                the Notice is received, you and the Company may attempt to
                resolve the claim or dispute informally. If you and the Company
                do not resolve the claim or dispute within thirty (30) days
                after the Notice is received, either party may begin an
                arbitration proceeding. The amount of any settlement offer made
                by any party may not be disclosed to the arbitrator until after
                the arbitrator has determined the amount of the award, if any,
                to which either party is entitled.
            </li>
            <li>
                Arbitration Rules. The Federal Arbitration Act governs the
                interpretation and enforcement of this Arbitration Agreement.
                Arbitration shall be initiated through JAMS, an established
                alternative dispute resolution provider (“ADR Provider”) that
                offers arbitration as set forth in this section. Disputes
                involving claims and counterclaims with an amount in controversy
                under $250,000, not inclusive of attorneys’ fees and interest,
                shall be subject to JAMS’ most current version of the
                Streamlined Arbitration Rules and procedures available at{' '}
                <a href="http://www.jamsadr.com/rules-streamlined-arbitration/;">
                    http://www.jamsadr.com/rules-streamlined-arbitration/;
                </a>{' '}
                all other claims shall be subject to JAMS’s most current version
                of the Comprehensive Arbitration Rules and Procedures, available
                at{' '}
                <a href="http://www.jamsadr.com/rules-comprehensive-arbitration/">
                    http://www.jamsadr.com/rules-comprehensive-arbitration/
                </a>
                . JAMS’s rules are also available at{' '}
                <a href="https://www.jamsadr.com">www.jamsadr.com</a> or by
                calling JAMS at 800-352-5267. If JAMS is not available to
                arbitrate, the parties will select an alternative arbitral
                forum. The rules of the ADR Provider shall govern all aspects of
                the arbitration, including but not limited to the method of
                initiating and/or demanding arbitration, except to the extent
                such rules are in conflict with the Terms. The arbitration shall
                be conducted by a single, neutral arbitrator. Any claims or
                disputes where the total amount of the award sought is less than
                Ten Thousand U.S. Dollars (US $10,000.00) may be resolved
                through binding non-appearance-based arbitration, at the option
                of the party seeking relief. For claims or disputes in which the
                total amount of the award sought is Ten Thousand U.S. Dollars
                (US $10,000.00) or more, the right to a hearing will be
                determined by the ADR Provider’s Rules. Any hearing will be held
                in a location within San Francisco County, California unless the
                parties agree otherwise. If you reside outside of the U.S., the
                arbitrator shall give the parties reasonable notice of the date,
                time and place of any oral hearings. Any judgment on the award
                rendered by the arbitrator may be entered in any court of
                competent jurisdiction. Each party shall bear its own costs
                (including attorney’s fees) and disbursements arising out of the
                arbitration and shall pay an equal share of the fees and costs
                of the ADR Provider.
            </li>
            <li>
                Additional Rules for Non-Appearance Based Arbitration. If
                non-appearance based arbitration is elected, the arbitration
                shall be conducted by telephone, online and/or based solely on
                written submissions; the specific manner shall be chosen by the
                party initiating the arbitration. The arbitration shall not
                involve any personal appearance by the parties or witnesses
                unless otherwise agreed by the parties.
            </li>
            <li>
                Time Limits. If you or the Company pursues arbitration, the
                arbitration action must be initiated and/or demanded within the
                statute of limitations (i.e., the legal deadline for filing a
                claim) and within any deadline imposed under ADR Provider’s
                Rules for the pertinent claim.
            </li>
            <li>
                Authority of Arbitrator. If arbitration is initiated, the
                arbitrator, and not any court or agency, will decide the rights
                and liabilities, if any, of you and the Company, and the dispute
                will not be consolidated with any other matters or joined with
                any other cases or parties. The arbitrator shall have the
                authority to grant motions dispositive of all or part of any
                claim. The arbitrator shall have the authority to award monetary
                damages, and to grant any non-monetary remedy or relief
                available to an individual under applicable law, the ADR
                Provider’s rules, and the Terms. The arbitrator shall issue a
                written award and statement of decision describing the essential
                findings and conclusions on which the award is based, including
                the calculation of any damages awarded. The arbitrator has the
                same authority to award relief on an individual basis that a
                judge in a court of law would have. The award of the arbitrator
                is final and binding upon you and the Company.
            </li>
            <li>
                Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR
                CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A
                TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all
                claims and disputes subject to arbitration hereunder shall be
                resolved by arbitration under this Arbitration Agreement.
                Arbitration procedures are typically more limited, more
                efficient and less costly than proceedings in a court and are
                subject to very limited review by a court. In the event any
                litigation should arise between you and the Company in any state
                or federal court in a suit to vacate or enforce an arbitration
                award or otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A
                JURY TRIAL, instead electing that the dispute be resolved by a
                judge.
            </li>
            <li>
                Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES
                WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
                ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A
                CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT
                BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF
                ANY OTHER CUSTOMER OR USER. Notwithstanding anything to the
                contrary herein, in the event that the foregoing sentence is
                deemed invalid or unenforceable with respect to a particular
                claim or dispute, neither you nor we shall be entitled to
                arbitration of such claim or dispute and instead the applicable
                claim or dispute shall be resolved in a court as set forth in
                Section 12.2(p).
            </li>
            <li>
                Confidentiality. All aspects of the arbitration proceeding,
                including but not limited to the award of the arbitrator and
                compliance therewith, shall be strictly confidential. The
                parties agree to maintain confidentiality unless otherwise
                required by law. This paragraph shall not prevent a party from
                submitting to a court of law any information necessary to
                enforce this Agreement, to enforce an arbitration award, or to
                seek injunctive or equitable relief.
            </li>
            <li>
                Severability. Subject to Section 12.2(h), if any part or parts
                of this Arbitration Agreement are found under the law to be
                invalid or unenforceable by a court of competent jurisdiction,
                then such specific part or parts shall be of no force and effect
                and shall be severed and the remainder of the Agreement shall
                continue in full force and effect.
            </li>
            <li>
                Right to Waive. Any or all of the rights and limitations set
                forth in this Arbitration Agreement may be waived by the party
                against whom the claim is asserted. Such waiver shall not waive
                or affect any other portion of this Arbitration Agreement.
            </li>
            <li>
                Survival of Agreement. This Arbitration Agreement will survive
                the termination of your relationship with Company.
            </li>
            <li>
                Small Claims Court. Notwithstanding the foregoing, either you or
                the Company may bring an individual action in small claims
                court.
            </li>
            <li>
                Emergency Equitable Relief. Notwithstanding the foregoing,
                either party may seek emergency equitable relief before a state
                or federal court in order to maintain the status quo pending
                arbitration. A request for interim measures shall not be deemed
                a waiver of any other rights or obligations under this
                Arbitration Agreement.
            </li>
            <li>
                Claims Not Subject to Arbitration. Notwithstanding the
                foregoing, claims of defamation, violation of the Computer Fraud
                and Abuse Act, and infringement or misappropriation of the other
                party’s patent, copyright, trademark or trade secrets shall not
                be subject to this Arbitration Agreement.
            </li>
            <li>
                Courts. In any circumstances where the foregoing Arbitration
                Agreement permits the parties to litigate in court (other than a
                small claims court), the parties hereby agree that all
                applicable claims and disputes shall be litigated exclusively in
                the state courts located within San Francisco County,
                California, or the federal courts located in the Northern
                District of California, and the parties hereby agree to submit
                to the personal jurisdiction of such courts for purposes such
                litigation.
            </li>
            <li>
                Modifications. Notwithstanding any provision in the Terms to the
                contrary, the parties agree that if the Company makes any future
                material change to this Arbitration Agreement, it will not apply
                to any individual claim(s) of which you had already provided
                notice to us.
            </li>
        </ol>
        <p>
            12.3 Export Control. You may not use, export, import, or transfer
            the Services except as authorized by U.S. law, the laws of the
            jurisdiction in which you obtained the Services, and any other
            applicable laws. In particular, but without limitation, the Services
            may not be exported or re-exported (a) into any United States
            embargoed countries, or (b) to anyone on the U.S. Treasury
            Department’s list of Specially Designated Nationals or the U.S.
            Department of Commerce’s Denied Person’s List or Entity List. By
            using the Services, you represent and warrant that (i) you are not
            located in a country that is subject to a U.S. Government embargo,
            or that has been designated by the U.S. Government as a “terrorist
            supporting” country and (ii) you are not listed on any U.S.
            Government list of prohibited or restricted parties. You also will
            not use the Services for any purpose prohibited by law.
        </p>
        <p>
            12.5 Consumer Complaints. Company is located at the address in
            Section 12.10. If you are a consumer and a California resident, you
            may report complaints to the Complaint Assistance Unit of the
            Division of Consumer Product of the California Department of
            Consumer Affairs by contacting them in writing at 400 R Street,
            Sacramento, CA 95814, or by telephone at (800) 952-5210.
        </p>
        <p>
            12.6 Consent to Receive Calls, Texts and Other Electronic
            Communications. The communications between you and Company use
            electronic means, whether you use the Company Properties or send us
            emails or text messages, or whether Company posts notices on the
            Company Properties or communicates with you via email, text messages
            or other electronic means. By agreeing to these Terms, you provide
            your express written consent for the Company and its representatives
            to contact you at any time using any telephone number or email
            address you provide or associate with your account via text
            messages, phone calls, or e-mails for any matters relating to your
            account or your use of the Company Properties. You further agree
            that the Company may use automatic telephone dialing systems and/or
            prerecorded or artificial voice message for purposes of such
            communications. For contractual purposes, you (a) consent to receive
            communications from Company in an electronic form; and (b) agree
            that all terms and conditions, agreements, notices, disclosures, and
            other communications that Company provides to you electronically
            satisfy any legal requirement that such communications would satisfy
            if set forth in a hardcopy writing. The foregoing does not affect
            your non-waivable rights.
        </p>
        <p>
            12.7 Entire Terms; Assignments. These Terms constitute the entire
            agreement between you and us regarding the use of the Company
            Properties. Failure by either party to exercise or enforce any right
            or provision of these Terms shall not operate as a waiver of such
            right or provision. The section titles in these Terms are for
            convenience only and have no legal or contractual effect. The word
            “including” means “including without limitation.” Subject to Section
            12.2(h), if any provision of these Terms is, for any reason, held to
            be invalid or unenforceable, the other provisions of these Terms
            will be unimpaired and the invalid or unenforceable provision will
            be deemed modified so that it is valid and enforceable to the
            maximum extent permitted by law. Your relationship to Company is
            that of an independent contractor, and neither party is an agent or
            partner of the other. These Terms, and your rights and obligations
            herein, may not be assigned, subcontracted, delegated, or otherwise
            transferred by you without Company’s prior written consent, and any
            attempted assignment, subcontract, delegation, or transfer in
            violation of the foregoing will be null and void. Company may freely
            assign these Terms. The terms and conditions set forth in these
            Terms shall be binding upon assignees.
        </p>
        <p>
            12.8 Governing Law. THESE TERMS AND ANY ACTION RELATED THERETO WILL
            BE GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF
            CALIFORNIA, CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT
            GIVING EFFECT TO ANY CONFLICT OF LAW OR OTHER PRINCIPLES THAT
            PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER JURISDICTION. THE
            UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF
            GOODS DOES NOT APPLY TO THIS AGREEMENT.
        </p>
        <p>
            12.9 Copyright/Trademark Information. Copyright © 2022 Clerk Chat
            Inc. All rights reserved. All trademarks, logos and service marks
            (“Marks”) displayed on the Company Properties are our property or
            the property of third party owners. You are not permitted to use
            these Marks without our prior written consent or the consent of
            their respective owners.
        </p>
        <p>12.10 Company contact information is as follows:</p>
        <h2>
            <strong>Contact Information</strong>
        </h2>
        <p>Address:</p>
        <p>Clerk Chat Inc.</p>
        <p>16192 Coastal Highway</p>
        <p>Lewes, DE 19958</p>
        <p>Telephone: 888-572-5375</p>
        <p>
            Email: <a href="mailto:support@clerk.chat">support@clerk.chat</a>
        </p>
    </div>
);
