import IconBase, { IconBaseProps } from '../IconBase';

/** @deprecated use src/icons/common/CEditIcon.tsx*/
export const EditIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7088 4.12002C19.2207 3.63187 18.4292 3.63187 17.9411 4.12002L16.5604 5.50067L18.3282 7.26844L19.7088 5.88779C20.197 5.39963 20.197 4.60818 19.7088 4.12002ZM20.7695 6.94845C21.8434 5.87451 21.8434 4.1333 20.7695 3.05936C19.6956 1.98542 17.9544 1.98542 16.8804 3.05936L14.9697 4.9701L5.17393 14.7658C4.38992 15.5497 3.9221 16.0175 3.55684 16.5603C3.23305 17.0414 2.97262 17.5623 2.78198 18.11C2.56692 18.7279 2.47341 19.3828 2.31669 20.4803L2.25755 20.8944C2.22309 21.1355 2.30793 21.3784 2.48506 21.5457C2.66219 21.713 2.90953 21.7838 3.14834 21.7356L3.70961 21.6224C4.69578 21.4235 5.28433 21.3047 5.83868 21.0865C6.33025 20.8931 6.79741 20.6425 7.23055 20.3401C7.71902 19.9991 8.14353 19.5745 8.85486 18.8631L20.7695 6.94845ZM17.2675 8.3291L15.4998 6.56133L6.29308 15.768C5.43338 16.6277 5.07575 16.9899 4.80129 17.3977C4.54945 17.772 4.34689 18.1771 4.19862 18.6031C4.07377 18.9618 4.00052 19.3438 3.89437 20.054C4.59205 19.9102 4.95228 19.8234 5.28929 19.6908C5.67162 19.5403 6.03496 19.3454 6.37186 19.1102C6.73894 18.8539 7.06728 18.5294 7.84712 17.7495L17.2675 8.3291ZM12.25 21C12.25 20.5858 12.5858 20.25 13 20.25H21C21.4142 20.25 21.75 20.5858 21.75 21C21.75 21.4142 21.4142 21.75 21 21.75H13C12.5858 21.75 12.25 21.4142 12.25 21Z"
            fill="currentColor"
        />
    </IconBase>
);
