import styles from './WorkflowTree.module.scss';
import DescendantNodes from '../DescendantNodes';
import {
    WorkflowAction,
    WorkflowCondition,
    WorkflowStep,
    WorkflowStepType,
} from '../types';
import renderStep from '../WorkflowStep';
import StartStopStep from '../StartStopStep';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';

const walkAndRender = (step?: WorkflowStep) => {
    if (!step) {
        return;
    }

    if (step.type === WorkflowStepType.Action) {
        const action = step as WorkflowAction;

        return (
            <div className={styles['workflow-tree__node']}>
                {renderStep(step)}
                <DescendantNodes
                    singleNode={
                        action.nextStep ? (
                            walkAndRender(action.nextStep)
                        ) : (
                            <StartStopStep type="exit" />
                        )
                    }
                    parentId={action.id}
                />
            </div>
        );
    }

    if (step.type === WorkflowStepType.Conditional) {
        const conditional = step as WorkflowCondition;

        return (
            <div className={styles['workflow-tree__node']}>
                {renderStep(step)}
                <DescendantNodes
                    leftNode={
                        conditional.falsyStep ? (
                            walkAndRender(conditional.falsyStep)
                        ) : (
                            <StartStopStep type="exit" />
                        )
                    }
                    rightNode={
                        conditional.truthyStep ? (
                            walkAndRender(conditional.truthyStep)
                        ) : (
                            <StartStopStep type="exit" />
                        )
                    }
                    parentId={conditional.id}
                />
            </div>
        );
    }
};

export const WorkflowTree = () => {
    const {
        state: { workflow },
        dispatch,
    } = useWorkflowContext();

    const onTriggerClick = () => {
        dispatch({
            type: WorkflowReducerActionTypes.SET_TRIGGER_STEP,
        });
    };

    return (
        workflow && (
            <div className={styles['workflow-tree']}>
                <div className={styles['workflow-tree__node']}>
                    <StartStopStep type="trigger" onClick={onTriggerClick} />
                    <DescendantNodes
                        singleNode={walkAndRender(workflow.spec.steps)}
                        parentId="trigger"
                    />
                </div>
            </div>
        )
    );
};
