import cc from 'classcat';
// styles
import styles from './Radio.module.scss';

interface RadioProps {
    checked?: boolean;
    className?: string;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
}

export const Radio = ({
    checked,
    className,
    disabled,
    onChange,
}: RadioProps) => (
    <div
        className={cc([styles['radio'], className])}
        onClick={() => onChange?.(!checked)}
    >
        <input
            className={styles['radio__input']}
            type="radio"
            disabled={disabled}
            checked={checked}
        />
        <span className={styles['radio__span']} />
    </div>
);
