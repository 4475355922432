import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const EditIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.424 3.43333C16.0172 3.02654 15.3577 3.02654 14.9509 3.43333L13.8004 4.58387L15.2735 6.05701L16.424 4.90647C16.8308 4.49967 16.8308 3.84013 16.424 3.43333ZM17.3079 5.79035C18.2029 4.8954 18.2029 3.4444 17.3079 2.54945C16.413 1.6545 14.962 1.6545 14.067 2.54945L12.4747 4.14173L4.31161 12.3048C3.65827 12.9581 3.26842 13.3479 2.96404 13.8002C2.69421 14.2012 2.47718 14.6352 2.31831 15.0916C2.1391 15.6065 2.06117 16.1523 1.93058 17.0669L1.88129 17.4119C1.85258 17.6129 1.92328 17.8153 2.07088 17.9547C2.21849 18.0941 2.42461 18.1531 2.62362 18.113L3.09134 18.0186C3.91315 17.8529 4.40361 17.7539 4.86557 17.5721C5.27521 17.4109 5.66451 17.2021 6.02546 16.9501C6.43252 16.6659 6.78627 16.3121 7.37905 15.7192L17.3079 5.79035ZM14.3896 6.9409L12.9165 5.46776L5.24423 13.14C4.52781 13.8564 4.22979 14.1582 4.00108 14.4981C3.79121 14.8099 3.62241 15.1475 3.49885 15.5025C3.39481 15.8015 3.33377 16.1198 3.24531 16.7117C3.82671 16.5918 4.1269 16.5195 4.40774 16.409C4.72635 16.2836 5.02914 16.1212 5.30988 15.9252C5.61579 15.7116 5.8894 15.4411 6.53927 14.7912L14.3896 6.9409ZM10.2083 17.5C10.2083 17.1548 10.4882 16.875 10.8333 16.875H17.5C17.8452 16.875 18.125 17.1548 18.125 17.5C18.125 17.8452 17.8452 18.125 17.5 18.125H10.8333C10.4882 18.125 10.2083 17.8452 10.2083 17.5Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
