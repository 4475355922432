import { Message, MessageStatus, SenderType } from '../../../api/types';
import { SxProps } from '@mui/material';
import { TimePickerSlotsComponentsProps } from '@mui/x-date-pickers/TimePicker/TimePicker.types';
import { Dayjs } from 'dayjs';

export const container = {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    order: 2,
};

export const getBodyStyle = ({
    inbound,
    senderType,
    status,
}: Message): SxProps => ({
    px: 3,
    py: 2.5,
    maxWidth: 620,
    color: 'primary.dark',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    textAlign: 'left',
    borderRadius: 1.5,
    order: inbound ? 1 : 2,
    bgcolor:
        (status === MessageStatus.Failed && '#fdf0ef') ||
        ([SenderType.CAMPAIGN, SenderType.CAMPAIGN_MESSAGE].includes(
            senderType,
        ) &&
            '#eefff1') ||
        (senderType === SenderType.WORKFLOW && '#fff7ee') ||
        (inbound && 'custom.gray.active') ||
        (status === MessageStatus.Scheduled && '#eff1f5') ||
        '#eef0ff',
});

export const getAvatarContainer = (inbound: boolean): SxProps => ({
    width: 40,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-end',
    order: inbound ? 1 : 2,
});

export const status: SxProps = {
    color: 'custom.gray.super',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
};
export const timePickerSlotProps: TimePickerSlotsComponentsProps<Dayjs> = {
    digitalClockSectionItem: {
        sx: {
            px: 1,
            py: 2,
            fontSize: 16,
            lineHeight: 1.3,
        },
    },
    desktopPaper: {
        sx: {
            '.MuiMultiSectionDigitalClock-root .MuiList-root:after': {
                height: 'initial',
            },
        },
    },
    desktopTrapFocus: {
        isEnabled: () => true,
        disableRestoreFocus: true,
        disableAutoFocus: true,
        disableEnforceFocus: true,
    },
};
export const getActionsList = (inbound: boolean): SxProps => ({
    position: 'absolute',
    top: -30,
    left: inbound ? '10%' : undefined,
    right: !inbound ? '10%' : undefined,

    display: 'flex',
    justifyContent: 'center',
    p: 1,

    borderRadius: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'custom.gray.divider',

    bgcolor: 'white',
    boxShadow: '0 1px 2px 0 rgba(31, 41, 54, 0.08)',
});

export const getMessageContainer = (inbound: boolean): SxProps => ({
    display: 'flex',
    position: 'relative',
    justifyContent: inbound ? 'flex-start' : 'flex-end',
});
