import Icon from '../../icons/Icon';
import styles from './Label.module.scss';

type FormLabelProps = {
    text: string;
    info?: boolean;
    required?: boolean;
};

export const Label = (props: FormLabelProps) => (
    <div className={styles['root']}>
        {props.info && <Icon name="contact-info" />}
        {props.text}
        {props.required && ' *'}
    </div>
);
