import { SvgIcon, SvgIconProps } from '@mui/material';

export const Power = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00016 0.875C8.34534 0.875 8.62516 1.15482 8.62516 1.5V9.83333C8.62516 10.1785 8.34534 10.4583 8.00016 10.4583C7.65498 10.4583 7.37516 10.1785 7.37516 9.83333V1.5C7.37516 1.15482 7.65498 0.875 8.00016 0.875ZM5.20783 3.74559C5.38067 4.04438 5.27857 4.42671 4.97978 4.59955C3.17229 5.64513 1.9585 7.59785 1.9585 9.83333C1.9585 13.1701 4.66344 15.875 8.00016 15.875C11.3369 15.875 14.0418 13.1701 14.0418 9.83333C14.0418 7.59785 12.828 5.64513 11.0205 4.59955C10.7218 4.42671 10.6197 4.04438 10.7925 3.74559C10.9653 3.44681 11.3477 3.3447 11.6464 3.51754C13.8243 4.77736 15.2918 7.1336 15.2918 9.83333C15.2918 13.8604 12.0272 17.125 8.00016 17.125C3.97309 17.125 0.708496 13.8604 0.708496 9.83333C0.708496 7.1336 2.17602 4.77736 4.35388 3.51754C4.65266 3.3447 5.03499 3.44681 5.20783 3.74559Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
