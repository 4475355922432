import ModalLayout from '../../../layouts/ModalLayout';
import styles from './AttachmentModal.module.scss';

type AttachmentModalProps = {
    url: string;
};

export const AttachmentModal = (props: AttachmentModalProps) => {
    return (
        <ModalLayout>
            <div className={styles.wrap}>
                <img src={props.url} className={styles.image} />
            </div>
        </ModalLayout>
    );
};
