import IconBase, { IconBaseProps } from '../IconBase';

export const TableInboxesIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M5 12V7.2C5 6.0799 5 5.51984 5.21799 5.09202C5.40973 4.71569 5.71569 4.40973 6.09202 4.21799C6.51984 4 7.07989 4 8.2 4H15.8C16.9201 4 17.4802 4 17.908 4.21799C18.2843 4.40973 18.5903 4.71569 18.782 5.09202C19 5.51984 19 6.0799 19 7.2V12M3 12V16.8C3 17.9201 3 18.4802 3.21799 18.908C3.40973 19.2843 3.71569 19.5903 4.09202 19.782C4.51984 20 5.0799 20 6.2 20H17.8C18.9201 20 19.4802 20 19.908 19.782C20.2843 19.5903 20.5903 19.2843 20.782 18.908C21 18.4802 21 17.9201 21 16.8V12H17.3255C16.8363 12 16.5917 12 16.3615 12.0553C16.1575 12.1043 15.9624 12.1851 15.7834 12.2947C15.5816 12.4184 15.4086 12.5914 15.0627 12.9373L14.9373 13.0627C14.5914 13.4086 14.4184 13.5816 14.2166 13.7053C14.0376 13.8149 13.8425 13.8957 13.6385 13.9447C13.4083 14 13.1637 14 12.6745 14H11.3255C10.8363 14 10.5917 14 10.3615 13.9447C10.1575 13.8957 9.96237 13.8149 9.78343 13.7053C9.5816 13.5816 9.40864 13.4086 9.06274 13.0627L8.93726 12.9373C8.59135 12.5914 8.4184 12.4184 8.21657 12.2947C8.03763 12.1851 7.84254 12.1043 7.63846 12.0553C7.40829 12 7.1637 12 6.67452 12H3Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
