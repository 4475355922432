import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles';
import { legacyMobileBreakpoint } from './breakpoints';

export const MuiDrawer: Components<Theme>['MuiDrawer'] = {
    styleOverrides: {
        paperAnchorDockedLeft: ({ theme }) => ({
            width: 248,
            borderRight: '1px solid',
            borderColor: theme.palette.custom.gray.divider,
            backgroundColor: theme.palette.custom.gray.sideBack,
            [theme.breakpoints.down(legacyMobileBreakpoint)]: {
                width: '100vw',
            },

            '& .MuiList-root': {
                paddingBottom: theme.spacing(4),

                '& .MuiListSubheader-root': {
                    ...theme.typography.body4,
                    backgroundColor: theme.palette.custom.gray.sideBack,
                    paddingTop: theme.spacing(4),
                    paddingBottom: theme.spacing(1),
                },
            },
        }),
    },
};
