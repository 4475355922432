import IconBase, { IconBaseProps } from '../IconBase';

export const SendFilledIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <g clipPath="url(#clip0_5673_5186)">
            <path
                d="M21.5771 3.05676C21.4667 2.59471 20.959 2.28031 20.4985 2.44373C20.4985 2.44373 0.551709 9.48237 0.54839 9.48486C0.242232 9.59851 0.0173836 9.89051 0.000789672 10.2248C-0.0141449 10.5458 0.184153 10.8801 0.477036 11.0162L8.20318 14.6222C8.20318 14.6222 11.9675 10.8586 11.9742 10.8519C12.7209 10.1054 13.9132 11.2601 13.1473 12.0249L9.37637 15.7952C9.37637 15.7952 12.9781 23.5115 12.9847 23.524C13.1316 23.806 13.4187 23.9852 13.7339 23.9993C14.0766 24.0151 14.3936 23.7529 14.5163 23.446C14.5163 23.446 21.5522 3.51467 21.5563 3.50222C21.6086 3.35871 21.6119 3.20359 21.5771 3.05676Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_5673_5186">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </IconBase>
);
