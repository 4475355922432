import IconBase, { IconBaseProps } from '../IconBase';

export const CustomSandTimerIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.25 3C3.25 2.58579 3.58579 2.25 4 2.25H20C20.4142 2.25 20.75 2.58579 20.75 3C20.75 3.41421 20.4142 3.75 20 3.75H19.717C19.4994 6.21648 18.216 8.48169 16.181 9.93525L13.2903 12L16.181 14.0647C18.216 15.5183 19.4994 17.7835 19.717 20.25H20C20.4142 20.25 20.75 20.5858 20.75 21C20.75 21.4142 20.4142 21.75 20 21.75H4C3.58579 21.75 3.25 21.4142 3.25 21C3.25 20.5858 3.58579 20.25 4 20.25H4.28301C4.50065 17.7835 5.78401 15.5183 7.81901 14.0647L10.7097 12L7.81901 9.93525C5.78401 8.48169 4.50065 6.21648 4.28301 3.75H4C3.58579 3.75 3.25 3.41421 3.25 3ZM5.7901 3.75C6.0027 5.73104 7.05039 7.54289 8.69086 8.71465L12 11.0783L15.3091 8.71465C16.9496 7.54289 17.9973 5.73104 18.2099 3.75H5.7901ZM12 12.9217L8.69086 15.2853C7.05039 16.4571 6.0027 18.269 5.7901 20.25H18.2099C17.9973 18.269 16.9496 16.4571 15.3091 15.2853L12 12.9217ZM8.25 18C8.25 17.5858 8.58579 17.25 9 17.25H15C15.4142 17.25 15.75 17.5858 15.75 18C15.75 18.4142 15.4142 18.75 15 18.75H9C8.58579 18.75 8.25 18.4142 8.25 18Z"
            fill="currentColor"
        />
    </IconBase>
);
