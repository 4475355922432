import IconBase, { IconBaseProps } from '../IconBase';

export const StateIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.17142 2.80172C2.35568 2.68784 2.58577 2.67749 2.77951 2.77436L7.5 5.1346L12.2205 2.77436C12.3964 2.68638 12.6036 2.68638 12.7795 2.77436L17.7795 5.27436C17.9912 5.38023 18.125 5.59664 18.125 5.83337V16.6667C18.125 16.8833 18.0128 17.0845 17.8286 17.1984C17.6443 17.3122 17.4142 17.3226 17.2205 17.2257L12.5 14.8655L7.77951 17.2257C7.60355 17.3137 7.39645 17.3137 7.22049 17.2257L2.22049 14.7257C2.00875 14.6199 1.875 14.4034 1.875 14.1667V3.33337C1.875 3.11676 1.98716 2.9156 2.17142 2.80172ZM13.125 13.7804L16.875 15.6554V6.21965L13.125 4.34465V13.7804ZM11.875 4.34465V13.7804L8.125 15.6554V6.21965L11.875 4.34465ZM6.875 6.21965L3.125 4.34465V13.7804L6.875 15.6554V6.21965Z"
            fill="currentColor"
        />
    </IconBase>
);
