import { allowedDocMimeTypes, MimeType } from '../../../../constants';

export const isAllowed = (
    mimeType?: string | MimeType,
): mimeType is MimeType => {
    if (!mimeType) {
        return false;
    }
    return allowedDocMimeTypes.includes(mimeType as MimeType);
};
