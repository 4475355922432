import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const AddMemberIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                d="M18.9809 1.54731C18.8889 1.16226 18.4658 0.900264 18.0821 1.03645C18.0821 1.03645 1.45976 6.90198 1.45699 6.90406C1.20186 6.99876 1.01449 7.24209 1.00066 7.52068C0.988213 7.78821 1.15346 8.0668 1.39753 8.18017L7.83598 11.1852C7.83598 11.1852 10.9729 8.04882 10.9785 8.04329C11.6007 7.42114 12.5943 8.3834 11.9561 9.02077L8.81364 12.1626C8.81364 12.1626 11.8151 18.593 11.8206 18.6033C11.943 18.8384 12.1822 18.9877 12.445 18.9994C12.7305 19.0126 12.9946 18.7941 13.097 18.5384C13.097 18.5384 18.9602 1.92889 18.9636 1.91852C19.0072 1.79893 19.0099 1.66966 18.9809 1.54731Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
