import IconBase, { IconBaseProps } from '../IconBase';

export const MicrosoftOutlookLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <g clipPath="url(#clip0_5788_3196)">
            <path
                d="M23.9999 12.5625C24.0013 12.3734 23.9043 12.1974 23.7443 12.0985H23.7415L23.7315 12.0928L15.4152 7.1316C15.3793 7.10715 15.342 7.0848 15.3036 7.06467C14.9825 6.89771 14.601 6.89771 14.2799 7.06467C14.2415 7.08481 14.2042 7.10715 14.1683 7.1316L5.85206 12.0928L5.84202 12.0985C5.58784 12.2578 5.50992 12.5946 5.66798 12.8507C5.71455 12.9262 5.7787 12.9891 5.85485 13.0339L14.1711 17.9951C14.2072 18.0194 14.2444 18.0417 14.2828 18.0621C14.6039 18.229 14.9853 18.229 15.3064 18.0621C15.3447 18.0417 15.382 18.0194 15.418 17.9951L23.7343 13.0339C23.9004 12.9363 24.0018 12.7563 23.9999 12.5625Z"
                fill="#0A2767"
            />
            <path
                d="M6.82157 9.24992H12.2791V14.2916H6.82157V9.24992ZM22.8837 4.12499V1.81874C22.8968 1.24213 22.4439 0.763817 21.8718 0.75H7.70958C7.13743 0.763817 6.68456 1.24213 6.69767 1.81874V4.12499L15.0698 6.37498L22.8837 4.12499Z"
                fill="#0364B8"
            />
            <path
                d="M6.69763 4.125H12.279V9.18749H6.69763V4.125Z"
                fill="#0078D4"
            />
            <path
                d="M17.8605 4.125H12.2791V9.18749L17.8605 14.25H22.8837V9.18749L17.8605 4.125Z"
                fill="#28A8EA"
            />
            <path
                d="M12.2791 9.1875H17.8604V14.25H12.2791V9.1875Z"
                fill="#0078D4"
            />
            <path
                d="M12.2791 14.25H17.8604V19.3125H12.2791V14.25Z"
                fill="#0364B8"
            />
            <path
                d="M6.82153 14.2916H12.279V18.8749H6.82153V14.2916Z"
                fill="#14447D"
            />
            <path
                d="M17.8605 14.25H22.8837V19.3125H17.8605V14.25Z"
                fill="#0078D4"
            />
            <path
                d="M23.7444 13.0029L23.7338 13.0086L15.4175 17.7223C15.3812 17.7448 15.3444 17.7662 15.3059 17.7853C15.1646 17.8531 15.0112 17.8914 14.8549 17.8978L14.4006 17.63C14.3622 17.6106 14.3249 17.589 14.2889 17.5654L5.86105 12.7177H5.85714L5.58142 12.5625V22.1047C5.58572 22.7414 6.10115 23.254 6.73286 23.25H22.8664C22.8759 23.25 22.8843 23.2455 22.8943 23.2455C23.0278 23.2369 23.1593 23.2092 23.285 23.1634C23.3394 23.1402 23.3918 23.1127 23.4419 23.0812C23.4793 23.0599 23.5434 23.0132 23.5434 23.0132C23.8294 22.7999 23.9986 22.4632 24 22.1047V12.5625C23.9998 12.745 23.9021 12.9133 23.7444 13.0029Z"
                fill="url(#paint0_linear_5788_3196)"
            />
            <path
                opacity="0.5"
                d="M23.5534 12.5248V13.1099L14.8576 19.1438L5.85482 12.7217C5.85482 12.7186 5.85232 12.7161 5.84923 12.7161L5.02319 12.2155V11.7936L5.36366 11.788L6.08365 12.2042L6.10039 12.2098L6.16178 12.2492C6.16178 12.2492 14.6232 17.1148 14.6455 17.1261L14.9692 17.3173C14.9971 17.3061 15.025 17.2948 15.0585 17.2836C15.0753 17.2723 23.4585 12.5192 23.4585 12.5192L23.5534 12.5248Z"
                fill="#0A2767"
            />
            <path
                d="M23.7444 13.0029L23.7338 13.0091L15.4175 17.7229C15.3812 17.7454 15.3444 17.7667 15.3059 17.7858C14.9829 17.9449 14.6052 17.9449 14.2822 17.7858C14.244 17.7668 14.2067 17.7457 14.1706 17.7229L5.85435 13.0091L5.84431 13.0029C5.68375 12.9152 5.58309 12.7466 5.58142 12.5625V22.1047C5.58542 22.7413 6.10067 23.254 6.73225 23.25C6.73225 23.25 6.73228 23.25 6.73231 23.25H22.8491C23.4807 23.254 23.996 22.7413 24 22.1048C24 22.1048 24 22.1048 24 22.1047V12.5625C23.9998 12.745 23.9021 12.9133 23.7444 13.0029Z"
                fill="#1490DF"
            />
            <path
                opacity="0.1"
                d="M15.5386 17.6531L15.4141 17.7234C15.378 17.7465 15.3408 17.7678 15.3025 17.787C15.1653 17.8548 15.0162 17.8946 14.8638 17.9039L18.0279 21.6749L23.5473 23.0154C23.6985 22.9002 23.8188 22.7488 23.8973 22.5749L15.5386 17.6531Z"
                fill="black"
            />
            <path
                opacity="0.05"
                d="M16.1023 17.3336L15.4141 17.7234C15.378 17.7466 15.3408 17.7678 15.3025 17.787C15.1653 17.8548 15.0162 17.8946 14.8638 17.904L16.3462 22.0232L23.549 23.0137C23.8327 22.799 23.9998 22.4623 23.9999 22.1047V21.9815L16.1023 17.3336Z"
                fill="black"
            />
            <path
                d="M6.74793 23.25H22.8474C23.0952 23.2512 23.3368 23.1724 23.5367 23.025L14.4 17.6312C14.3616 17.6118 14.3243 17.5902 14.2884 17.5665L5.86048 12.7189H5.85658L5.58142 12.5625V22.0721C5.58079 22.722 6.10305 23.2493 6.74793 23.25Z"
                fill="#28A8EA"
            />
            <path
                opacity="0.1"
                d="M13.3954 6.28106V18.2792C13.3944 18.6998 13.1406 19.0779 12.7535 19.2354C12.6336 19.2873 12.5045 19.3141 12.374 19.3142H5.58142V5.81249H6.6977V5.25H12.374C12.9378 5.25215 13.3942 5.71279 13.3954 6.28106Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M12.8372 6.8436V18.8417C12.8386 18.9776 12.8101 19.1121 12.7535 19.2354C12.5985 19.6205 12.2281 19.8731 11.8158 19.875H5.58142V5.81255H11.8158C11.9778 5.81091 12.1374 5.85162 12.2791 5.93068C12.6212 6.1044 12.8371 6.45751 12.8372 6.8436Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M12.8372 6.8436V17.7167C12.8345 18.2847 12.3794 18.7451 11.8158 18.75H5.58142V5.81255H11.8158C11.9778 5.81091 12.1374 5.85162 12.2791 5.93068C12.6212 6.1044 12.8371 6.45751 12.8372 6.8436Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M12.2791 6.84356V17.7167C12.2785 18.2855 11.8222 18.7472 11.2577 18.75H5.58142V5.8125H11.2577C11.8221 5.8128 12.2794 6.27418 12.2791 6.843C12.2791 6.84319 12.2791 6.84337 12.2791 6.84356Z"
                fill="black"
            />
            <path
                d="M1.02307 5.8125H11.256C11.821 5.8125 12.2791 6.27413 12.2791 6.84356V17.1564C12.2791 17.7258 11.821 18.1875 11.256 18.1875H1.02307C0.458037 18.1875 0 17.7258 0 17.1564V6.84356C0 6.27413 0.45805 5.8125 1.02307 5.8125Z"
                fill="#1784D9"
            />
            <path
                d="M3.19755 10.137C3.4497 9.59559 3.8567 9.14249 4.3663 8.83596C4.93065 8.51033 5.5732 8.348 6.22323 8.36683C6.82569 8.35366 7.42017 8.50757 7.94174 8.81176C8.43212 9.10648 8.82708 9.53833 9.07867 10.0549C9.35265 10.6241 9.4892 11.2505 9.47718 11.883C9.49046 12.544 9.34997 13.199 9.06695 13.7955C8.80937 14.3305 8.40189 14.7777 7.89486 15.0819C7.35318 15.3954 6.73663 15.5534 6.11216 15.5387C5.49683 15.5536 4.88924 15.398 4.35569 15.0887C3.86106 14.7936 3.46116 14.3612 3.2037 13.8433C2.92808 13.2823 2.78991 12.663 2.80072 12.0371C2.78924 11.3817 2.9249 10.7322 3.19755 10.137ZM4.44331 13.1914C4.57775 13.5337 4.80574 13.8305 5.1008 14.0475C5.40134 14.2592 5.76122 14.3684 6.12777 14.3591C6.51814 14.3747 6.90292 14.2617 7.22396 14.0374C7.51529 13.8211 7.73728 13.5234 7.86247 13.1812C8.00241 12.7991 8.07147 12.3943 8.06619 11.9871C8.07052 11.5759 8.0056 11.167 7.87419 10.7777C7.75812 10.4262 7.54329 10.1163 7.25577 9.88557C6.94278 9.65058 6.55903 9.53139 6.16907 9.54807C5.79458 9.5383 5.42664 9.64837 5.1181 9.86251C4.81804 10.0804 4.58574 10.3799 4.44833 10.7259C4.14353 11.5191 4.14195 12.3982 4.44387 13.1925L4.44331 13.1914Z"
                fill="white"
            />
            <path
                d="M17.8605 4.125H22.8837V9.18749H17.8605V4.125Z"
                fill="#50D9FF"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_5788_3196"
                x1="14.7907"
                y1="12.5625"
                x2="14.7907"
                y2="23.25"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#35B8F1" />
                <stop offset="1" stopColor="#28A8EA" />
            </linearGradient>
            <clipPath id="clip0_5788_3196">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </IconBase>
);
