import { ConversationsFilterType, InboxStatus } from '../../api/types';
import styles from './ConversationsList.module.scss';
import startConversationIcon from '../../assets/icons/placeholders/start-conversation.svg';
import { Button } from '@mui/material';

export const EmptyList = (props: {
    type: ConversationsFilterType | undefined;
    status: InboxStatus | undefined;
    onClick: (() => void) | undefined;
}) => (
    <div className={styles['empty-list']}>
        {props.type !== ConversationsFilterType.Archived && (
            <img
                className={styles['empty-list__icon']}
                src={startConversationIcon}
                alt="Start conversation"
            />
        )}
        <p className={styles['empty-list__text']}>
            {props.type === ConversationsFilterType.Active
                ? 'Add first message and start a conversation'
                : 'No archived conversations'}
        </p>
        <Button
            disabled={props.status === InboxStatus.PENDING}
            onClick={props.onClick}
        >
            Send Message
        </Button>
    </div>
);
