import IconBase, { IconBaseProps } from '../IconBase';

export const CheckIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M4 12.6111L8.92308 17.5L20 6.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
