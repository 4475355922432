import IconBase, { IconBaseProps } from '../IconBase';

export const AuIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M22.2026 4.13788H1.79742C0.804703 4.13788 0 4.94258 0 5.9353V18.0647C0 19.0573 0.804703 19.8621 1.79742 19.8621H22.2026C23.1952 19.8621 24 19.0574 24 18.0647V5.9353C24 4.94258 23.1953 4.13788 22.2026 4.13788Z"
            fill="#41479B"
        />
        <path
            d="M0.413813 11.9999H1.13259L4.96556 9.48866V11.9999H7.03453V9.48866L10.8675 11.9999H11.5862C11.8148 11.9999 12 11.8146 12 11.5861V11.2579L8.71153 9.10339H12V7.03442H8.71148L12 4.87991V4.55169C12 4.32313 11.8147 4.13788 11.5862 4.13788H10.8674L7.03444 6.64916V4.13788H4.96547V6.64916L1.25831 4.22028C0.825469 4.35622 0.463594 4.65168 0.240609 5.0375L3.28856 7.03442H0V9.10339H3.28852L0 11.2579V11.5861C0 11.8146 0.185203 11.9999 0.413813 11.9999Z"
            fill="#F5F5F5"
        />
        <path
            d="M12 7.44824H6.62067V4.13788H5.37933V7.44824H0V8.68958H5.37933V11.9999H6.62067V8.68958H12V7.44824Z"
            fill="#FF4B55"
        />
        <path
            d="M0.385742 4.8235L3.79177 7.03445H4.5518L0.684805 4.52429C0.573758 4.61195 0.473398 4.7124 0.385742 4.8235Z"
            fill="#FF4B55"
        />
        <path
            d="M7.79446 7.03442L11.937 4.34539C11.865 4.22357 11.738 4.13788 11.5861 4.13788H11.4966L7.03442 7.03442H7.79446Z"
            fill="#FF4B55"
        />
        <path
            d="M4.19418 9.10339L0.0595703 11.7873C0.130867 11.9121 0.259867 11.9999 0.413898 11.9999H0.491945L4.95426 9.10339H4.19418Z"
            fill="#FF4B55"
        />
        <path
            d="M11.9934 11.6187L8.11843 9.10345H7.3584L11.7587 11.9598C11.8906 11.8986 11.981 11.7704 11.9934 11.6187Z"
            fill="#FF4B55"
        />
        <path
            d="M6.12522 14.2561L6.43755 15.2872L7.43847 14.8886C7.52388 14.8545 7.6015 14.9519 7.54938 15.0276L6.93794 15.9146L7.87366 16.4486C7.95349 16.4942 7.92578 16.6156 7.8341 16.622L6.7593 16.697L6.92528 17.7615C6.93944 17.8524 6.82722 17.9064 6.76507 17.8387L6.03625 17.0452L5.30744 17.8387C5.24528 17.9064 5.13307 17.8523 5.14722 17.7615L5.31321 16.697L4.23841 16.622C4.14672 16.6156 4.11897 16.4942 4.19885 16.4486L5.13457 15.9146L4.52313 15.0276C4.471 14.9519 4.54863 14.8545 4.63403 14.8886L5.63496 15.2872L5.94728 14.2561C5.97405 14.1681 6.0986 14.1681 6.12522 14.2561Z"
            fill="#F5F5F5"
        />
        <path
            d="M18.435 6.92479L18.5876 7.42846L19.0765 7.23374C19.1182 7.21715 19.1562 7.26468 19.1307 7.30167L18.832 7.73503L19.2891 7.99588C19.3281 8.0181 19.3146 8.07745 19.2697 8.08054L18.7447 8.1172L18.8258 8.63718C18.8327 8.68157 18.7779 8.70796 18.7475 8.67487L18.3915 8.28726L18.0355 8.67487C18.0051 8.70792 17.9503 8.68152 17.9572 8.63718L18.0382 8.1172L17.5132 8.08054C17.4684 8.0774 17.4549 8.0181 17.4939 7.99588L17.951 7.73503L17.6523 7.30167C17.6268 7.26468 17.6648 7.21715 17.7065 7.23374L18.1954 7.42846L18.348 6.92479C18.3612 6.88176 18.4221 6.88176 18.435 6.92479Z"
            fill="#F5F5F5"
        />
        <path
            d="M15.0328 10.7908L15.1854 11.2945L15.6743 11.0997C15.716 11.0831 15.754 11.1307 15.7285 11.1677L15.4298 11.601L15.8869 11.8619C15.9259 11.8841 15.9124 11.9434 15.8676 11.9465L15.3426 11.9832L15.4236 12.5032C15.4306 12.5476 15.3757 12.574 15.3454 12.5409L14.9894 12.1533L14.6333 12.5409C14.603 12.5739 14.5482 12.5475 14.5551 12.5032L14.6361 11.9832L14.1111 11.9465C14.0663 11.9434 14.0528 11.8841 14.0918 11.8619L14.5489 11.601L14.2502 11.1677C14.2247 11.1307 14.2627 11.0831 14.3044 11.0997L14.7933 11.2945L14.9459 10.7908C14.9589 10.7478 15.0198 10.7478 15.0328 10.7908Z"
            fill="#F5F5F5"
        />
        <path
            d="M21.6536 9.1356L21.8062 9.63927L22.2951 9.44455C22.3368 9.42796 22.3748 9.47549 22.3493 9.51247L22.0506 9.94583L22.5077 10.2067C22.5467 10.2289 22.5332 10.2883 22.4884 10.2913L21.9634 10.328L22.0445 10.848C22.0514 10.8924 21.9965 10.9188 21.9662 10.8857L21.6102 10.4981L21.2541 10.8857C21.2238 10.9187 21.169 10.8923 21.1759 10.848L21.257 10.328L20.732 10.2913C20.6872 10.2882 20.6736 10.2289 20.7126 10.2067L21.1697 9.94583L20.8711 9.51247C20.8456 9.47549 20.8835 9.42796 20.9252 9.44455L21.4142 9.63927L21.5667 9.1356C21.5797 9.09266 21.6406 9.09266 21.6536 9.1356Z"
            fill="#F5F5F5"
        />
        <path
            d="M18.435 15.7563L18.5876 16.26L19.0765 16.0652C19.1182 16.0486 19.1562 16.0962 19.1307 16.1332L18.832 16.5665L19.2891 16.8274C19.3281 16.8496 19.3146 16.9089 19.2697 16.912L18.7447 16.9487L18.8258 17.4687C18.8327 17.5131 18.7779 17.5394 18.7475 17.5064L18.3915 17.1187L18.0355 17.5064C18.0051 17.5394 17.9503 17.513 17.9572 17.4687L18.0382 16.9487L17.5132 16.912C17.4684 16.9089 17.4549 16.8496 17.4939 16.8274L17.951 16.5665L17.6523 16.1332C17.6268 16.0962 17.6648 16.0486 17.7065 16.0652L18.1954 16.26L18.348 15.7563C18.3612 15.7134 18.4221 15.7134 18.435 15.7563Z"
            fill="#F5F5F5"
        />
        <path
            d="M19.6154 11.6959L19.493 12.0632L19.1058 12.0662C19.0557 12.0666 19.0349 12.1305 19.0753 12.1603L19.3867 12.3903L19.2699 12.7595C19.2548 12.8073 19.3092 12.8468 19.3499 12.8176L19.6649 12.5925L19.9799 12.8176C20.0207 12.8467 20.0751 12.8072 20.06 12.7595L19.9432 12.3903L20.2547 12.1603C20.295 12.1305 20.2742 12.0666 20.2241 12.0662L19.837 12.0632L19.7145 11.6959C19.6985 11.6483 19.6313 11.6483 19.6154 11.6959Z"
            fill="#F5F5F5"
        />
    </IconBase>
);
