import IconBase, { IconBaseProps } from '../IconBase';

export const NumberIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M20.34 10.8H16.86V12.54H20.34V14.28H17.73V16.02H20.34V17.76H16.86V19.5H20.34C20.862 19.5 21.21 19.326 21.558 18.978C21.906 18.63 22.08 18.282 22.08 17.76V12.54C22.08 12.018 21.906 11.67 21.558 11.322C21.21 10.974 20.862 10.8 20.34 10.8Z"
            fill="currentColor"
        />
        <path
            d="M14.2499 15.9H9.02991V12.42C9.02991 11.898 9.20391 11.55 9.55191 11.202C9.89991 10.854 10.2479 10.68 10.7699 10.68H12.5099V8.94001H9.02991V7.20001H12.5099C13.0319 7.20001 13.3799 7.37401 13.7279 7.72201C14.0759 8.07001 14.2499 8.41801 14.2499 8.94001V10.68C14.2499 11.202 14.0759 11.55 13.7279 11.898C13.3799 12.246 13.0319 12.42 12.5099 12.42H10.7699V14.16H14.2499V15.9Z"
            fill="currentColor"
        />
        <path
            d="M4.67995 10.56V3.60001H2.93995V4.47001H1.19995V6.21001H2.93995V10.56H1.19995V12.3H6.41995V10.56H4.67995Z"
            fill="currentColor"
        />
    </IconBase>
);
