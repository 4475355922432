import IconBase, { IconBaseProps } from '../IconBase';

export const RefreshIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4691 1.47027C10.7616 1.17705 11.2365 1.17651 11.5297 1.46907L13.5297 3.46451C13.6705 3.60501 13.7498 3.79568 13.75 3.99459C13.7502 4.19351 13.6714 4.38436 13.5309 4.52518L11.5309 6.52973C11.2384 6.82295 10.7635 6.82349 10.4703 6.53093C10.177 6.23837 10.1765 5.7635 10.4691 5.47027L11.137 4.80083C7.53974 5.2275 4.75 8.28796 4.75 12C4.75 14.2892 5.81011 16.3303 7.4691 17.6602C7.79229 17.9193 7.84426 18.3913 7.58519 18.7145C7.32611 19.0377 6.85409 19.0896 6.5309 18.8306C4.53204 17.2282 3.25 14.7636 3.25 12C3.25 7.4287 6.75548 3.67615 11.2249 3.28386L10.4703 2.53093C10.177 2.23837 10.1765 1.7635 10.4691 1.47027ZM16.4148 5.28553C16.6739 4.96234 17.1459 4.91036 17.4691 5.16944C19.468 6.77179 20.75 9.23643 20.75 12C20.75 16.5606 17.2609 20.3063 12.8065 20.7133L13.5418 21.4814C13.8282 21.7807 13.8178 22.2554 13.5186 22.5418C13.2194 22.8283 12.7446 22.8179 12.4582 22.5187L10.4582 20.4294C10.3206 20.2856 10.2457 20.093 10.2502 19.894C10.2546 19.695 10.338 19.506 10.4819 19.3685L12.4819 17.4578C12.7814 17.1716 13.2562 17.1825 13.5423 17.482C13.8284 17.7815 13.8176 18.2562 13.5181 18.5424L12.8261 19.2035C16.4412 18.7934 19.25 15.7247 19.25 12C19.25 9.7108 18.1899 7.66971 16.5309 6.33981C16.2077 6.08074 16.1557 5.60872 16.4148 5.28553Z"
            fill="currentColor"
        />
    </IconBase>
);
