import { PublicFile } from './types';
import * as storage from '../utils/local-storage';

type UploadFile = {
    file: File;
    onUploadProgress?: (progress: number) => void;
};

export function uploadFile({
    file,
    onUploadProgress,
}: UploadFile): Promise<PublicFile> {
    const baseURL = String(import.meta.env.VITE_API_BASE_URL);
    const targetUrl = new URL('/files/', baseURL);
    const token = storage.get('auth');

    const formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();

        function updateProgress(event: ProgressEvent) {
            if (event.lengthComputable) {
                const percentComplete = Math.floor(
                    (event.loaded / event.total) * 100,
                );
                onUploadProgress?.(percentComplete);
            } else {
                // Unable to compute progress information since the total size is unknown
                onUploadProgress?.(0);
            }
        }

        function transferComplete() {
            onUploadProgress?.(100);
            try {
                if (req.status >= 200 && req.status <= 299) {
                    resolve(JSON.parse(req.response));
                } else {
                    reject(
                        new Error(
                            'An error occurred while transferring the file.',
                        ),
                    );
                }
            } catch (e) {
                reject(e);
            }
        }

        function transferFailed() {
            onUploadProgress?.(0);
            reject(new Error('An error occurred while transferring the file.'));
        }

        function transferCanceled() {
            onUploadProgress?.(0);
            reject(new Error('The transfer has been canceled by the user.'));
        }

        function transferTimeout() {
            onUploadProgress?.(0);
            reject(new Error('The transfer has been canceled by timeout.'));
        }

        req.addEventListener('load', transferComplete);
        req.upload.addEventListener('progress', updateProgress);
        req.upload.addEventListener('error', transferFailed);
        req.upload.addEventListener('abort', transferCanceled);
        req.upload.addEventListener('timeout', transferTimeout);

        req.open('POST', targetUrl, true);
        req.setRequestHeader('Authorization', `Bearer ${token}`);
        req.send(formData);
    });
}
