import { SxProps, Theme } from '@mui/material';

export const menu: SxProps<Theme> = {
    '.MuiPaper-root': {
        border: '1px solid',
        borderColor: 'custom.gray.input',
        boxShadow: '0 5px 32px 0 rgba(31, 41, 54, 0.08)',
    },
    '.MuiList-root': { py: 1 },
    '.MuiMenuItem-root': (theme) => ({
        ...theme.typography.body3,
        mx: 0.5,
        p: 2,
        borderRadius: 1,
    }),
};

export const tip: SxProps = {
    textWrap: 'wrap',
    maxWidth: 220,
    display: 'block',
};

export const select: SxProps = {
    '.drop-list-tip': { display: 'none' },
    '.MuiSelect-icon': {
        width: 20,
        height: 20,
        top: 10,
    },
};
