import cc from 'classcat';
import styles from './PaymentPeriodSwitcher.module.scss';

export type PaymentPeriodValue = 'monthly' | 'yearly';

interface PaymentPeriodSwitcherProps {
    value: PaymentPeriodValue;
    onChange: (value: PaymentPeriodValue) => void;
}

export const PaymentPeriodSwitcher = ({
    value,
    onChange,
}: PaymentPeriodSwitcherProps) => (
    <div className={styles['root']}>
        <button
            className={cc([
                styles['root__button'],
                {
                    [styles['root__button_active']]: value === 'monthly',
                },
            ])}
            onClick={() => onChange('monthly')}
        >
            Monthly
        </button>
        <button
            className={cc([
                styles['root__button'],
                {
                    [styles['root__button_active']]: value === 'yearly',
                },
            ])}
            onClick={() => onChange('yearly')}
        >
            Yearly
        </button>
    </div>
);
