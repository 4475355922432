import IconBase, { IconBaseProps } from '../IconBase';

export const SmarshLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2649 19.1177C17.378 18.4675 17.4347 17.8081 17.4343 17.1474C17.4343 11.0572 12.6656 6.12006 6.78234 6.12006C5.99924 6.12006 5.23526 6.20774 4.49951 6.37366V1H22V19.1177H17.2649Z"
            fill="#004EC3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.57002 23C8.30149 23 9.02579 22.8508 9.70158 22.561C10.3774 22.2712 10.9914 21.8464 11.5086 21.311C12.0259 20.7755 12.4361 20.1397 12.7161 19.4401C12.996 18.7404 13.14 17.9905 13.14 17.2332C13.14 16.4759 12.996 15.726 12.7161 15.0264C12.4361 14.3267 12.0259 13.691 11.5086 13.1555C10.9914 12.62 10.3774 12.1952 9.70158 11.9054C9.02579 11.6156 8.30149 11.4664 7.57002 11.4664C6.09276 11.4664 4.676 12.074 3.63142 13.1555C2.58684 14.237 2 15.7038 2 17.2332C2 18.7627 2.58684 20.2295 3.63142 21.311C4.676 22.3924 6.09276 23 7.57002 23Z"
            fill="#009AFF"
        />
    </IconBase>
);
