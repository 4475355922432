export const DotLoader = () => (
    <svg viewBox="0 0 32 32" fill="green" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="16" r="3" fill="white">
            <animate
                attributeName="opacity"
                attributeType="XML"
                values=".4; 1; .75"
                begin="0s"
                dur="0.9s"
                repeatCount="indefinite"
            />
        </circle>
        <circle cx="16" cy="16" r="3" fill="white">
            <animate
                attributeName="opacity"
                attributeType="XML"
                values=".4; 1; .75"
                begin="0.3s"
                dur="0.9s"
                repeatCount="indefinite"
            />
        </circle>
        <circle cx="26" cy="16" r="3" fill="white">
            <animate
                attributeName="opacity"
                attributeType="XML"
                values=".4; 1; .75"
                begin="0.6s"
                dur="0.9s"
                repeatCount="indefinite"
            />
        </circle>
    </svg>
);
