import { badgeClasses, BadgeProps } from '@mui/material';
import { getSquare } from '../../theme/style.helpers';
import { ScheduledIcon } from './ScheduledIcon';

export const staticBadgeProps: BadgeProps = {
    overlap: 'circular',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    sx: {
        [`.${badgeClasses.badge}`]: {
            ...getSquare(20),
            bgcolor: 'white',
        },
    },
    badgeContent: <ScheduledIcon sx={getSquare(16)} />,
};
