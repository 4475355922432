import IconBase, { IconBaseProps } from '../IconBase';

export const PdfFileIcon = (props: IconBaseProps) => (
    <IconBase {...props} viewBox="0 0 32 32">
        <g clipPath="url(#clip0_5937_815)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.20008 0H18.8987L27.9662 9.45128V27.8341C27.9662 30.1369 26.1031 32 23.8083 32H8.20008C5.89724 32 4.03418 30.1369 4.03418 27.8341V4.1659C4.03414 1.86306 5.8972 0 8.20008 0V0Z"
                fill="#E5252A"
            />
            <path
                opacity="0.302"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8906 0V9.37931H27.9661L18.8906 0Z"
                fill="white"
            />
            <path
                d="M8.66406 23.8761V18.031H11.1508C11.7665 18.031 12.2543 18.1989 12.6221 18.5428C12.9899 18.8786 13.1738 19.3344 13.1738 19.9021C13.1738 20.4698 12.9899 20.9256 12.6221 21.2614C12.2543 21.6052 11.7665 21.7731 11.1508 21.7731H10.1593V23.8761H8.66406ZM10.1593 20.5018H10.9829C11.2068 20.5018 11.3827 20.4538 11.5027 20.3419C11.6226 20.2379 11.6866 20.094 11.6866 19.9021C11.6866 19.7102 11.6226 19.5663 11.5027 19.4623C11.3827 19.3504 11.2068 19.3024 10.9829 19.3024H10.1593V20.5018ZM13.7895 23.8761V18.031H15.8605C16.2683 18.031 16.6521 18.087 17.0119 18.2069C17.3717 18.3268 17.6995 18.4948 17.9874 18.7267C18.2753 18.9505 18.5071 19.2544 18.675 19.6382C18.835 20.022 18.9229 20.4618 18.9229 20.9575C18.9229 21.4453 18.835 21.8851 18.675 22.2689C18.5071 22.6527 18.2753 22.9565 17.9874 23.1804C17.6995 23.4123 17.3717 23.5802 17.0119 23.7002C16.6521 23.8201 16.2683 23.8761 15.8605 23.8761H13.7895ZM15.2528 22.6047H15.6845C15.9164 22.6047 16.1323 22.5808 16.3322 22.5248C16.5241 22.4688 16.708 22.3808 16.884 22.2609C17.0519 22.141 17.1878 21.973 17.2837 21.7492C17.3797 21.5253 17.4277 21.2614 17.4277 20.9575C17.4277 20.6457 17.3797 20.3818 17.2837 20.158C17.1878 19.9341 17.0519 19.7662 16.884 19.6462C16.708 19.5263 16.5241 19.4383 16.3322 19.3824C16.1323 19.3264 15.9164 19.3024 15.6845 19.3024H15.2528V22.6047ZM19.6746 23.8761V18.031H23.8325V19.3024H21.1698V20.2379H23.2967V21.5013H21.1698V23.8761H19.6746Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5937_815">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </IconBase>
);
