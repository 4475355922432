import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const RefreshIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.07692 2.5C3.39555 2.5 3.65385 2.7583 3.65385 3.07692V5.625L4.99985 4.40986C6.32636 3.22261 8.07948 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 10.3186 17.2417 10.5769 16.9231 10.5769C16.6045 10.5769 16.3462 10.3186 16.3462 10C16.3462 6.49512 13.5049 3.65385 10 3.65385C8.3747 3.65385 6.89331 4.26408 5.77028 5.26883C5.76998 5.2691 5.76968 5.26937 5.76937 5.26964L4.57693 6.34615H6.92308C7.2417 6.34615 7.5 6.60445 7.5 6.92308C7.5 7.2417 7.2417 7.5 6.92308 7.5H3.07692C2.7583 7.5 2.5 7.2417 2.5 6.92308V3.07692C2.5 2.7583 2.7583 2.5 3.07692 2.5ZM3.07692 9.42308C3.39555 9.42308 3.65385 9.68137 3.65385 10C3.65385 13.5049 6.49512 16.3462 10 16.3462C11.6253 16.3462 13.1067 15.7359 14.2297 14.7312C14.23 14.7309 14.2303 14.7306 14.2306 14.7304L15.4231 13.6538H13.0769C12.7583 13.6538 12.5 13.3955 12.5 13.0769C12.5 12.7583 12.7583 12.5 13.0769 12.5H16.9231C17.2417 12.5 17.5 12.7583 17.5 13.0769V16.9231C17.5 17.2417 17.2417 17.5 16.9231 17.5C16.6045 17.5 16.3462 17.2417 16.3462 16.9231V14.375L15.002 15.5885L15.0001 15.5901C13.6736 16.7774 11.9205 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 9.68137 2.7583 9.42308 3.07692 9.42308Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
