import { Components, Theme } from '@mui/material';

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            borderTopWidth: 1,
            borderTopColor: theme.palette.custom.gray.divider,
            borderTopStyle: 'solid',
            justifyContent: 'center',
            '>:not(style)~:not(style)': {
                marginLeft: theme.spacing(4),
            },
            padding: theme.spacing(4, 6),
        }),
    },
};
