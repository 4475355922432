import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const ICON_SIZE = 20;

export const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
    styleOverrides: {
        root: () => ({
            minWidth: ICON_SIZE,
            width: ICON_SIZE,
            height: ICON_SIZE,
            '.MuiSvgIcon-root': {
                width: ICON_SIZE,
                height: ICON_SIZE,
            },
            '+ .MuiListItemText-root': {
                // marginLeft: theme.spacing(2),
            },
        }),
    },
};
