import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const GenerateText = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.25 4C2.25 3.58579 2.58579 3.25 3 3.25H13C13.4142 3.25 13.75 3.58579 13.75 4V7C13.75 7.41421 13.4142 7.75 13 7.75C12.5858 7.75 12.25 7.41421 12.25 7V4.75H8.75V19.25H10C10.4142 19.25 10.75 19.5858 10.75 20C10.75 20.4142 10.4142 20.75 10 20.75H6C5.58579 20.75 5.25 20.4142 5.25 20C5.25 19.5858 5.58579 19.25 6 19.25H7.25V4.75H3.75V7C3.75 7.41421 3.41421 7.75 3 7.75C2.58579 7.75 2.25 7.41421 2.25 7V4ZM12.25 12C12.25 11.5858 12.5858 11.25 13 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12V14C21.75 14.4142 21.4142 14.75 21 14.75C20.5858 14.75 20.25 14.4142 20.25 14V12.75H17.75V19.25H19C19.4142 19.25 19.75 19.5858 19.75 20C19.75 20.4142 19.4142 20.75 19 20.75H15C14.5858 20.75 14.25 20.4142 14.25 20C14.25 19.5858 14.5858 19.25 15 19.25H16.25V12.75H13.75V14C13.75 14.4142 13.4142 14.75 13 14.75C12.5858 14.75 12.25 14.4142 12.25 14V12Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
