import {
    ListItemIcon,
    MenuItem,
    SelectChangeEvent,
    TextField,
    TextFieldProps,
} from '@mui/material';
import { RoleIcon } from './RoleIcon';
import { RoleTypes } from '../../../api/types';

const options = [
    {
        value: RoleTypes.Admin,
        label: 'Admin',
    },
    {
        value: RoleTypes.WorkspaceOwner,
        label: 'Workspace Owner',
    },
    {
        value: RoleTypes.Agent,
        label: 'Agent',
    },
    {
        value: RoleTypes.Manager,
        label: 'Manager',
    },
    {
        value: RoleTypes.Member,
        label: 'Member',
    },
] as const;

type Props = TextFieldProps & {
    value?: RoleTypes;
    onChange: (value: RoleTypes) => void;
};

export const RoleSelect = ({ value, onChange, ...rest }: Props) => {
    return (
        <TextField
            {...rest}
            select
            value={value}
            onChange={(event: SelectChangeEvent<RoleTypes>) => {
                // @ts-expect-error this known, unresolved issue in @mui: https://github.com/mui/material-ui/issues/33399
                onChange(event.target?.value);
            }}
        >
            {options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                    <ListItemIcon>
                        <RoleIcon />
                    </ListItemIcon>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
