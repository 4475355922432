import IconBase, { IconBaseProps } from '../IconBase';

export const CustomLocationIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.75C8.568 3.75 5.75 6.61771 5.75 10.2C5.75 11.957 6.5207 13.5993 7.78786 15.2917C8.85739 16.7201 10.2262 18.1166 11.6869 19.6068C11.7909 19.7128 11.8952 19.8193 12 19.9263C12.1048 19.8193 12.2091 19.7128 12.3131 19.6068C13.7738 18.1166 15.1426 16.7201 16.2121 15.2917C17.4793 13.5993 18.25 11.957 18.25 10.2C18.25 6.61771 15.432 3.75 12 3.75ZM4.25 10.2C4.25 5.82939 7.70002 2.25 12 2.25C16.3 2.25 19.75 5.82939 19.75 10.2C19.75 12.4194 18.7707 14.3772 17.4129 16.1907C16.2779 17.7065 14.8251 19.1879 13.3657 20.6761C13.0892 20.958 12.8125 21.2402 12.5377 21.5228C12.3965 21.6681 12.2026 21.75 12 21.75C11.7974 21.75 11.6035 21.6681 11.4623 21.5228C11.1875 21.2402 10.9108 20.958 10.6343 20.6761C9.17489 19.1879 7.72211 17.7065 6.58714 16.1907C5.2293 14.3772 4.25 12.4194 4.25 10.2ZM12 7.75C10.7574 7.75 9.75 8.75736 9.75 10C9.75 11.2426 10.7574 12.25 12 12.25C13.2426 12.25 14.25 11.2426 14.25 10C14.25 8.75736 13.2426 7.75 12 7.75ZM8.25 10C8.25 7.92893 9.92893 6.25 12 6.25C14.0711 6.25 15.75 7.92893 15.75 10C15.75 12.0711 14.0711 13.75 12 13.75C9.92893 13.75 8.25 12.0711 8.25 10Z"
            fill="currentColor"
        />
    </IconBase>
);
