import { QueryClient } from '@tanstack/react-query';

export const getLatestQueryByKey = (
    queryClient: QueryClient,
    key?: string[],
) => {
    const cache = queryClient.getQueryCache();
    const queries = cache.findAll({ queryKey: key });
    return queries
        .slice()
        .sort((a, b) => b.state.dataUpdatedAt - a.state.dataUpdatedAt)[0];
};
