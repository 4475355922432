import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const SendFilledIcon = (props: SvgIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
            <path
                d="M17.9809 0.547305C17.8889 0.16226 17.4658 -0.0997359 17.0821 0.0364469C17.0821 0.0364469 0.459757 5.90198 0.456992 5.90406C0.20186 5.99876 0.0144864 6.24209 0.00065806 6.52068C-0.0117874 6.78821 0.153461 7.0668 0.39753 7.18017L6.83598 10.1852C6.83598 10.1852 9.97293 7.04882 9.97846 7.04329C10.6007 6.42114 11.5943 7.3834 10.9561 8.02077L7.81364 11.1626C7.81364 11.1626 10.8151 17.593 10.8206 17.6033C10.943 17.8384 11.1822 17.9877 11.445 17.9994C11.7305 18.0126 11.9946 17.7941 12.097 17.5384C12.097 17.5384 17.9602 0.928893 17.9636 0.918524C18.0072 0.798932 18.0099 0.669662 17.9809 0.547305Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
