import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const Add = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4999 2.70831C10.8451 2.70831 11.1249 2.98814 11.1249 3.33331V9.37498H17.1666C17.5118 9.37498 17.7916 9.6548 17.7916 9.99998C17.7916 10.3452 17.5118 10.625 17.1666 10.625H11.1249V16.6666C11.1249 17.0118 10.8451 17.2916 10.4999 17.2916C10.1547 17.2916 9.87492 17.0118 9.87492 16.6666V10.625H3.83325C3.48807 10.625 3.20825 10.3452 3.20825 9.99998C3.20825 9.6548 3.48807 9.37498 3.83325 9.37498H9.87492V3.33331C9.87492 2.98814 10.1547 2.70831 10.4999 2.70831Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
