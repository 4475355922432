import IconBase, { IconBaseProps } from '../IconBase';

export const LinkedInLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <g clipPath="url(#clip0_5788_3208)">
            <path
                d="M20.7273 0H3.27273C1.46525 0 0 1.46525 0 3.27273V20.7273C0 22.5347 1.46525 24 3.27273 24H20.7273C22.5347 24 24 22.5347 24 20.7273V3.27273C24 1.46525 22.5347 0 20.7273 0Z"
                fill="#3375B0"
            />
            <path
                d="M8.64548 6.54545C8.64548 6.95001 8.52551 7.34547 8.30075 7.68185C8.076 8.01822 7.75654 8.28039 7.38278 8.43521C7.00902 8.59002 6.59775 8.63053 6.20097 8.55161C5.80419 8.47268 5.43973 8.27787 5.15367 7.99181C4.8676 7.70575 4.67279 7.34128 4.59387 6.9445C4.51494 6.54772 4.55545 6.13645 4.71027 5.76269C4.86508 5.38894 5.12725 5.06948 5.46363 4.84472C5.8 4.61996 6.19547 4.5 6.60002 4.5C7.14251 4.5 7.66278 4.7155 8.04638 5.0991C8.42997 5.4827 8.64548 6.00297 8.64548 6.54545Z"
                fill="white"
            />
            <path
                d="M8.18179 9.95454V18.9914C8.18215 19.058 8.16933 19.1241 8.14406 19.1858C8.1188 19.2475 8.08159 19.3036 8.03458 19.3509C7.98757 19.3981 7.93167 19.4356 7.87011 19.4612C7.80856 19.4868 7.74255 19.5 7.67588 19.5H5.51997C5.4533 19.5002 5.38726 19.4872 5.32563 19.4617C5.26401 19.4363 5.20801 19.399 5.16087 19.3518C5.11373 19.3047 5.07637 19.2487 5.05094 19.1871C5.02552 19.1254 5.01252 19.0594 5.0127 18.9927V9.95454C5.0127 9.82 5.06614 9.69097 5.16127 9.59584C5.25641 9.50071 5.38543 9.44727 5.51997 9.44727H7.67588C7.81018 9.44763 7.93886 9.50123 8.03369 9.59632C8.12853 9.69142 8.18179 9.82024 8.18179 9.95454Z"
                fill="white"
            />
            <path
                d="M19.451 14.6591V19.0336C19.4512 19.0949 19.4392 19.1556 19.4159 19.2123C19.3925 19.269 19.3581 19.3204 19.3148 19.3638C19.2715 19.4071 19.22 19.4415 19.1633 19.4648C19.1066 19.4882 19.0459 19.5001 18.9846 19.5H16.6664C16.6051 19.5001 16.5444 19.4882 16.4878 19.4648C16.4311 19.4415 16.3796 19.4071 16.3363 19.3638C16.2929 19.3204 16.2586 19.269 16.2352 19.2123C16.2118 19.1556 16.1999 19.0949 16.2001 19.0336V14.7941C16.2001 14.1613 16.3855 12.0232 14.546 12.0232C13.121 12.0232 12.8305 13.4863 12.7733 14.1436V19.0336C12.7733 19.1561 12.7251 19.2737 12.6391 19.361C12.5531 19.4483 12.4362 19.4982 12.3137 19.5H10.0746C10.0134 19.5 9.95286 19.4879 9.89636 19.4644C9.83985 19.441 9.78853 19.4066 9.74533 19.3633C9.70214 19.32 9.66791 19.2685 9.64462 19.212C9.62134 19.1554 9.60944 19.0948 9.60962 19.0336V9.91497C9.60944 9.85379 9.62134 9.79318 9.64462 9.7366C9.66791 9.68003 9.70214 9.62861 9.74533 9.58528C9.78853 9.54196 9.83985 9.50758 9.89636 9.48413C9.95286 9.46068 10.0134 9.4486 10.0746 9.4486H12.3137C12.4374 9.4486 12.556 9.49774 12.6435 9.5852C12.7309 9.67266 12.7801 9.79128 12.7801 9.91497V10.7032C13.3092 9.90951 14.0933 9.29724 15.7664 9.29724C19.4728 9.29724 19.451 12.7582 19.451 14.6591Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5788_3208">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </IconBase>
);
