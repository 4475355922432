import IconBase, { IconBaseProps } from '../IconBase';

export const CaIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M18.6206 4.138H5.37939V19.8622H18.6206V4.138Z"
            fill="#F5F5F5"
        />
        <path
            d="M1.79742 4.13782C0.804703 4.13782 0 4.94252 0 5.93524V18.0646C0 19.0572 0.804703 19.862 1.79742 19.862H5.37933V4.13782H1.79742Z"
            fill="#FF4B55"
        />
        <path
            d="M22.2026 4.13782H18.6207V19.8619H22.2026C23.1953 19.8619 24.0001 19.0572 24.0001 18.0645V5.93524C24.0001 4.94252 23.1954 4.13782 22.2026 4.13782Z"
            fill="#FF4B55"
        />
        <path
            d="M14.5111 13.8167L16.9665 12.4137L16.4031 12.132C16.2451 12.0529 16.1546 11.8823 16.178 11.7071L16.3613 10.3327L15.3218 10.6351C15.0922 10.7019 14.8538 10.5607 14.8022 10.3272L14.7026 9.87718L13.7265 11.0195C13.5913 11.1779 13.3334 11.0569 13.3687 10.8517L13.7856 8.42785L13.1474 8.60236C12.961 8.65336 12.7641 8.56852 12.6732 8.39799L12.0012 7.13841V7.13654L12.0007 7.13747L12.0002 7.13654V7.13841L11.3281 8.39799C11.2372 8.56847 11.0403 8.65332 10.8539 8.60236L10.2157 8.42785L10.6325 10.8517C10.6678 11.0569 10.41 11.1779 10.2747 11.0196L9.29859 9.87722L9.19903 10.3272C9.14737 10.5608 8.90906 10.7019 8.67942 10.6351L7.63987 10.3327L7.8232 11.7072C7.84659 11.8823 7.75612 12.053 7.5981 12.132L7.03467 12.4137L9.49012 13.8168C9.74446 13.9621 9.85593 14.2704 9.75332 14.5448L9.54342 15.1062L11.6474 14.9266C11.7638 14.9166 11.8629 15.0104 11.8595 15.1271L11.7932 17.3792H12.207L12.1407 15.127C12.1372 15.0102 12.2363 14.9165 12.3528 14.9265L14.4577 15.1062L14.2478 14.5448C14.1453 14.2703 14.2567 13.9621 14.5111 13.8167Z"
            fill="#FF4B55"
        />
    </IconBase>
);
