import IconBase, { IconBaseProps } from '../IconBase';

/** @deprecated use icon from /src/icons/common/CMoreIcon.tsx */
export const MoreIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M5.25 9.5C4.0125 9.5 3 10.5125 3 11.75C3 12.9875 4.0125 14 5.25 14C6.4875 14 7.5 12.9875 7.5 11.75C7.5 10.5125 6.4875 9.5 5.25 9.5ZM18.75 9.5C17.5125 9.5 16.5 10.5125 16.5 11.75C16.5 12.9875 17.5125 14 18.75 14C19.9875 14 21 12.9875 21 11.75C21 10.5125 19.9875 9.5 18.75 9.5ZM12 9.5C10.7625 9.5 9.75 10.5125 9.75 11.75C9.75 12.9875 10.7625 14 12 14C13.2375 14 14.25 12.9875 14.25 11.75C14.25 10.5125 13.2375 9.5 12 9.5Z"
            fill="currentColor"
        />
    </IconBase>
);
