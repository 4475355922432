import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ArrowDownIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.41076 13.0893C9.7362 13.4147 10.2638 13.4147 10.5893 13.0893L15.5893 8.0893C15.9147 7.76386 15.9147 7.23622 15.5893 6.91079C15.2638 6.58535 14.7362 6.58535 14.4108 6.91079L10 11.3215L5.58928 6.91079C5.26384 6.58535 4.7362 6.58535 4.41076 6.91079C4.08533 7.23622 4.08533 7.76386 4.41076 8.0893L9.41076 13.0893Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
