import {
    WorkflowAction,
    WorkflowActionTypes,
    WorkflowCondition,
    WorkflowConditionTypes,
    WorkflowStep,
    WorkflowStepType,
} from '../types';
import LastContactedStep from './LastContactedStep';
import ScheduleStep from './ScheduleStep';
import SendMessageStep from './SendMessageStep';
import SleepStep from './SleepStep';
import TimeFrameStep from './TimeFrameStep';
import WebhookStep from './WebhookStep';
import AddToCohortStep from './AddToCohortStep';
import { FilterStep } from './FilterStep/FilterStep';
import AssignTeamMemberStep from './AssignTeamMemberStep';

interface WorkflowStepConfiguratorProps {
    step: WorkflowStep;
}

export const WorkflowStepConfigurator = ({
    step,
}: WorkflowStepConfiguratorProps) => {
    if (step.type === WorkflowStepType.Action) {
        const actionStep = step as WorkflowAction;

        switch (actionStep.action) {
            case WorkflowActionTypes.SendSMS:
                return <SendMessageStep step={actionStep} />;

            case WorkflowActionTypes.Webhook:
                return <WebhookStep step={actionStep} />;

            case WorkflowActionTypes.Sleep:
                return <SleepStep />;

            case WorkflowActionTypes.AddToCohort:
                return <AddToCohortStep step={actionStep} />;

            case WorkflowActionTypes.AssignTeamMember:
                return <AssignTeamMemberStep step={actionStep} />;
        }
    }

    if (step.type === WorkflowStepType.Conditional) {
        const conditionalStep = step as WorkflowCondition;

        switch (conditionalStep.kind) {
            case WorkflowConditionTypes.LastContacted:
                return <LastContactedStep step={conditionalStep} />;

            case WorkflowConditionTypes.Schedule:
                return <ScheduleStep step={conditionalStep} />;

            case WorkflowConditionTypes.TimeFrame:
                return <TimeFrameStep step={conditionalStep} />;

            case WorkflowConditionTypes.Filter:
                return <FilterStep step={conditionalStep} />;
        }
    }

    return null;
};
