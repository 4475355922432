import { ScheduleDay, Time24Format } from '../../types';
import { OptionBase } from '../../../../elements/AdvancedSelect';
import { ianaTimeZones } from '../../../../utils/iana-timezones';
import dayjs from 'dayjs';

type DayOfWeek = {
    id: ScheduleDay;
    label: string;
};

export const AM_PM: OptionBase[] = [
    {
        id: 'am',
        label: 'AM',
    },
    {
        id: 'pm',
        label: 'PM',
    },
];

export const DAYS_OF_WEEK: DayOfWeek[] = [
    { id: '1', label: 'Mon' },
    { id: '2', label: 'Tue' },
    { id: '3', label: 'Wed' },
    { id: '4', label: 'Thu' },
    { id: '5', label: 'Fri' },
    { id: '6', label: 'Sat' },
    { id: '0', label: 'Sun' },
];

export type TimeSelectItem = Time24Format & { id: string; label: string };

const TIME_STEP_MINUTES = 30;

export const getTimeList = () => {
    const init = dayjs().startOf('day');
    const records: TimeSelectItem[] = [];

    let counter = init.clone();
    while (counter.isSame(init, 'date')) {
        const hours = counter.get('hours');
        const minutes = counter.get('minutes');
        records.push({
            id: `${hours}:${minutes}`,
            label: counter.format('hh:mm a'),
            hours,
            minutes,
        });
        counter = counter.add(TIME_STEP_MINUTES, 'minutes');
    }

    return records;
};

export const TIMEZONES: OptionBase[] = ianaTimeZones.map((tz) => ({
    id: tz,
    label: tz,
}));
