import client from './http';
import {
    Campaign,
    CreateCampaignDto,
    UpdateCampaignDto,
} from './campaign.types';

export function getCampaigns(scheduled = false): Promise<Campaign[]> {
    return client()
        .get('/campaigns', {
            params: {
                scheduled,
            },
        })
        .then((res) => res.data);
}

/** @depreacted use v3 instead */
export function createCampaign(payload: CreateCampaignDto): Promise<Campaign> {
    return client()
        .post('/campaigns/v2', payload)
        .then((res) => res.data);
}

export function renameCampaign(campaignId: number, name: string) {
    return client()
        .put<Campaign>(`/campaigns/rename/${campaignId}`, { name })
        .then(({ data }) => data);
}

export function updateCampaign(
    id: number,
    payload: UpdateCampaignDto,
): Promise<Campaign> {
    return client()
        .put(`/campaigns/${id}`, payload)
        .then((res) => res.data);
}

export function removeCampaign(campaignId: number): Promise<void> {
    return client()
        .delete(`/campaigns/${campaignId}`)
        .then((res) => res.data);
}
