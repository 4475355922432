import { Box, Button, Typography } from '@mui/material';
import { indicatorContainer } from './styles';
import { Add } from './CAdd';

export const EmptyCampaignList = ({
    startChat,
}: {
    startChat?: () => void;
}) => (
    <Box sx={indicatorContainer}>
        <Box sx={{ fontSize: 48 }}>📂</Box>
        <Typography variant="h3" mt={6}>
            Campaign list is empty
        </Typography>
        <Typography variant="body3" mt={2}>
            Your campaigns appears here
        </Typography>
        {startChat && (
            <Button
                onClick={() => {
                    startChat();
                }}
                startIcon={<Add />}
                color="info"
                variant="text"
                sx={{ mt: 6 }}
            >
                Create a campaign
            </Button>
        )}
    </Box>
);
