import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const MoreHorizFilledIcon = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.375 7.91666C3.34375 7.91666 2.5 8.76041 2.5 9.79166C2.5 10.8229 3.34375 11.6667 4.375 11.6667C5.40625 11.6667 6.25 10.8229 6.25 9.79166C6.25 8.76041 5.40625 7.91666 4.375 7.91666ZM15.625 7.91666C14.5938 7.91666 13.75 8.76041 13.75 9.79166C13.75 10.8229 14.5938 11.6667 15.625 11.6667C16.6562 11.6667 17.5 10.8229 17.5 9.79166C17.5 8.76041 16.6562 7.91666 15.625 7.91666ZM10 7.91666C8.96875 7.91666 8.125 8.76041 8.125 9.79166C8.125 10.8229 8.96875 11.6667 10 11.6667C11.0312 11.6667 11.875 10.8229 11.875 9.79166C11.875 8.76041 11.0312 7.91666 10 7.91666Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
