import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const AttentionIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="24"
                cy="24"
                r="24"
                fill="url(#paint0_linear_15788_52675)"
            />
            <circle
                cx="24"
                cy="24"
                r="23.5"
                stroke="#FA776B"
                strokeOpacity="0.32"
            />
            <circle cx="24" cy="24" r="10" fill="#E54132" />
            <path
                d="M24.0164 31.5C23.2091 31.5 22.5382 30.7494 22.5382 29.8462C22.5382 28.9429 23.2091 28.1923 24.0164 28.1923C24.8236 28.1923 25.4945 28.9429 25.4618 29.8889C25.5 30.7494 24.7909 31.5 24.0164 31.5ZM25.3636 20.808C25.2982 22.0895 25.2273 23.365 25.1618 24.6466C25.1291 25.0616 25.1291 25.44 25.1291 25.8488C25.0964 26.5263 24.6218 27.0511 24.0164 27.0511C23.4109 27.0511 22.9418 26.5629 22.9036 25.8855C22.8055 23.8899 22.7018 21.9309 22.6036 19.9353C22.5709 19.4104 22.5382 18.8795 22.5 18.3547C22.5 17.4881 22.9364 16.774 23.6455 16.5482C24.3545 16.3591 25.0582 16.7374 25.3636 17.4881C25.4673 17.7505 25.5 18.0129 25.5 18.318C25.4673 19.1541 25.3964 19.9841 25.3636 20.808Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_15788_52675"
                    x1="24"
                    y1="0"
                    x2="24"
                    y2="48"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FDF0EF" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
);
