import IconBase, { IconBaseProps } from '../IconBase';

export const TriangleRightIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8511 6.49547L15.9268 10.8239C16.6911 11.4756 16.6911 12.5285 15.9268 13.1803L10.8511 17.5086C9.61649 18.5615 7.5 17.8095 7.5 16.3221L7.5 7.66531C7.5 6.17794 9.61649 5.44262 10.8511 6.49547Z"
            fill="currentColor"
        />
    </IconBase>
);
