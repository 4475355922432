import { Box, Button, Typography } from '@mui/material';
import { Add } from './CAdd';
import { indicatorContainer } from './styles';

export const ListErrorIndicator = ({
    startChat,
}: {
    startChat?: () => void;
}) => (
    <Box sx={indicatorContainer}>
        <Box sx={{ fontSize: 48 }}>🤔</Box>
        <Typography variant="h3" mt={6}>
            Something went wrong
        </Typography>
        <Typography variant="body3" mt={2}>
            Clerk can’t load your campaigns. Try to reload the page
            {startChat && ' or click the button'}.
        </Typography>
        {startChat && (
            <Button
                onClick={startChat}
                startIcon={<Add />}
                color="info"
                variant="text"
                sx={{ mt: 6 }}
            >
                Send a message
            </Button>
        )}
    </Box>
);
