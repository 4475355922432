import { TextField } from '@mui/material';
import { GenericDialog } from '../GenericDialog/GenericDialog';
import { IntegrationIcon } from '../../../icons/shared/IntegrationIcon';
import { createIntegrationUrl } from '../../../main/settings/Integrations/utils';
import { useForm } from 'react-hook-form';
import { AvailableIntegration } from '../../../main/settings/Integrations/integrations-list';
import { Profile } from '../../../api/types';
import { ArrowLeftIcon } from '../../../icons/shared/ArrowLeftIcon';

type Props = {
    open: boolean;
    onClose: () => void;
    integration: AvailableIntegration;
    me: Profile;
};

type Inputs = {
    field: string;
};

export function ConnectDynamicsIntegrationModal({
    onClose,
    open,
    integration,
    me,
}: Props) {
    const {
        handleSubmit,
        formState: { errors, isValid },
        register,
    } = useForm<Inputs>({
        defaultValues: {
            field: '',
        },
    });

    return (
        <GenericDialog
            open={open}
            onClose={onClose}
            IconComponent={IntegrationIcon}
            title="Connect Microsoft Dynamics"
            description={
                <>
                    Connect your dynamics workspace by entering the URL of your
                    environment. For example{' '}
                    <b>https://myaccount.crm.dynamics.com</b>
                </>
            }
            onSubmit={handleSubmit((formData: Inputs) => {
                onClose();
                const url = new URL(
                    createIntegrationUrl(integration, me, {
                        orgURL: formData.field,
                    }),
                );
                url.searchParams.append(
                    'scope',
                    `${formData.field}/.default offline_access`,
                );

                window.open(url.href, '_blank', 'noopener, noreferrer');
            })}
            confirmLabel="Connect"
            confirmButtonProps={{
                disabled: !isValid,
            }}
            dismissButtonProps={{
                startIcon: <ArrowLeftIcon />,
                color: 'primary',
            }}
            dismissLabel="Back"
        >
            <TextField
                {...register('field', { required: true })}
                error={!!errors.field}
                fullWidth
                placeholder="Dynamics Workspace URL"
                label="Enter your dynamics workspace URL"
                helperText={
                    !!errors.field && 'Dynamics Workspace URL field is required'
                }
            />
        </GenericDialog>
    );
}
