import { Components } from '@mui/material/styles/components';
import { ICON_SIZE } from './MuiListItemIcon.override';

export const MuiButtonBase: Components['MuiButtonBase'] = {
    defaultProps: { disableRipple: true },
    styleOverrides: {
        root: {
            '&.MuiMenuItem-root .MuiListItemIcon-root': {
                minWidth: ICON_SIZE,
            },
        },
    },
};
