import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const AttentionFilled = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.5382 17.8462C10.5382 18.7494 11.2091 19.5 12.0164 19.5C12.7909 19.5 13.5 18.7494 13.4618 17.8889C13.4945 16.9429 12.8236 16.1923 12.0164 16.1923C11.2091 16.1923 10.5382 16.9429 10.5382 17.8462ZM13.2627 10.7273C13.2968 10.088 13.3309 9.44875 13.3636 8.80797C13.3798 8.40014 13.4054 7.99082 13.431 7.58C13.4572 7.16091 13.4835 6.74025 13.5 6.31804C13.5 6.0129 13.4673 5.75048 13.3636 5.48807C13.0582 4.73743 12.3545 4.35906 11.6455 4.54824C10.9364 4.77404 10.5 5.48807 10.5 6.35466C10.5358 6.84731 10.5669 7.34534 10.5976 7.83865C10.5996 7.87088 10.6016 7.90309 10.6036 7.93527C10.6527 8.93308 10.7032 9.92172 10.7536 10.9104C10.8041 11.899 10.8545 12.8877 10.9036 13.8855C10.9418 14.5629 11.4109 15.0511 12.0164 15.0511C12.6218 15.0511 13.0964 14.5263 13.1291 13.8488V13.8488C13.1291 13.4399 13.1291 13.0616 13.1618 12.6466C13.1945 12.0058 13.2286 11.3665 13.2627 10.7273Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
