import IconBase, { IconBaseProps } from '../IconBase';

export const ListIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1761 1.99747L17.5045 6.4016C18.5574 7.47287 17.8221 9.30934 16.3347 9.30934H7.6779C6.19054 9.30934 5.4385 7.47287 6.49135 6.4016L10.8197 1.99747C11.4715 1.3343 12.5244 1.3343 13.1761 1.99747ZM10.8239 22.0026L6.49547 17.5984C5.44262 16.5272 6.17794 14.6907 7.66531 14.6907H16.3221C17.8095 14.6907 18.5615 16.5272 17.5086 17.5984L13.1803 22.0026C12.5285 22.6657 11.4756 22.6657 10.8239 22.0026Z"
            fill="currentColor"
        />
    </IconBase>
);
