import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import AdvancedSelect, { OptionId } from '../../../elements/AdvancedSelect';
import Button from '../../../elements/Buttons';
import WorkflowScheduleRow from '../../WorkflowScheduleRow';
import { ScheduleDay, WorkflowSchedule } from '../types';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import { TIMEZONES } from '../WorkflowStepConfigurator/ScheduleStep/utils';
import styles from './WorkflowAside.module.scss';

export const WorkflowAsideSchedule = () => {
    const { state, dispatch } = useWorkflowContext();

    const schedules = state?.workflow?.schedules || [];

    const updateSchedule = (schedule: WorkflowSchedule) => {
        dispatch({
            type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_TRIGGER_SETTINGS_SCHEDULES,
            payload: schedule,
        });
    };

    const createNewSchedule = () => {
        updateSchedule({
            dayOfWeek: dayjs().day().toString() as ScheduleDay,
            enabled: true,
            time: dayjs().format('HH:mmZ'),
            // @ts-ignore
            // Note: this is a hack to get around the fact that the new schedules aren't saved in db yet
            id: uuidv4(),
            timezone: dayjs.tz.guess(),
        });
    };

    const timezone = schedules.length
        ? schedules[0].timezone
        : dayjs.tz.guess();

    return (
        <section className={styles['aside__box']}>
            <div className={styles['aside__box-select']}>
                <label className={styles['aside__box-select-label']}>
                    Timezone
                </label>
                <AdvancedSelect
                    placeholder="timezone"
                    options={TIMEZONES}
                    onChange={(id: OptionId) =>
                        schedules.map((s) =>
                            updateSchedule({
                                ...s,
                                timezone: id as string,
                            }),
                        )
                    }
                    searchable
                    value={timezone}
                />
            </div>
            <div className={styles['aside__box-select']}>
                <label className={styles['aside__box-label']}>Schedules</label>
                {schedules.map((s) => (
                    <WorkflowScheduleRow
                        key={s.id}
                        updateSchedule={updateSchedule}
                        schedule={s}
                    />
                ))}
                <Button type="text" onClick={createNewSchedule} fullWidth>
                    + Add new schedule
                </Button>
            </div>
        </section>
    );
};
