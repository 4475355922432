import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const AddressIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99999 3.125C7.13999 3.125 4.79166 5.51476 4.79166 8.5C4.79166 9.96417 5.43391 11.3327 6.48988 12.743C7.38114 13.9334 8.52183 15.0971 9.73911 16.339C9.8257 16.4273 9.91268 16.5161 9.99999 16.6053C10.0873 16.5161 10.1743 16.4273 10.2609 16.339C11.4781 15.0971 12.6188 13.9334 13.5101 12.743C14.5661 11.3327 15.2083 9.96417 15.2083 8.5C15.2083 5.51476 12.86 3.125 9.99999 3.125ZM3.54166 8.5C3.54166 4.85783 6.41667 1.875 9.99999 1.875C13.5833 1.875 16.4583 4.85783 16.4583 8.5C16.4583 10.3495 15.6422 11.981 14.5107 13.4922C13.5649 14.7554 12.3542 15.99 11.138 17.2301C10.9077 17.465 10.6771 17.7001 10.4481 17.9357C10.3304 18.0567 10.1688 18.125 9.99999 18.125C9.83118 18.125 9.66954 18.0567 9.55187 17.9357C9.32287 17.7001 9.0923 17.465 8.86193 17.2301C7.64574 15.9899 6.43508 14.7554 5.48927 13.4922C4.35774 11.981 3.54166 10.3495 3.54166 8.5ZM9.99999 7.29167C9.42469 7.29167 8.95832 7.75804 8.95832 8.33333C8.95832 8.90863 9.42469 9.375 9.99999 9.375C10.5753 9.375 11.0417 8.90863 11.0417 8.33333C11.0417 7.75804 10.5753 7.29167 9.99999 7.29167ZM7.70832 8.33333C7.70832 7.06768 8.73434 6.04167 9.99999 6.04167C11.2656 6.04167 12.2917 7.06768 12.2917 8.33333C12.2917 9.59899 11.2656 10.625 9.99999 10.625C8.73434 10.625 7.70832 9.59899 7.70832 8.33333Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
