import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const MoreIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                d="M4.375 7.91675C3.34375 7.91675 2.5 8.7605 2.5 9.79175C2.5 10.823 3.34375 11.6667 4.375 11.6667C5.40625 11.6667 6.25 10.823 6.25 9.79175C6.25 8.7605 5.40625 7.91675 4.375 7.91675ZM15.625 7.91675C14.5938 7.91675 13.75 8.7605 13.75 9.79175C13.75 10.823 14.5938 11.6667 15.625 11.6667C16.6562 11.6667 17.5 10.823 17.5 9.79175C17.5 8.7605 16.6562 7.91675 15.625 7.91675ZM10 7.91675C8.96875 7.91675 8.125 8.7605 8.125 9.79175C8.125 10.823 8.96875 11.6667 10 11.6667C11.0312 11.6667 11.875 10.823 11.875 9.79175C11.875 8.7605 11.0312 7.91675 10 7.91675Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
