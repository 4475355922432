import IconBase, { IconBaseProps } from '../IconBase';

export const CustomCubeIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 3.86057C11.9924 3.86489 11.9843 3.86947 11.9756 3.87436L5.5992 7.46108L12.0001 10.6615L18.401 7.46108L12.0246 3.87436C12.0159 3.86947 12.0078 3.86489 12.0001 3.86057ZM19.2501 8.71358L12.7501 11.9636V19.7177L19.2246 16.0758C19.2336 16.0707 19.2421 16.0659 19.2501 16.0614C19.2501 16.0522 19.2501 16.0425 19.2501 16.0322V8.71358ZM11.2501 19.7177V11.9636L4.75011 8.71358V16.0322C4.75011 16.0425 4.75011 16.0522 4.75012 16.0614C4.75811 16.0659 4.76658 16.0707 4.7756 16.0758L11.2501 19.7177ZM11.7457 2.34989C11.9135 2.31501 12.0867 2.31501 12.2545 2.34989C12.451 2.39075 12.6247 2.48982 12.73 2.54992C12.7407 2.55605 12.7507 2.56178 12.76 2.56699L20.3678 6.84637C20.604 6.97921 20.7501 7.2291 20.7501 7.50006V16.0322C20.7501 16.0432 20.7502 16.0552 20.7503 16.0681C20.7511 16.1937 20.7524 16.4008 20.6876 16.5979C20.6323 16.7661 20.542 16.9207 20.4225 17.0513C20.2824 17.2044 20.1013 17.3048 19.9914 17.3657C19.9801 17.3719 19.9696 17.3777 19.96 17.3831L12.3678 21.6537C12.1395 21.7822 11.8607 21.7822 11.6324 21.6537L4.04021 17.3831C4.03058 17.3777 4.02009 17.3719 4.00885 17.3657C3.89896 17.3048 3.71779 17.2044 3.57777 17.0513C3.45826 16.9207 3.36788 16.7661 3.3126 16.5979C3.24783 16.4008 3.24915 16.1937 3.24995 16.0681C3.25003 16.0552 3.25011 16.0432 3.25011 16.0322V7.50006C3.25011 7.2291 3.39626 6.97921 3.63242 6.84637L11.2402 2.56699C11.2495 2.56178 11.2595 2.55605 11.2703 2.54992C11.3756 2.48982 11.5492 2.39075 11.7457 2.34989Z"
            fill="currentColor"
        />
    </IconBase>
);
