import { ConversationStatus } from '../api/types';
import { CONVERSATIONS_LIST_KEY } from '../queries/conversations';
import { queryClient } from '../queries/queryClient';

type DataPayload = {
    inboxId: string;
};

export const CONVERSATION_CHANGE_EVENT = 'CONVERSATION_CHANGE';

export const handleConversationChangeEvent = async (data: DataPayload) => {
    // TODO: Dynamically update conversation list
    await Promise.all([
        queryClient.invalidateQueries({
            queryKey: [
                CONVERSATIONS_LIST_KEY,
                data.inboxId,
                ConversationStatus.Active,
            ],
        }),
        queryClient.invalidateQueries({
            queryKey: [
                CONVERSATIONS_LIST_KEY,
                data.inboxId,
                ConversationStatus.Archived,
            ],
        }),
    ]);
};
