import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ArrowLeftIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.44194 4.55806C8.68602 4.80214 8.68602 5.19786 8.44194 5.44194L4.50888 9.375H18C18.3452 9.375 18.625 9.65482 18.625 10C18.625 10.3452 18.3452 10.625 18 10.625H4.50888L8.44194 14.5581C8.68602 14.8021 8.68602 15.1979 8.44194 15.4419C8.19786 15.686 7.80214 15.686 7.55806 15.4419L2.55806 10.4419C2.31398 10.1979 2.31398 9.80214 2.55806 9.55806L7.55806 4.55806C7.80214 4.31398 8.19786 4.31398 8.44194 4.55806Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
