import cc from 'classcat';

//types
import { IconButtonProps } from '../../types/ElementsProps';

//styles
import styles from './Button.module.scss';

/** @deprecated use mui/material/IconButton */
function IconButton({
    icon,
    className,
    onClick,
    disabled,
    type,
    size,
    href,
    needBackground = true,
    enabled = false,
    ...rest
}: IconButtonProps) {
    const IconButtonClassName = cc([
        styles['clerk-btn-icon'],
        className,
        {
            [styles['clerk-btn-icon_disabled']]: disabled,
            [styles['clerk-btn-icon_size-large']]: size === 'large',
            [styles['clerk-btn-icon_default']]: type === 'default',
            [styles['clerk-btn-icon_no-background']]: !needBackground,
            [styles['clerk-btn-icon_enabled']]: enabled,
        },
    ]);

    switch (true) {
        case !!href:
            return (
                <a
                    href={disabled ? '#' : href}
                    className={IconButtonClassName}
                    role="button"
                    onClick={onClick}
                    {...rest}
                >
                    {icon}
                </a>
            );
        default:
            return (
                <button
                    className={IconButtonClassName}
                    onClick={(e) => onClick && onClick(e)}
                    disabled={disabled}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    {icon}
                </button>
            );
    }
}

export default IconButton;
