import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const IntegrationsIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.875C9.65482 1.875 9.375 2.15482 9.375 2.5V2.7808C8.18054 3.06296 7.29167 4.13601 7.29167 5.41667C7.29167 6.16208 7.59306 6.83681 8.07929 7.326L5.08431 12.1512C4.29362 11.6634 3.58074 11.0612 2.96859 10.3676C2.74017 10.1089 2.34521 10.0842 2.08642 10.3126C1.82762 10.5411 1.803 10.936 2.03141 11.1948C2.7237 11.9792 3.5303 12.6607 4.42514 13.2132L1.96897 17.1704C1.78694 17.4637 1.87712 17.849 2.1704 18.031C2.46367 18.2131 2.84899 18.1229 3.03102 17.8296L5.52768 13.8072C6.88702 14.4389 8.40241 14.7917 10 14.7917C11.5976 14.7917 13.113 14.4389 14.4723 13.8072L16.969 17.8296C17.151 18.1229 17.5363 18.2131 17.8296 18.031C18.1229 17.849 18.2131 17.4637 18.031 17.1704L15.5749 13.2132C16.4697 12.6607 17.2763 11.9792 17.9686 11.1948C18.197 10.936 18.1724 10.5411 17.9136 10.3126C17.6548 10.0842 17.2598 10.1089 17.0314 10.3676C16.4193 11.0612 15.7064 11.6634 14.9157 12.1512L11.9207 7.326C12.4069 6.83681 12.7083 6.16208 12.7083 5.41667C12.7083 4.13601 11.8195 3.06296 10.625 2.7808V2.5C10.625 2.15482 10.3452 1.875 10 1.875ZM10.859 7.98576L13.8076 12.7363C12.6441 13.2541 11.3557 13.5417 10 13.5417C8.64426 13.5417 7.35588 13.2541 6.19236 12.7363L9.141 7.98576C9.41123 8.07609 9.70021 8.125 10 8.125C10.2998 8.125 10.5888 8.07609 10.859 7.98576ZM10 3.95833C10.8054 3.95833 11.4583 4.61125 11.4583 5.41667C11.4583 5.93907 11.1841 6.39792 10.7687 6.65628C10.5459 6.79485 10.2833 6.875 10 6.875C9.71672 6.875 9.45406 6.79485 9.23128 6.65628C8.81592 6.39792 8.54167 5.93907 8.54167 5.41667C8.54167 4.61125 9.19458 3.95833 10 3.95833Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
