import IconBase, { IconBaseProps } from '../IconBase';

export const OtherCarrierIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6893 5.75C10.6767 5.75 8.93059 7.03318 8.19134 8.79078C8.0911 9.0291 7.87518 9.19899 7.61996 9.24034C5.2839 9.61887 3.75 11.5672 3.75 13.6493C3.75 16.1837 5.82538 18.25 8.4 18.25L16.5 18.25C18.5706 18.25 20.25 16.5702 20.25 14.4969C20.25 12.9365 19.3051 11.508 17.9995 10.9372C17.7407 10.824 17.5674 10.575 17.5512 10.293C17.406 7.76563 15.2898 5.75 12.6893 5.75ZM6.97916 7.83765C8.01516 5.7576 10.1736 4.25 12.6893 4.25C15.9265 4.25 18.6073 6.64908 19.0077 9.76413C20.6561 10.6771 21.75 12.5225 21.75 14.4969C21.75 17.3976 19.4 19.75 16.5 19.75L8.4 19.75C5.00995 19.75 2.25 17.0251 2.25 13.6493C2.25 10.9576 4.13725 8.4847 6.97916 7.83765ZM15.5402 10.4798C15.8276 10.7781 15.8186 11.2529 15.5202 11.5402L11.3664 15.5402C11.0759 15.8199 10.6164 15.8199 10.3259 15.5402L8.47977 13.7625C8.1814 13.4751 8.17244 13.0004 8.45976 12.702C8.74708 12.4036 9.22186 12.3947 9.52023 12.682L10.8462 13.9588L14.4798 10.4598C14.7781 10.1724 15.2529 10.1814 15.5402 10.4798Z"
            fill="currentColor"
        />
    </IconBase>
);
