import AdvancedSelect from '../../elements/AdvancedSelect';
import Button from '../../elements/Buttons';
import CheckBox from '../../elements/CheckBox/CheckBox';
import Icon from '../../icons/Icon';
import TimeInput from '../TimeInput';
import { ScheduleDay, WorkflowSchedule } from '../workflows/types';
import { useWorkflowContext } from '../workflows/WorkflowProvider';
import { WorkflowReducerActionTypes } from '../workflows/WorkflowProvider/reducer';
import { DAYS_OF_WEEK } from '../workflows/WorkflowStepConfigurator/ScheduleStep/utils';
import styles from './WorkflowScheduleRow.module.scss';

type WorkflowScheduleRowProps = {
    schedule: WorkflowSchedule;
    updateSchedule: (schedule: WorkflowSchedule) => void;
};

export const WorkflowScheduleRow = (props: WorkflowScheduleRowProps) => {
    const { dispatch } = useWorkflowContext();
    const deleteSchedule = (schedule: WorkflowSchedule) => {
        dispatch({
            type: WorkflowReducerActionTypes.DELETE_WORKFLOW_TRIGGER_SETTINGS_SCHEDULE,
            payload: schedule,
        });
    };

    return (
        <div className={styles.container}>
            <CheckBox
                checked={props.schedule.enabled}
                onChange={() =>
                    props.updateSchedule({
                        ...props.schedule,
                        enabled: !props.schedule.enabled,
                    })
                }
            />
            <TimeInput
                schedule={props.schedule}
                onChange={props.updateSchedule}
            />
            <AdvancedSelect
                options={DAYS_OF_WEEK}
                value={props.schedule.dayOfWeek.toString()}
                onChange={(id) =>
                    props.updateSchedule({
                        ...props.schedule,
                        dayOfWeek: id as ScheduleDay,
                    })
                }
            />
            <Button
                type="icon"
                icon={<Icon color="f7d4d6" name="delete" />}
                onClick={() => deleteSchedule(props.schedule)}
            />
        </div>
    );
};
