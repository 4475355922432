import IconBase, { IconBaseProps } from '../IconBase';

export const MicrosoftOnedriveLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M9.15184 8.3321L9.15207 8.33122L14.1903 11.5699L17.1925 10.2141C17.8026 9.93102 18.4604 9.78589 19.125 9.78658C19.2357 9.78658 19.3452 9.79198 19.4541 9.79978C19.0931 8.28935 18.3312 6.92396 17.2608 5.86951C16.1904 4.81506 14.8576 4.11695 13.4245 3.86002C11.9914 3.6031 10.5196 3.79841 9.18794 4.42223C7.8563 5.04605 6.72216 6.07152 5.92334 7.37403C5.949 7.37368 5.97428 7.37195 6 7.37195C7.11349 7.37033 8.20521 7.70291 9.15184 8.3321Z"
            fill="#0364B8"
        />
        <path
            d="M9.15207 8.33122L9.15184 8.33211C8.20521 7.70291 7.11349 7.37034 6 7.37195C5.97427 7.37195 5.94896 7.37368 5.92333 7.37403C4.8335 7.38851 3.76796 7.72142 2.84119 8.33698C1.91443 8.95254 1.16149 9.82749 0.663278 10.8678C0.165069 11.9081 -0.0595727 13.0744 0.0134948 14.2415C0.0865624 15.4085 0.454576 16.5321 1.07799 17.4915L5.52099 15.485L7.49605 14.593L11.8937 12.607L14.1903 11.5699L9.15207 8.33122Z"
            fill="#0078D4"
        />
        <path
            d="M19.4541 9.79982C19.3452 9.79202 19.2357 9.78662 19.125 9.78662C18.4604 9.78593 17.8027 9.9316 17.1926 10.2146L14.1903 11.5699L15.0609 12.1295L17.9146 13.9639L19.1596 14.7643L23.4169 17.5009C23.8037 16.7303 24.0042 15.8678 23.9999 14.9924C23.9957 14.1171 23.7868 13.2569 23.3925 12.4906C22.9982 11.7243 22.431 11.0764 21.743 10.6063C21.0549 10.1362 20.2679 9.85893 19.4541 9.79982Z"
            fill="#1490DF"
        />
        <path
            d="M19.1597 14.7642L17.9146 13.9638L15.0609 12.1294L14.1903 11.5698L11.8937 12.607L7.49607 14.593L5.521 15.4849L1.078 17.4914C1.63013 18.3433 2.36616 19.0392 3.22327 19.5195C4.08037 19.9999 5.03307 20.2505 6.00001 20.2499H19.125C20.005 20.2502 20.8687 19.9948 21.6243 19.5108C22.38 19.0268 22.9994 18.3322 23.4169 17.5008L19.1597 14.7642Z"
            fill="#28A8EA"
        />
    </IconBase>
);
