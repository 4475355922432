import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import {
    createProfanity,
    deleteWordFromProfanity,
    getProfanity,
    updateProfanity,
} from '../api/profanity';
import { ProfanityRecord } from '../api/types';

const PROFANITY_KEY = 'profanity_list';

export function useProfanityQuery() {
    return useQuery({
        queryKey: [PROFANITY_KEY],
        queryFn: getProfanity,
    });
}

export function useCreateProfanityQuery() {
    return useMutation<ProfanityRecord, AxiosError, string[]>({
        mutationKey: ['profanity_create'],
        mutationFn: createProfanity,
        onMutate: async (blackList) => {
            await queryClient.cancelQueries({ queryKey: [PROFANITY_KEY] });

            queryClient.setQueryData<ProfanityRecord>([PROFANITY_KEY], () => ({
                blackList,
            }));
        },
        onError: () => {
            queryClient.setQueryData<ProfanityRecord>([PROFANITY_KEY], () => ({
                blackList: [],
            }));
        },
    });
}

export function useUpdateProfanityQuery() {
    return useMutation<ProfanityRecord, AxiosError, string[], ProfanityRecord>({
        mutationKey: ['profanity_update'],
        mutationFn: updateProfanity,
        onMutate: async (blackList) => {
            await queryClient.cancelQueries({ queryKey: [PROFANITY_KEY] });

            const previousProfanity = queryClient.getQueryData<ProfanityRecord>(
                [PROFANITY_KEY],
            );

            queryClient.setQueryData<ProfanityRecord>([PROFANITY_KEY], () => ({
                blackList,
            }));

            return previousProfanity;
        },
        onError: (_error, _blacklist, previousProfanity) => {
            if (previousProfanity) {
                queryClient.setQueryData<ProfanityRecord>(
                    [PROFANITY_KEY],
                    previousProfanity,
                );
            }
        },
    });
}

export function useDeleteWordFromProfanityQuery() {
    return useMutation<ProfanityRecord, AxiosError, string, ProfanityRecord>({
        mutationKey: ['cprofanity_delete_word'],
        mutationFn: deleteWordFromProfanity,
        onMutate: async (wordToDelete) => {
            await queryClient.cancelQueries({ queryKey: [PROFANITY_KEY] });

            const previousProfanity = queryClient.getQueryData<ProfanityRecord>(
                [PROFANITY_KEY],
            );

            queryClient.setQueryData<ProfanityRecord>([PROFANITY_KEY], () => ({
                blackList: previousProfanity
                    ? previousProfanity.blackList.filter(
                          (word) => word !== wordToDelete,
                      )
                    : [],
            }));

            return previousProfanity;
        },
        onError: (_error, _blacklist, previousProfanity) => {
            if (previousProfanity) {
                queryClient.setQueryData<ProfanityRecord>(
                    [PROFANITY_KEY],
                    previousProfanity,
                );
            }
        },
    });
}
