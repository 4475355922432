import styles from './EmojiTrio.module.scss';

type EmojiTrioProps = {
    emoji: '🔒';
};

export const EmojiTrio = (props: EmojiTrioProps) => {
    return (
        <div className={styles['root']}>
            <div className={styles['bar']} />
            <span className={styles['emoji']}>{props.emoji}</span>
            <span className={styles['emoji']}>{props.emoji}</span>
            <span className={styles['emoji']}>{props.emoji}</span>
            <div className={styles['bar']} />
        </div>
    );
};
