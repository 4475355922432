import { Stack, Typography } from '@mui/material';
import { Integration } from '../../../../api/types';
import { CloseIcon } from '../../../../icons/shared/CloseIcon';
import { CheckmarkIcon } from '../../../../icons/shared/CheckmarkIcon';
import * as styles from './styles';
import dayjs from 'dayjs';

type Props = {
    status?: Integration['lastSyncStatus'];
    time?: Integration['lastSyncedAt'];
};

export function SyncStatus({ status, time }: Props) {
    const syncTime = (
        <Typography variant="body4" color="custom.gray.super">
            {`${dayjs(time).fromNow(true)} ago`}
        </Typography>
    );

    if (status && ['disconnected', 'fail'].includes(status)) {
        return (
            <Stack spacing={2} direction="row" alignItems="center">
                <Typography
                    variant="body4"
                    color="error.main"
                    sx={styles.statusText}
                >
                    <CloseIcon sx={styles.statusIcon} />
                    {status === 'fail' ? 'Failed' : 'Disconnected'}
                </Typography>
                {syncTime}
            </Stack>
        );
    }

    if (status === 'syncing') {
        return (
            <Stack spacing={2} direction="row">
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    sx={styles.statusText}
                >
                    Syncing
                </Typography>
            </Stack>
        );
    }

    if (status === 'success') {
        return (
            <Stack spacing={2} direction="row">
                <Typography
                    variant="body4"
                    color="success.main"
                    sx={styles.statusText}
                >
                    <CheckmarkIcon sx={styles.statusIcon} />
                    Synced
                </Typography>
                {syncTime}
            </Stack>
        );
    }

    return null;
}
