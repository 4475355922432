import IconBase, { IconBaseProps } from '../IconBase';

export const CustomCrownIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.25 4C10.25 3.0335 11.0335 2.25 12 2.25C12.9665 2.25 13.75 3.0335 13.75 4C13.75 4.59369 13.4544 5.11832 13.0023 5.43473L14.0328 7.83932C14.3198 8.50885 14.516 8.96501 14.6952 9.29791C14.8719 9.62634 14.9909 9.75333 15.0831 9.81828C15.3367 9.99698 15.6478 10.0749 15.9558 10.037C16.0677 10.0232 16.2325 9.96738 16.5433 9.76112C16.8582 9.55206 17.2464 9.24239 17.8152 8.78734L18.6529 8.11718C18.4013 7.8141 18.25 7.42472 18.25 7C18.25 6.0335 19.0335 5.25 20 5.25C20.9665 5.25 21.75 6.0335 21.75 7C21.75 7.7343 21.2977 8.36298 20.6566 8.62266L18.7398 20.1233C18.6795 20.4849 18.3666 20.75 18 20.75H6C5.63337 20.75 5.32048 20.4849 5.2602 20.1233L3.34343 8.62266C2.70226 8.36298 2.25 7.7343 2.25 7C2.25 6.0335 3.0335 5.25 4 5.25C4.9665 5.25 5.75 6.0335 5.75 7C5.75 7.42472 5.5987 7.8141 5.34706 8.11718L6.18476 8.78734C6.75357 9.24239 7.14178 9.55206 7.45674 9.76112C7.76748 9.96738 7.93231 10.0232 8.04424 10.037C8.3522 10.0749 8.66326 9.99698 8.91692 9.81828C9.00912 9.75333 9.12813 9.62634 9.30485 9.29791C9.48397 8.96501 9.68023 8.50885 9.96717 7.83932L10.9977 5.43473C10.5456 5.11832 10.25 4.59369 10.25 4ZM12 6.90394L11.3322 8.46222C11.0624 9.09176 10.8403 9.60994 10.6258 10.0087C10.4052 10.4186 10.1518 10.7832 9.78078 11.0446C9.22272 11.4377 8.53839 11.6092 7.86088 11.5258C7.4104 11.4703 7.01504 11.2683 6.6272 11.0109C6.24996 10.7605 5.80974 10.4083 5.27491 9.9804L5.06203 9.81009L6.63535 19.25H17.3647L18.938 9.81009L18.7251 9.9804C18.1903 10.4083 17.75 10.7605 17.3728 11.0109C16.985 11.2683 16.5896 11.4703 16.1391 11.5258C15.4616 11.6092 14.7773 11.4377 14.2192 11.0446C13.8482 10.7832 13.5948 10.4186 13.3742 10.0087C13.1597 9.60994 12.9376 9.09174 12.6678 8.46219L12 6.90394Z"
            fill="currentColor"
        />
    </IconBase>
);
