import IconBase, { IconBaseProps } from '../IconBase';

export const GbIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M22.2026 4.13794H1.79742C0.804703 4.13794 0 4.94264 0 5.93536V18.0647C0 19.0574 0.804703 19.8621 1.79742 19.8621H22.2026C23.1952 19.8621 24 19.0574 24 18.0647V5.93536C24 4.94264 23.1953 4.13794 22.2026 4.13794Z"
            fill="#41479B"
        />
        <path
            d="M23.9751 5.63821C23.8335 4.787 23.094 4.13788 22.2026 4.13788H21.7349L14.069 9.16039V4.13793H9.93103V9.16044L2.26509 4.13793H1.79742C0.906 4.13793 0.166547 4.787 0.0248906 5.63825L6.57698 9.93107H0V14.069H6.57698L0.0248906 18.3617C0.166547 19.2129 0.906 19.8621 1.79742 19.8621H2.26509L9.93103 14.8395V19.8621H14.069V14.8395L21.7349 19.8621H22.2026C23.094 19.8621 23.8335 19.213 23.9751 18.3617L17.423 14.0689H24V9.93097H17.423L23.9751 5.63821Z"
            fill="#F5F5F5"
        />
        <path
            d="M13.2414 4.13794H10.7586V10.7586H0V13.2414H10.7586V19.8621H13.2414V13.2414H24V10.7586H13.2414V4.13794Z"
            fill="#FF4B55"
        />
        <path
            d="M1.16217 19.7462L9.90825 14.069H8.38819L0.432373 19.2333C0.627045 19.4605 0.877217 19.6385 1.16217 19.7462Z"
            fill="#FF4B55"
        />
        <path
            d="M16.237 14.069H14.7169L23.188 19.5676C23.425 19.412 23.6228 19.2017 23.7639 18.9548L16.237 14.069Z"
            fill="#FF4B55"
        />
        <path
            d="M0.189819 5.13166L7.58341 9.93105H9.10348L0.725413 4.49261C0.50046 4.66005 0.316382 4.87896 0.189819 5.13166Z"
            fill="#FF4B55"
        />
        <path
            d="M15.589 9.93106L23.5594 4.75723C23.3626 4.53078 23.1105 4.35378 22.8235 4.24817L14.069 9.93106H15.589Z"
            fill="#FF4B55"
        />
    </IconBase>
);
