import { SxProps } from '@mui/material';

export const base: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: 1,
};
export const icon: SxProps = { width: 16, height: 16, color: '#fff' };
