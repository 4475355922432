import IconBase, { IconBaseProps } from '../IconBase';

export const MicrosoftDynamicsLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M5.25 23.25L9.51856 10.6697L5.25 7.79531V23.25ZM18.75 15.4692V8.32688L5.25 23.25L18.75 15.4692ZM5.25 0.75V6.77859L14.0596 11.2153L18.1543 7.79531L5.25 0.75Z"
            fill="#0364B8"
        />
    </IconBase>
);
