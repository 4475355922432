import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const BlacklistIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.97464 1.875H14.0254C14.4705 1.87499 14.842 1.87498 15.1456 1.89978C15.4621 1.92565 15.7593 1.98157 16.0404 2.12478C16.4716 2.34449 16.8222 2.69507 17.0419 3.12627C17.1851 3.40733 17.241 3.70454 17.2669 4.02111C17.2917 4.32463 17.2917 4.69617 17.2917 5.14129V14.1667C17.2917 14.5118 17.0118 14.7917 16.6667 14.7917H16.4583V16.875H16.6667C17.0118 16.875 17.2917 17.1548 17.2917 17.5C17.2917 17.8452 17.0118 18.125 16.6667 18.125H5C3.73435 18.125 2.70833 17.099 2.70833 15.8333L2.70833 5.1413C2.70832 4.69618 2.70832 4.32464 2.73312 4.02111C2.75898 3.70454 2.81491 3.40732 2.95811 3.12627C3.17782 2.69507 3.5284 2.34449 3.9596 2.12478C4.24066 1.98157 4.53787 1.92565 4.85444 1.89978C5.15797 1.87498 5.52951 1.87499 5.97464 1.875ZM3.95833 15.8333C3.95833 16.4086 4.4247 16.875 5 16.875H15.2083V14.7917H5C4.4247 14.7917 3.95833 15.258 3.95833 15.8333ZM5 13.5417C4.62491 13.5417 4.27086 13.6318 3.95833 13.7915V5.16667C3.95833 4.68964 3.95882 4.36946 3.97896 4.1229C3.99851 3.88363 4.03341 3.76923 4.07187 3.69376C4.17174 3.49776 4.33109 3.3384 4.52709 3.23854C4.60256 3.20008 4.71696 3.16518 4.95623 3.14563C5.2028 3.12549 5.52298 3.125 6 3.125H14C14.477 3.125 14.7972 3.12549 15.0438 3.14563C15.283 3.16518 15.3974 3.20008 15.4729 3.23854C15.6689 3.3384 15.8283 3.49776 15.9281 3.69376C15.9666 3.76923 16.0015 3.88363 16.021 4.1229C16.0412 4.36946 16.0417 4.68964 16.0417 5.16667V13.5417H5ZM6.875 5.83333C6.875 5.48816 7.15482 5.20833 7.5 5.20833H12.5C12.8452 5.20833 13.125 5.48816 13.125 5.83333C13.125 6.17851 12.8452 6.45833 12.5 6.45833H7.5C7.15482 6.45833 6.875 6.17851 6.875 5.83333ZM6.875 9.16667C6.875 8.82149 7.15482 8.54167 7.5 8.54167H12.5C12.8452 8.54167 13.125 8.82149 13.125 9.16667C13.125 9.51185 12.8452 9.79167 12.5 9.79167H7.5C7.15482 9.79167 6.875 9.51185 6.875 9.16667Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
