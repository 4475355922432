import { Typography } from '@mui/material';
import { KeywordsList } from './Keywords/KeywordsList';
import { KeywordForm } from './Keywords/KeywordForm';

export const KeywordsRow = () => (
    <>
        <Typography
            variant="body3"
            component="label"
            htmlFor="ai-assistant-keywords-select"
            id="ai-assistant-keywords-label"
            color="custom.gray.super"
            fontWeight={500}
        >
            Stop words
        </Typography>
        <Typography
            variant="body4"
            component="div"
            color="custom.gray.super"
            mt={1}
        >
            Keywords or phrases that prevent the assistant from replying
        </Typography>
        <KeywordForm sx={{ mt: 4 }} />
        <KeywordsList sx={{ mt: 10 }} />
    </>
);
