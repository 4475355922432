import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const CountryIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.02 2.15332C18.2115 2.44052 18.1339 2.82857 17.8467 3.02004L17.0882 3.52571C17.7485 4.701 18.125 6.05724 18.125 7.50001C18.125 11.777 14.8203 15.2822 10.625 15.6013V16.875H14.1667C14.5118 16.875 14.7917 17.1548 14.7917 17.5C14.7917 17.8452 14.5118 18.125 14.1667 18.125H5.83334C5.48816 18.125 5.20834 17.8452 5.20834 17.5C5.20834 17.1548 5.48816 16.875 5.83334 16.875H9.37501V15.6013C7.03498 15.4233 4.97231 14.2537 3.60606 12.5138L2.84669 13.02C2.55949 13.2115 2.17144 13.1339 1.97997 12.8467C1.7885 12.5595 1.86611 12.1714 2.15332 11.98L3.40332 11.1466C3.51182 11.0743 3.63471 11.0404 3.75596 11.0416C3.95973 11.0395 4.16052 11.137 4.28234 11.319C5.51671 13.1631 7.61695 14.375 10 14.375C13.797 14.375 16.875 11.297 16.875 7.50001C16.875 6.09343 16.4533 4.78725 15.7295 3.6987C15.6563 3.58868 15.6226 3.46393 15.625 3.34112C15.6226 3.13667 15.7205 2.93517 15.9033 2.81331L17.1533 1.97997C17.4405 1.7885 17.8286 1.86611 18.02 2.15332ZM10 3.95834C8.044 3.95834 6.45834 5.544 6.45834 7.50001C6.45834 9.45601 8.044 11.0417 10 11.0417C11.956 11.0417 13.5417 9.45601 13.5417 7.50001C13.5417 5.544 11.956 3.95834 10 3.95834ZM5.20834 7.50001C5.20834 4.85364 7.35364 2.70834 10 2.70834C12.6464 2.70834 14.7917 4.85364 14.7917 7.50001C14.7917 10.1464 12.6464 12.2917 10 12.2917C7.35364 12.2917 5.20834 10.1464 5.20834 7.50001Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
