import IconBase, { IconBaseProps } from '../IconBase';

export const LinkedIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M8.06258 18.8496H6.03375V9.58008H8.06258V18.8496ZM8.69037 6.66833C8.69037 5.74019 7.93735 4.98685 7.00969 4.98685C6.07851 4.98685 5.32741 5.74019 5.32741 6.66833C5.32741 7.59679 6.07851 8.35013 7.00969 8.35013C7.93735 8.35013 8.69037 7.59679 8.69037 6.66833ZM18.4351 13.7499C18.4351 11.2616 17.2386 9.41602 15.3759 9.41602C13.9791 9.41602 13.0415 10.1146 12.6587 10.841H12.656V9.58008H10.4584V18.8496H12.656V14.2472C12.656 13.0419 12.9628 11.8742 14.4568 11.8742C15.9305 11.8742 15.9783 13.2526 15.9783 14.3238V18.8496H18.4351V13.7499ZM22.4998 20.0391V3.96094C22.4998 2.6039 21.3959 1.5 20.0388 1.5H3.96069C2.60365 1.5 1.49976 2.6039 1.49976 3.96094V20.0391C1.49976 21.3961 2.60365 22.5 3.96069 22.5H20.0388C21.3959 22.5 22.4998 21.3961 22.4998 20.0391ZM20.0388 3.14062C20.4911 3.14062 20.8591 3.50864 20.8591 3.96094V20.0391C20.8591 20.4914 20.4911 20.8594 20.0388 20.8594H3.96069C3.5084 20.8594 3.14038 20.4914 3.14038 20.0391V3.96094C3.14038 3.50864 3.5084 3.14062 3.96069 3.14062H20.0388Z"
            fill="currentColor"
        />
    </IconBase>
);
