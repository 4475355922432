import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const VerticalThreeDotsIcon = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                d="M4.375 7.91663C3.34375 7.91663 2.5 8.76038 2.5 9.79163C2.5 10.8229 3.34375 11.6666 4.375 11.6666C5.40625 11.6666 6.25 10.8229 6.25 9.79163C6.25 8.76038 5.40625 7.91663 4.375 7.91663ZM15.625 7.91663C14.5938 7.91663 13.75 8.76038 13.75 9.79163C13.75 10.8229 14.5938 11.6666 15.625 11.6666C16.6562 11.6666 17.5 10.8229 17.5 9.79163C17.5 8.76038 16.6562 7.91663 15.625 7.91663ZM10 7.91663C8.96875 7.91663 8.125 8.76038 8.125 9.79163C8.125 10.8229 8.96875 11.6666 10 11.6666C11.0312 11.6666 11.875 10.8229 11.875 9.79163C11.875 8.76038 11.0312 7.91663 10 7.91663Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
