import IconBase, { IconBaseProps } from '../IconBase';

export const CustomUploadIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6893 5.75C10.6767 5.75 8.93059 7.03318 8.19134 8.79078C8.0911 9.0291 7.87518 9.19899 7.61996 9.24034C5.2839 9.61887 3.75 11.5672 3.75 13.6493C3.75 16.1837 5.82538 18.25 8.4 18.25L16.5 18.25C18.5706 18.25 20.25 16.5702 20.25 14.4969C20.25 12.9365 19.3051 11.508 17.9995 10.9372C17.7407 10.824 17.5674 10.575 17.5512 10.293C17.406 7.76563 15.2898 5.75 12.6893 5.75ZM6.97916 7.83765C8.01516 5.7576 10.1736 4.25 12.6893 4.25C15.9265 4.25 18.6073 6.64908 19.0077 9.76413C20.6561 10.6771 21.75 12.5225 21.75 14.4969C21.75 17.3976 19.4 19.75 16.5 19.75L8.4 19.75C5.00995 19.75 2.25 17.0251 2.25 13.6493C2.25 10.9576 4.13725 8.4847 6.97916 7.83765ZM11.4697 8.96967C11.7626 8.67678 12.2374 8.67678 12.5303 8.96967L14.5303 10.9697C14.8232 11.2626 14.8232 11.7374 14.5303 12.0303C14.2374 12.3232 13.7626 12.3232 13.4697 12.0303L12.75 11.3107V15.5C12.75 15.9142 12.4142 16.25 12 16.25C11.5858 16.25 11.25 15.9142 11.25 15.5V11.3107L10.5303 12.0303C10.2374 12.3232 9.76256 12.3232 9.46967 12.0303C9.17678 11.7374 9.17678 11.2626 9.46967 10.9697L11.4697 8.96967Z"
            fill="currentColor"
        />
    </IconBase>
);
