import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiAlertTitle: Components<Theme>['MuiAlertTitle'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.alertTitle,
            margin: theme.spacing(0, 0, 1),
        }),
    },
};
