import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const InviteIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="24"
                cy="24"
                r="24"
                fill="url(#paint0_linear_15788_53633)"
            />
            <circle
                cx="24"
                cy="24"
                r="23.5"
                stroke="#788DF8"
                strokeOpacity="0.32"
            />
            <path
                d="M25.6715 25.1792C25.1739 25.5479 24.5959 25.7429 24 25.7429C23.4042 25.7429 22.8262 25.5479 22.3286 25.1792L14.1332 20.105C14.0877 20.0713 14.0434 20.0362 14 20V29.9532C14 31.0943 14.833 32 15.8411 32H32.1589C33.1854 32 34 31.0739 34 29.9532V20C33.9565 20.0362 33.9121 20.0714 33.8666 20.1052L25.6715 25.1792Z"
                fill="#3F5DF4"
            />
            <path
                d="M14.7832 19.2758L22.9786 24.1719C23.2889 24.377 23.6444 24.4795 24 24.4795C24.3555 24.4795 24.7111 24.377 25.0214 24.1719L33.2168 19.2758C33.7072 18.9519 34 18.4097 34 17.8246C34 16.8185 33.1743 16 32.1595 16H15.8405C14.8257 16 14 16.8185 14 17.8256C14 18.4097 14.2928 18.9519 14.7832 19.2758Z"
                fill="#3F5DF4"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_15788_53633"
                    x1="24"
                    y1="0"
                    x2="24"
                    y2="48"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#F7F8FE" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
);
