import { queryClient } from '../queries/queryClient';
import { DISCUSSION_KEY } from '../queries/comments';
import { Discussion, Message, MessageComment } from '../api/types';
import { CONVERSATION_MESSAGES_KEY } from '../queries/messages';
import { PagedData } from '../types/PagedData';
import { findPageIndexInPagedData } from '../queries/utils';

type DataPayload = {
    comment: MessageComment;
};

export const NEW_DISCUSSION_COMMENT_MESSAGE = 'NEW_DISCUSSION_COMMENT_MESSAGE';

export const handleNewDiscussionCommentEvent = ({ comment }: DataPayload) => {
    const discussion = queryClient.getQueryData<Discussion>([
        DISCUSSION_KEY,
        comment.discussionId,
    ]);

    if (discussion) {
        const newDiscussion: Discussion = {
            ...discussion,
            replies: discussion.replies + 1,
            comments: discussion.comments.concat(comment),
        };

        queryClient.setQueryData<Discussion>(
            [DISCUSSION_KEY, comment.discussionId],
            newDiscussion,
        );

        queryClient.setQueryData<PagedData<Message>>(
            [CONVERSATION_MESSAGES_KEY, discussion.message.conversationId],
            (pagedMessages = { pages: [], pageParams: [] }) => {
                const [pageIndex, itemIndex] = findPageIndexInPagedData(
                    pagedMessages,
                    (message) => message.id === discussion.messageId,
                );

                if (pagedMessages.pages[pageIndex][itemIndex].discussion) {
                    pagedMessages.pages[pageIndex][
                        itemIndex
                    ].discussion!.replies = newDiscussion.replies;
                }
                return pagedMessages;
            },
        );
    }
};
