import cc from 'classcat';
//components
import Input from './Input';

//type
import { SearchProps } from '../../types/ElementsProps';

//assets
import { FiSearch } from 'react-icons/fi';

//styles
import styles from './Input.module.scss';

/**
 * @deprecated use SearchTextField component instead
 */
function Search({ onSearch, className, ...rest }: SearchProps) {
    const handleSearch = (value: string) => {
        onSearch && onSearch(value);
    };

    return (
        <Input
            prefix={<FiSearch className={styles['clerk-search__icon']} />}
            onChange={handleSearch}
            placeholder="Search"
            className={cc([styles.contactsSearch, className])}
            {...rest}
        />
    );
}

export default Search;
