import IconBase, { IconBaseProps } from '../IconBase';

export const GlobalRelayLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <rect width="24" height="24" rx="6" fill="#D72634" />
        <g clipPath="url(#clip0_5788_3234)">
            <path
                d="M17.1374 3.83585C16.8043 3.37613 15.5314 3.16299 13.7865 3.32598C13.7781 3.32598 13.77 3.3295 13.7641 3.33577C13.7581 3.34204 13.7548 3.35055 13.7548 3.35941C13.7548 3.36939 13.7585 3.37896 13.7652 3.38601C13.7719 3.39307 13.781 3.39703 13.7904 3.39703C16.2571 3.43464 16.261 3.99048 16.2769 3.97794C16.3522 4.20362 15.1824 4.81379 12.0416 4.87648C9.14273 4.92663 7.60803 4.31228 7.51682 3.80659C7.42561 3.30091 7.96494 3.15463 8.30995 3.05015C8.31637 3.04378 8.32033 3.03514 8.32106 3.02588C8.32179 3.01662 8.31925 3.0074 8.31391 3C7.81424 3.03761 7.12422 3.35106 7.20354 3.92779C7.36216 4.90573 9.34498 5.59949 12.4302 5.59949C14.2029 5.59949 15.6464 5.29023 16.7448 4.65498C17.3238 4.32064 17.2287 3.92779 17.1414 3.81913"
                fill="white"
            />
            <path
                d="M16.9036 19.2614C16.5784 18.8435 15.3173 18.622 13.6359 18.7766C13.6314 18.7766 13.6269 18.7776 13.6228 18.7795C13.6186 18.7814 13.6149 18.7843 13.6119 18.7878C13.6089 18.7914 13.6067 18.7956 13.6053 18.8002C13.604 18.8047 13.6036 18.8095 13.6042 18.8142C13.6051 18.8235 13.6093 18.832 13.6159 18.8382C13.6225 18.8444 13.631 18.8477 13.6399 18.8477C16.047 18.8811 16.051 19.4286 16.0668 19.4161C16.1382 19.6334 14.9961 20.2519 11.9307 20.2937C9.10318 20.3397 7.70331 19.717 7.4852 19.299C7.43009 19.1544 7.42078 18.995 7.45866 18.8443C7.49653 18.6937 7.57956 18.56 7.69538 18.4632C7.69538 18.4565 7.69287 18.4502 7.68841 18.4455C7.68395 18.4408 7.6779 18.4381 7.67159 18.4381C7.50004 18.5158 7.3591 18.6533 7.27244 18.8276C7.18578 19.0019 7.15869 19.2023 7.19571 19.3952C7.43761 20.3271 9.28956 21.0669 12.2995 21.0292C14.0285 21.0292 15.4363 20.7242 16.511 20.1056C17.0741 19.7755 16.9829 19.3952 16.9075 19.2698"
                fill="white"
            />
            <path
                d="M20.072 7.2378C19.5367 6.61509 17.4864 6.34344 14.6669 6.56494C14.6351 6.56494 14.6113 6.5942 14.6153 6.61927C14.6158 6.62641 14.6176 6.63338 14.6207 6.63977C14.6238 6.64616 14.628 6.65185 14.6332 6.65651C14.6383 6.66118 14.6443 6.66472 14.6508 6.66693C14.6572 6.66915 14.6641 6.66999 14.6708 6.66942C18.6365 6.69868 18.6563 7.4802 18.6801 7.46348C18.803 7.77274 16.9035 8.65456 11.8354 8.75069C7.15595 8.85099 4.84399 7.96917 4.48708 7.3381C4.40234 7.12901 4.39134 6.89487 4.45606 6.67797C4.52079 6.46107 4.65697 6.27572 4.84002 6.15538C4.84612 6.15321 4.85156 6.14935 4.85575 6.1442C4.85993 6.13905 4.86272 6.1328 4.86382 6.12612C4.86194 6.11543 4.85653 6.10578 4.84855 6.09889C4.84056 6.09201 4.83051 6.08833 4.8202 6.08851C4.55128 6.17576 4.32204 6.36388 4.17588 6.61727C4.02972 6.87066 3.97677 7.17172 4.02707 7.46348C4.42363 8.79666 7.47717 9.81221 12.458 9.76206C15.3212 9.72863 17.653 9.30235 19.4375 8.40381C20.3734 7.93155 20.2306 7.38407 20.0918 7.2378"
                fill="white"
            />
            <path
                d="M20.2505 15.2076C19.6993 14.5682 17.5856 14.2756 14.6986 14.518C14.6848 14.5189 14.6717 14.5247 14.6616 14.5347C14.6515 14.5446 14.6449 14.5579 14.6431 14.5723C14.6451 14.5871 14.6523 14.6005 14.6634 14.6099C14.6745 14.6192 14.6885 14.6237 14.7026 14.6225C18.8031 14.6517 18.807 15.4583 18.8308 15.4374C18.9577 15.7551 17.0027 16.6661 11.7839 16.7664C6.96565 16.8584 4.58627 15.9557 4.18971 15.3121C4.10601 15.093 4.10002 14.8494 4.17286 14.6261C4.24569 14.4027 4.39246 14.2145 4.58627 14.0959C4.58838 14.0913 4.58947 14.0863 4.58947 14.0813C4.58947 14.0762 4.58838 14.0712 4.58627 14.0667C4.58578 14.0612 4.58425 14.0558 4.58179 14.0509C4.57932 14.0461 4.57596 14.0418 4.57191 14.0383C4.56785 14.0348 4.56317 14.0321 4.55815 14.0306C4.55313 14.029 4.54786 14.0285 4.54265 14.029C4.26477 14.1174 4.02713 14.3098 3.87448 14.57C3.72182 14.8301 3.66469 15.1401 3.71383 15.4416C4.1104 16.8124 7.2829 17.8614 12.3906 17.8071C15.3371 17.7736 17.7403 17.3306 19.5724 16.4112C20.536 15.9222 20.3655 15.358 20.2505 15.2076Z"
                fill="white"
            />
            <path
                d="M20.8969 11.0408C20.2981 10.3555 18.0179 10.042 14.8969 10.3011C14.8818 10.3011 14.8672 10.3071 14.8562 10.318C14.8451 10.3288 14.8384 10.3437 14.8374 10.3596C14.8394 10.3755 14.8471 10.39 14.8589 10.4001C14.8707 10.4102 14.8857 10.4152 14.9009 10.414C19.3305 10.4474 19.3345 11.3125 19.3582 11.2916C19.4931 11.6343 17.3754 12.6122 11.7482 12.7209C6.5334 12.8212 3.96367 11.8558 3.5671 11.1537C3.4732 10.9224 3.46161 10.6633 3.53446 10.4237C3.60731 10.1842 3.7597 9.9804 3.96367 9.84976C3.97033 9.84729 3.97614 9.8428 3.98038 9.83685C3.98461 9.8309 3.98708 9.82376 3.98746 9.81633C3.98746 9.79125 3.98746 9.77454 3.93987 9.77454C3.63649 9.86394 3.37568 10.0694 3.20846 10.3508C3.04123 10.6321 2.97963 10.9691 3.03571 11.2958C3.47193 12.7711 6.87048 13.8953 12.4065 13.8368C15.579 13.8033 18.1844 13.3269 20.1633 12.3322C21.2062 11.8098 21.0357 11.2038 20.8969 11.0408Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5788_3234">
                <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(3 3)"
                />
            </clipPath>
        </defs>
    </IconBase>
);
