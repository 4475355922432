import IconBase, { IconBaseProps } from '../IconBase';

export const SendIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M9.30142 14.695L19.1025 4.89754M9.58 15.1356L11.8024 19.5805C12.3395 20.6546 12.608 21.1917 12.9463 21.3357C13.2399 21.4607 13.5755 21.4381 13.8496 21.2748C14.1656 21.0867 14.3596 20.5185 14.7477 19.382L18.9468 7.08447C19.2849 6.09421 19.454 5.59908 19.3383 5.27154C19.2376 4.9866 19.0135 4.76246 18.7285 4.66179C18.401 4.54607 17.9059 4.71514 16.9156 5.05328L4.61812 9.25242C3.48163 9.64049 2.91338 9.83453 2.72527 10.1504C2.56202 10.4246 2.5394 10.7602 2.66438 11.0538C2.8084 11.3921 3.34547 11.6606 4.41961 12.1977L8.86445 14.4201C9.04145 14.5086 9.12994 14.5528 9.20663 14.6119C9.27468 14.6644 9.33568 14.7254 9.38814 14.7935C9.44725 14.8701 9.4915 14.9586 9.58 15.1356Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
