import IconBase, { IconBaseProps } from '../IconBase';

export const XlsFileIcon = (props: IconBaseProps) => (
    <IconBase {...props} viewBox="0 0 32 32">
        <g clipPath="url(#clip0_5937_1456)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.19281 0H18.9023L27.9651 9.45986V27.8307C27.9651 30.1314 26.1082 32 23.8074 32H8.19281C5.90376 32 4.03516 30.1314 4.03516 27.8307V4.15765C4.03512 1.8686 5.90376 0 8.19281 0V0Z"
                fill="#00733B"
            />
            <path
                opacity="0.302"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8906 0V9.3781H27.9651L18.8906 0Z"
                fill="white"
            />
            <path
                d="M13.986 23.2992H12.4327L11.3699 21.5124L10.3071 23.2992H8.74219L10.5875 20.1927L8.96412 17.4948H10.529L11.3699 18.8963L12.1991 17.4948H13.764L12.1523 20.2043L13.986 23.2992ZM14.5349 23.2992V17.4948H16.0181V22.0379H18.5407V23.2992H14.5349V23.2992ZM20.9933 23.3693C20.4093 23.3693 19.8955 23.1824 19.4517 22.8204C19.0195 22.47 18.7743 22.0262 18.7393 21.5007L20.0123 21.127C20.0473 21.4073 20.1641 21.6525 20.3626 21.8394C20.5728 22.0263 20.8064 22.1197 21.0634 22.1197C21.2736 22.1197 21.4488 22.073 21.5889 21.9795C21.7174 21.8861 21.7874 21.7577 21.7874 21.6058C21.7874 21.4774 21.729 21.3723 21.6239 21.2788C21.5188 21.1971 21.3787 21.127 21.2151 21.0686C21.0516 21.0102 20.8531 20.9518 20.6546 20.9051C20.4444 20.8467 20.2341 20.7767 20.0356 20.6832C19.8254 20.6015 19.6385 20.4963 19.475 20.3679C19.2998 20.2511 19.1714 20.0759 19.0662 19.854C18.9611 19.6438 18.9027 19.3868 18.9027 19.0949C18.9027 18.6161 19.1012 18.219 19.51 17.9036C19.9188 17.5766 20.4093 17.4248 20.9815 17.4248C21.5538 17.4248 22.056 17.5649 22.4764 17.8336C22.8969 18.1138 23.1655 18.4759 23.2706 18.9197L21.9392 19.4803C21.8808 19.235 21.764 19.0365 21.6005 18.8847C21.437 18.7445 21.2268 18.6628 20.9815 18.6628C20.7947 18.6628 20.6545 18.7095 20.5378 18.7796C20.4326 18.8497 20.3859 18.9548 20.3859 19.0832C20.3859 19.2 20.456 19.3051 20.5961 19.3869C20.7363 19.457 20.9232 19.5153 21.1334 19.5504C21.3436 19.5854 21.5771 19.6438 21.8224 19.7256C22.0793 19.819 22.3012 19.9125 22.5231 20.0409C22.7333 20.1577 22.9085 20.3562 23.0487 20.6132C23.2005 20.8818 23.2705 21.1971 23.2705 21.5592C23.2705 22.0964 23.0603 22.5402 22.6399 22.8672C22.2194 23.1942 21.6707 23.3693 20.9933 23.3693Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5937_1456">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </IconBase>
);
