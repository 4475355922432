import IconBase, { IconBaseProps } from '../IconBase';

export const MessAttachIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M19.8278 11.2436L12.7074 18.364C10.7548 20.3167 7.58896 20.3167 5.63634 18.364C3.68372 16.4114 3.68372 13.2456 5.63634 11.293L12.4717 4.4576C13.7735 3.15586 15.884 3.15586 17.1858 4.4576C18.4875 5.75935 18.4875 7.8699 17.1858 9.17165L10.3614 15.996C9.71048 16.6469 8.6552 16.6469 8.00433 15.9961C7.35345 15.3452 7.35345 14.2899 8.00433 13.639L14.2258 7.41759"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
