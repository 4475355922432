import IconBase, { IconBaseProps } from '../IconBase';

export const TableStepsIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M4 4H20M4 20H20M7.2 16H16.8C17.9201 16 18.4802 16 18.908 15.782C19.2843 15.5903 19.5903 15.2843 19.782 14.908C20 14.4802 20 13.9201 20 12.8V11.2C20 10.0799 20 9.51984 19.782 9.09202C19.5903 8.71569 19.2843 8.40973 18.908 8.21799C18.4802 8 17.9201 8 16.8 8H7.2C6.0799 8 5.51984 8 5.09202 8.21799C4.71569 8.40973 4.40973 8.71569 4.21799 9.09202C4 9.51984 4 10.0799 4 11.2V12.8C4 13.9201 4 14.4802 4.21799 14.908C4.40973 15.2843 4.71569 15.5903 5.09202 15.782C5.51984 16 6.07989 16 7.2 16Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
