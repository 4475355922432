import { SxProps } from '@mui/material';

export const featureList: SxProps = {
    my: 8,
    mr: 8,
    padding: '0',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
};
export const featureItem: SxProps = {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 3,
};
