import IconBase, { IconBaseProps } from '../IconBase';

export const TriangleUpIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.49547 13.1489L10.8239 8.07322C11.4756 7.30893 12.5285 7.30893 13.1803 8.07322L17.5086 13.1489C18.5615 14.3835 17.8095 16.5 16.3221 16.5H7.66531C6.17794 16.5 5.44262 14.3835 6.49547 13.1489Z"
            fill="currentColor"
        />
    </IconBase>
);
