import { menuItemClasses, SxProps, Theme } from '@mui/material';

export const row: SxProps<Theme> = {
    padding: (theme) => theme.spacing(2, 4),
    border: '1px solid',
    borderColor: 'custom.gray.light400',
    borderRadius: 1.5,

    '&:hover': {
        borderColor: 'info.main',
    },
};

export const contextMenuButton: SxProps = {
    padding: 0,

    '&:hover': {
        color: 'info.main',
    },
};

export const contextMenu: SxProps = {
    [`& .${menuItemClasses.root}`]: {
        minWidth: 172,
    },
};
