import styles from './WorkflowAside.module.scss';
// assets
import { WorkflowTrigger } from '../types';
import AdvancedSelect, {
    OptionId,
    OptionWithDescription,
} from '../../../elements/AdvancedSelect';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import { renderSelectOptionWithDescription } from '../../../elements/AdvancedSelect/renderrers';
import { WorkflowAsideSchedule } from './WorkflowAsideSchedule';

const TriggerOptions: OptionWithDescription[] = [
    {
        id: WorkflowTrigger.IncomingMessage,
        label: 'Incoming message',
        description: 'Start the workflow when a contact sends a message',
    },
    {
        id: WorkflowTrigger.Schedule,
        label: 'Schedule',
        description: 'Start the workflow at specific times',
    },
    {
        id: WorkflowTrigger.OutboundMessage,
        label: 'Outbound message',
        description: 'Start the workflow after a message is sent',
    },
    {
        id: WorkflowTrigger.OutboundMessagePreSend,
        label: 'Outbound message (pre-send)',
        description: 'Start the workflow before a message is sent',
    },
];

export const WorkflowAsideTriggers = () => {
    const {
        state: { workflow },
        dispatch,
    } = useWorkflowContext();

    const handleTriggerChange = (option: OptionId) => {
        dispatch({
            type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_TRIGGER,
            payload: option,
        });
    };
    return (
        <div className={styles['aside__content']}>
            <section className={styles['aside__box']}>
                <div className={styles['aside__box-select']}>
                    <p>Choose the trigger that will start this workflow.</p>
                    <label className={styles['aside__box-select-label']}>
                        Trigger option
                    </label>
                    <AdvancedSelect
                        options={TriggerOptions}
                        onChange={handleTriggerChange}
                        value={workflow?.trigger}
                        renderOption={renderSelectOptionWithDescription}
                    />
                </div>
                {workflow?.trigger === WorkflowTrigger.Schedule && (
                    <WorkflowAsideSchedule />
                )}
            </section>
        </div>
    );
};
