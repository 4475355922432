import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import { borderRadius } from './border-radius';

export const MuiCard: Components<Theme>['MuiCard'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            backgroundColor: theme.palette.custom.secondary.blue.light,
            boxShadow: 'none',
            border: `1px solid ${theme.palette.custom.gray.divider}`,
            padding: theme.spacing(4),
            borderRadius,
        }),
    },
};
