import IconBase, { IconBaseProps } from '../IconBase';

export const OtherFileIcon = (props: IconBaseProps) => (
    <IconBase {...props} viewBox="0 0 32 32">
        <g clipPath="url(#clip0_5937_1980)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.20008 0H18.8987L27.9662 9.45128V27.8341C27.9662 30.1369 26.1031 32 23.8083 32H8.20008C5.89724 32 4.03418 30.1369 4.03418 27.8341V4.1659C4.03414 1.86306 5.8972 0 8.20008 0V0Z"
                fill="#6C6F85"
            />
            <path
                d="M21.2185 19.4958L16.4716 24.2428C15.1699 25.5445 13.0593 25.5445 11.7576 24.2428C10.4558 22.941 10.4558 20.8305 11.7576 19.5287L16.3145 14.9718C17.1823 14.104 18.5893 14.104 19.4572 14.9718C20.325 15.8396 20.325 17.2467 19.4572 18.1145L14.9076 22.6641C14.4737 23.098 13.7701 23.098 13.3362 22.6641C12.9023 22.2302 12.9023 21.5267 13.3362 21.0927L17.4838 16.9451"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.302"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8906 0V9.37931H27.9661L18.8906 0Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5937_1980">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </IconBase>
);
