import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const AttachmentIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8794 4.15666C13.0387 3.31594 11.6756 3.31594 10.8349 4.15666L5.13877 9.85279C3.75566 11.2359 3.75566 13.4784 5.13877 14.8615C6.52188 16.2446 8.76434 16.2446 10.1474 14.8615L16.0811 8.9278C16.3252 8.68372 16.7209 8.68372 16.965 8.9278C17.2091 9.17188 17.2091 9.56761 16.965 9.81168L11.0313 15.7453C9.16007 17.6166 6.12615 17.6166 4.25489 15.7453C2.38362 13.8741 2.38363 10.8402 4.25489 8.96891L9.95103 3.27277C11.2799 1.94391 13.4344 1.94391 14.7633 3.27277C16.0921 4.60164 16.0921 6.75616 14.7633 8.08503L9.07628 13.772C8.28981 14.5585 7.01468 14.5585 6.22821 13.772C5.44174 12.9856 5.44174 11.7104 6.22821 10.924L11.4127 5.73942C11.6568 5.49535 12.0525 5.49535 12.2966 5.73942C12.5407 5.9835 12.5407 6.37923 12.2966 6.62331L7.11209 11.8078C6.81377 12.1062 6.81377 12.5898 7.11209 12.8881C7.41041 13.1865 7.89408 13.1865 8.19239 12.8881L13.8794 7.20114C14.7201 6.36043 14.7201 4.99737 13.8794 4.15666Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
