import IconBase, { IconBaseProps } from '../IconBase';

export const CustomTimerIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.53033 2.96967C5.82322 3.26256 5.82322 3.73744 5.53033 4.03033L3.53033 6.03033C3.23744 6.32322 2.76256 6.32322 2.46967 6.03033C2.17678 5.73744 2.17678 5.26256 2.46967 4.96967L4.46967 2.96967C4.76256 2.67678 5.23744 2.67678 5.53033 2.96967ZM18.4697 2.96967C18.7626 2.67678 19.2374 2.67678 19.5303 2.96967L21.5303 4.96967C21.8232 5.26256 21.8232 5.73744 21.5303 6.03033C21.2374 6.32322 20.7626 6.32322 20.4697 6.03033L18.4697 4.03033C18.1768 3.73744 18.1768 3.26256 18.4697 2.96967ZM12 5.25C7.99594 5.25 4.75 8.49594 4.75 12.5C4.75 16.5041 7.99594 19.75 12 19.75C16.0041 19.75 19.25 16.5041 19.25 12.5C19.25 8.49594 16.0041 5.25 12 5.25ZM3.25 12.5C3.25 7.66751 7.16751 3.75 12 3.75C16.8325 3.75 20.75 7.66751 20.75 12.5C20.75 17.3325 16.8325 21.25 12 21.25C7.16751 21.25 3.25 17.3325 3.25 12.5ZM12 7.75C12.4142 7.75 12.75 8.08579 12.75 8.5V12.1893L14.5303 13.9697C14.8232 14.2626 14.8232 14.7374 14.5303 15.0303C14.2374 15.3232 13.7626 15.3232 13.4697 15.0303L11.4697 13.0303C11.329 12.8897 11.25 12.6989 11.25 12.5V8.5C11.25 8.08579 11.5858 7.75 12 7.75Z"
            fill="currentColor"
        />
    </IconBase>
);
