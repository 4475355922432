import IconBase, { IconBaseProps } from '../IconBase';

export const RemindMessageIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M13.7002 14.4361C13.1694 14.9111 12.4685 15.2 11.7002 15.2C10.0433 15.2 8.7002 13.8568 8.7002 12.2C8.7002 10.5431 10.0433 9.19995 11.7002 9.19995C13.0064 9.19995 14.1177 10.0348 14.5295 11.2M15.2002 8.69995V11.7H12.2002M21.0039 12C21.0039 16.9706 16.9745 21 12.0039 21C9.9675 21 3.00463 21 3.00463 21C3.00463 21 4.56382 17.2561 3.93982 16.0008C3.34076 14.7956 3.00391 13.4372 3.00391 12C3.00391 7.02944 7.03334 3 12.0039 3C16.9745 3 21.0039 7.02944 21.0039 12Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
