import Button from '../../../elements/Buttons';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import styles from './WorkflowAside.module.scss';

type WorkflowAsideProps = {
    title: string;
    showCloseButton?: boolean;
    children: JSX.Element;
};

export const WorkflowAside = (props: WorkflowAsideProps) => {
    const { dispatch } = useWorkflowContext();
    const deselectStep = () => {
        dispatch({
            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
            payload: null,
        });
    };
    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>{props.title}</h3>
                {props.showCloseButton && (
                    <Button type="icon" onClick={deselectStep}>
                        Close
                    </Button>
                )}
            </header>
            {props.children}
        </div>
    );
};
