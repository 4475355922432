import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const CheckmarkIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6882 3.98105C13.8828 4.177 13.8817 4.49358 13.6857 4.68816L6.30112 12.0215C6.10614 12.2151 5.79146 12.2151 5.59648 12.0215L2.31443 8.76223C2.11849 8.56765 2.11738 8.25107 2.31196 8.05513C2.50655 7.85919 2.82313 7.85808 3.01907 8.05267L5.9488 10.9621L12.9811 3.97859C13.177 3.78401 13.4936 3.78511 13.6882 3.98105Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
