import { Contact } from '../../api/types';
import Icon from '../../icons/Icon';
import { useContactUpdateQuery } from '../../queries/contacts';
import { useMeQueryData } from '../../queries/user';
import CollapsiblePropertiesSection from '../CollapsiblePropertiesSection';
import ToggleSwitch from '../ToggleSwitch';
import styles from './ComplianceProperties.module.scss';

type CompliancePropertiesProps = {
    contact: Partial<Contact>;
};

export const ComplianceProperties = (props: CompliancePropertiesProps) => {
    const { mutateAsync: updateContact } = useContactUpdateQuery();
    const me = useMeQueryData();

    return (
        <CollapsiblePropertiesSection title="Compliance" initiallyExpanded>
            {me?.id === props.contact?.createdByUserId && (
                <div className={styles['row']}>
                    <Icon size="20px" name="lock" />
                    <div className={styles['label']}>
                        <span>Personal contact</span>
                        <p>Only visible to you</p>
                    </div>
                    <ToggleSwitch
                        className={styles['toggle']}
                        isChecked={!props.contact?.public || false}
                        onToggle={() => {
                            updateContact({
                                ...props.contact,
                                public: !props.contact?.public,
                            });
                        }}
                    />
                </div>
            )}
            <div className={styles['row']}>
                <Icon size="20px" name="opt-out" />
                <div className={styles['label']}>
                    <span>Opt out</span>
                    <p>Will no longer receive messages</p>
                </div>
                <ToggleSwitch
                    className={styles['toggle']}
                    isChecked={props.contact?.optOut || false}
                    onToggle={() => {
                        updateContact({
                            ...props.contact,
                            optOut: !props.contact?.optOut,
                        });
                    }}
                />
            </div>
        </CollapsiblePropertiesSection>
    );
};
