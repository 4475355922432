import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const CAFlagIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.6206 4.138H5.37936V19.8622H18.6206V4.138Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.79742 4.13782C0.804703 4.13782 0 4.94252 0 5.93524V18.0646C0 19.0572 0.804703 19.862 1.79742 19.862H5.37933V4.13782H1.79742Z"
                fill="#FF4B55"
            />
            <path
                d="M22.2026 4.13782H18.6207V19.8619H22.2026C23.1952 19.8619 24 19.0572 24 18.0645V5.93524C24 4.94252 23.1953 4.13782 22.2026 4.13782Z"
                fill="#FF4B55"
            />
            <path
                d="M14.5111 13.8167L16.9665 12.4137L16.4031 12.132C16.2451 12.0529 16.1546 11.8823 16.178 11.7071L16.3613 10.3327L15.3218 10.6351C15.0921 10.7019 14.8538 10.5607 14.8022 10.3272L14.7026 9.87718L13.7265 11.0195C13.5912 11.1779 13.3334 11.0569 13.3687 10.8517L13.7856 8.42785L13.1474 8.60236C12.9609 8.65336 12.7641 8.56852 12.6732 8.39799L12.0011 7.13841V7.13654L12.0007 7.13747L12.0002 7.13654V7.13841L11.3281 8.39799C11.2371 8.56847 11.0402 8.65332 10.8538 8.60236L10.2156 8.42785L10.6325 10.8517C10.6678 11.0569 10.41 11.1779 10.2746 11.0196L9.29856 9.87722L9.199 10.3272C9.14734 10.5608 8.90903 10.7019 8.67939 10.6351L7.63984 10.3327L7.82317 11.7072C7.84656 11.8823 7.75609 12.053 7.59807 12.132L7.03464 12.4137L9.49009 13.8168C9.74443 13.9621 9.8559 14.2704 9.75329 14.5448L9.54339 15.1062L11.6474 14.9266C11.7638 14.9166 11.8629 15.0104 11.8595 15.1271L11.7932 17.3792H12.207L12.1406 15.127C12.1372 15.0102 12.2363 14.9165 12.3527 14.9265L14.4577 15.1062L14.2478 14.5448C14.1453 14.2703 14.2567 13.9621 14.5111 13.8167Z"
                fill="#FF4B55"
            />
        </svg>
    </SvgIcon>
);
