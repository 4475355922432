import { useState } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { LockIcon } from '../../../../icons/shared/LockIcon';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { Invite } from '../../../../api/types';
import { roleLabels } from '../utils';
import { PendingIcon } from './icons/PendingIcon';
import { useDeleteInvite, useResendInvite } from '../../../../queries/user';
import { ResendIcon } from './icons/ResendIcon';
import { CloseIcon } from '../../../../icons/shared/CloseIcon';
import { CheckmarkIcon } from '../../../../icons/shared/CheckmarkIcon';
import { RowWithMenu } from '../../../../components/RowWithMenu/RowWithMenu';

export function InviteRow({ invite }: { invite: Invite }) {
    const [sentInvite, setSentInvite] = useState(false);

    const { mutate: deleteInvite } = useDeleteInvite();
    const { mutate: resendInvite } = useResendInvite();

    const handleResendInvite = () => {
        resendInvite({ id: invite.id });
        setSentInvite(true);
        setTimeout(() => {
            setSentInvite(false);
        }, 2000);
    };

    const handleRevokeInvite = () => {
        deleteInvite({ id: invite.id });
    };

    return (
        <RowWithMenu
            menuItems={[
                {
                    onClick: handleResendInvite,
                    icon: <ResendIcon />,
                    label: 'Resend invite',
                    divider: true,
                },
                {
                    onClick: handleRevokeInvite,
                    icon: <CloseIcon color="error" />,
                    label: (
                        <Typography variant="subtitle2" color="error">
                            Revoke invite
                        </Typography>
                    ),
                },
            ]}
        >
            <Stack spacing={4} direction="row" alignItems="center" flex={1}>
                <UserAvatar size={40} />
                <Typography variant="body3" color="primary.dark">
                    {invite.email}
                </Typography>
            </Stack>
            <Stack
                spacing={6}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width={{ sm: 1, md: 'auto' }}
                flex={1}
            >
                <Stack
                    color="custom.gray.super"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    flex={1}
                >
                    {!!invite.role && (
                        <>
                            <LockIcon sx={{ width: 20, height: 20 }} />
                            <Typography variant="body3" color="primary.dark">
                                {roleLabels[invite.role]}
                            </Typography>
                        </>
                    )}
                </Stack>
                {sentInvite ? (
                    <Stack spacing={1} direction="row" alignItems="center">
                        <CheckmarkIcon color="success" />
                        <Typography variant="body4" color="success.main">
                            The invitation has been resent
                        </Typography>
                    </Stack>
                ) : (
                    <Chip
                        size="small"
                        color="warning"
                        label="pending"
                        icon={<PendingIcon />}
                    />
                )}
            </Stack>
        </RowWithMenu>
    );
}
