import { Components, Theme } from '@mui/material';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
    defaultProps: {
        variant: 'outlined',
        InputLabelProps: {
            required: false,
        },
        InputProps: {
            notched: false,
        },
    },
};
