import client from './http';
import {
    MessageTemplate,
    CreateMessageTemplateDto,
    UpdateMessageTemplateDto,
} from './types';

export function getMessageTemplates(): Promise<MessageTemplate[]> {
    return client()
        .get('/message-templates')
        .then((res) => res.data);
}

export function createMessageTemplate(
    payload: CreateMessageTemplateDto,
): Promise<MessageTemplate> {
    return client()
        .post('/message-templates', payload)
        .then((res) => res.data);
}

export function updateMessageTemplate(
    payload: UpdateMessageTemplateDto,
): Promise<MessageTemplate> {
    const { id, ...restDto } = payload;
    return client()
        .put(`/message-templates/${id}`, restDto)
        .then((res) => res.data);
}

export function removeMessageTemplate(id: number): Promise<void> {
    return client()
        .delete(`/message-templates/${id}`)
        .then((res) => res.data);
}
