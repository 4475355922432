import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const CloseIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.43319 5.43327C5.67726 5.18919 6.07299 5.18919 6.31707 5.43327L9.99992 9.11612L13.6828 5.43327C13.9268 5.18919 14.3226 5.18919 14.5666 5.43327C14.8107 5.67735 14.8107 6.07307 14.5666 6.31715L10.8838 10L14.5666 13.6828C14.8107 13.9269 14.8107 14.3227 14.5666 14.5667C14.3226 14.8108 13.9268 14.8108 13.6828 14.5667L9.99992 10.8839L6.31707 14.5667C6.07299 14.8108 5.67726 14.8108 5.43319 14.5667C5.18911 14.3227 5.18911 13.9269 5.43319 13.6828L9.11603 10L5.43319 6.31715C5.18911 6.07307 5.18911 5.67735 5.43319 5.43327Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
