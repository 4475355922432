import IconBase, { IconBaseProps } from '../IconBase';

export const CustomHashIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.1382 3.26286C10.5453 3.33919 10.8135 3.73111 10.7372 4.13823L10.1537 7.25001H15.6276L16.2628 3.8618C16.3392 3.45468 16.7311 3.18652 17.1382 3.26286C17.5453 3.33919 17.8135 3.73111 17.7372 4.13823L17.1537 7.25001H20C20.4142 7.25001 20.75 7.5858 20.75 8.00001C20.75 8.41423 20.4142 8.75001 20 8.75001H16.8724L15.6537 15.25H19C19.4142 15.25 19.75 15.5858 19.75 16C19.75 16.4142 19.4142 16.75 19 16.75H15.3724L14.7372 20.1382C14.6608 20.5453 14.2689 20.8135 13.8618 20.7372C13.4547 20.6608 13.1865 20.2689 13.2628 19.8618L13.8463 16.75H8.37244L7.73715 20.1382C7.66082 20.5453 7.2689 20.8135 6.86178 20.7372C6.45466 20.6608 6.18651 20.2689 6.26285 19.8618L6.84631 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16C3.25 15.5858 3.58579 15.25 4 15.25H7.12756L8.34631 8.75001H5C4.58579 8.75001 4.25 8.41423 4.25 8.00001C4.25 7.5858 4.58579 7.25001 5 7.25001H8.62756L9.26285 3.8618C9.33918 3.45468 9.7311 3.18652 10.1382 3.26286ZM9.87244 8.75001L8.65369 15.25H14.1276L15.3463 8.75001H9.87244Z"
            fill="currentColor"
        />
    </IconBase>
);
