import { Message, SenderType } from '../../../api/types';
import dayjs from 'dayjs';

export const checkIsTooLate = (time: dayjs.ConfigType): boolean =>
    dayjs(time).diff(new Date(), 'years') > 1;

export const checkIsUntilReview = (
    message: Pick<Message, 'senderType' | 'timestamp'>,
) =>
    checkIsTooLate(message.timestamp) &&
    message.senderType === SenderType.ASSISTANT;
