import IconBase, { IconBaseProps } from '../IconBase';

/** @depreacted use src/icons/common/CCloseIcon.tsx*/
export const CloseIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M7.05033 16.9497L16.9498 7.05025M7.05033 7.05025L16.9498 16.9497"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
