import IconBase, { IconBaseProps } from '../IconBase';

export const GoogleContactsLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <g clipPath="url(#clip0_5788_3165)">
            <path
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                fill="#1A73E8"
            />
            <path
                d="M15.9552 18H8.18398C7.24318 18 6.41278 17.4336 6.41278 16.5024V17.184C6.41278 18.1152 7.24318 18.8208 8.18398 18.8208H15.9552C16.896 18.8208 17.7264 18.1152 17.7264 17.184V16.5024C17.7264 17.4336 16.896 18 15.9552 18Z"
                fill="#185ABC"
            />
            <path
                d="M12 10.9104C10.5936 10.9056 9.48482 9.93124 9.40802 8.59204V9.13924C9.40802 10.5264 10.5696 11.7312 12 11.7312C13.4304 11.7312 14.592 10.5264 14.592 9.13924V8.59204C14.4816 9.94084 13.4064 10.9152 12 10.9104Z"
                fill="#185ABC"
            />
            <path
                d="M12 12.5472C9.26402 12.5472 6.40802 13.8816 6.40802 15.6816V16.4976C6.40802 17.4288 7.17122 18.1344 8.11202 18.1344H16.0176C16.9584 18.1344 17.7216 17.4288 17.7216 16.4976V15.6816C17.7264 13.8768 14.736 12.5472 12 12.5472Z"
                fill="white"
            />
            <path
                d="M12 11.0448C13.4315 11.0448 14.592 9.88436 14.592 8.45284C14.592 7.02132 13.4315 5.86084 12 5.86084C10.5685 5.86084 9.40802 7.02132 9.40802 8.45284C9.40802 9.88436 10.5685 11.0448 12 11.0448Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5788_3165">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </IconBase>
);
