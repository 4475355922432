export enum MimeType {
    PDF = 'application/pdf',
    CSV = 'text/csv',
    DOC = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    DOCX = 'application/msword',
    VCARD = 'text/vCard',
}

export const allowedDocMimeTypes: MimeType[] = [
    MimeType.PDF,
    MimeType.CSV,
    MimeType.DOC,
    MimeType.DOCX,
    MimeType.VCARD,
];

export const acceptDocFormats = allowedDocMimeTypes.join(',');

export const acceptMediaFormats = 'image/*,video/*,audio/*';

export const acceptImageFormats = '.png,.jpg,.jpeg';

export const acceptFileFormats = `${acceptMediaFormats},${acceptDocFormats}`;
