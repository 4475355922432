import { TextField } from '@mui/material';
import { GenericDialog } from '../GenericDialog/GenericDialog';
import { IntegrationIcon } from '../../../icons/shared/IntegrationIcon';
import { useForm } from 'react-hook-form';
import { ArrowLeftIcon } from '../../../icons/shared/ArrowLeftIcon';

type Props = {
    open: boolean;
    onClose: () => void;
};

type Inputs = {
    storeName: string;
};

export function ConnectShopifyIntegrationModal({ onClose, open }: Props) {
    const {
        handleSubmit,
        formState: { errors, isValid },
        register,
    } = useForm<Inputs>({
        defaultValues: {
            storeName: '',
        },
    });

    return (
        <GenericDialog
            open={open}
            onClose={onClose}
            IconComponent={IntegrationIcon}
            title="Connect Shopify"
            description={
                <>
                    Connect your shopify account by entering the URL of your
                    store. For example, <b> clerk-testing.myshopify.com</b>
                </>
            }
            onSubmit={handleSubmit((formData: Inputs) => {
                onClose();
                const url = new URL(import.meta.env.VITE_SHOPIFY_OAUTH_URL!);
                url.searchParams.append('shop', formData.storeName);

                window.open(url.href, '_blank', 'noopener, noreferrer');
            })}
            confirmLabel="Connect"
            confirmButtonProps={{
                disabled: !isValid,
            }}
            dismissButtonProps={{
                startIcon: <ArrowLeftIcon />,
            }}
            dismissLabel="Back"
        >
            <TextField
                {...register('storeName', { required: true })}
                error={!!errors.storeName}
                fullWidth
                placeholder="Enter your shopify store name"
                label="Shopify Store Name"
                helperText={
                    !!errors.storeName && 'Store name field is required'
                }
            />
        </GenericDialog>
    );
}
