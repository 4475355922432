import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const SendIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
            <g clipPath="url(#clip0_18315_566)">
                <path
                    d="M16.1828 2.29262C16.1 1.94608 15.7192 1.71029 15.3739 1.83285C15.3739 1.83285 0.413782 7.11183 0.411293 7.1137C0.181674 7.19894 0.0130377 7.41793 0.000592254 7.66866C-0.0106087 7.90944 0.138115 8.16016 0.357777 8.2622L6.15239 10.9667C6.15239 10.9667 8.97564 8.14399 8.98062 8.13901C9.54066 7.57907 10.4349 8.44511 9.86051 9.01874L7.03228 11.8464C7.03228 11.8464 9.73357 17.6337 9.73855 17.643C9.84869 17.8546 10.064 17.989 10.3005 17.9995C10.5575 18.0114 10.7952 17.8148 10.8873 17.5846C10.8873 17.5846 16.1641 2.63605 16.1673 2.62672C16.2065 2.51909 16.2089 2.40274 16.1828 2.29262Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_18315_566">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
