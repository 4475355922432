import { Components, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
    defaultProps: {
        disableAnimation: true,
        shrink: true,
    },
    styleOverrides: {
        outlined: ({ theme }) => ({
            ...theme.typography.body4,
            marginBottom: theme.spacing(1),
            letterSpacing: '0.2px',
            color: theme.palette.custom.gray.super,
            position: 'relative',
            transform: 'none',
        }),
    },
};
