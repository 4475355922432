import IconBase, { IconBaseProps } from '../IconBase';

export const ZipcodeIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.375 2.5C9.375 2.15482 9.65482 1.875 10 1.875H12.5C12.8452 1.875 13.125 2.15482 13.125 2.5C13.125 2.84518 12.8452 3.125 12.5 3.125H10.625V4.375H14.5833C16.5393 4.375 18.125 5.96066 18.125 7.91667V15C18.125 15.3452 17.8452 15.625 17.5 15.625H10.625V17.5C10.625 17.8452 10.3452 18.125 10 18.125C9.65482 18.125 9.375 17.8452 9.375 17.5V15.625H2.5C2.15482 15.625 1.875 15.3452 1.875 15L1.875 8.97325C1.87499 8.29588 1.87499 7.74953 1.91114 7.30711C1.94835 6.85158 2.02699 6.45146 2.2156 6.08128C2.51521 5.49327 2.99327 5.01521 3.58128 4.7156C3.95146 4.52699 4.35158 4.44835 4.80711 4.41114C5.24953 4.37499 5.79588 4.37499 6.47324 4.375H9.375V2.5ZM6.5 5.625C5.78962 5.625 5.29443 5.62549 4.9089 5.65698C4.53067 5.68789 4.31337 5.7455 4.14877 5.82936C3.79596 6.00913 3.50913 6.29596 3.32936 6.64877C3.2455 6.81337 3.18789 7.03067 3.15699 7.4089C3.12549 7.79443 3.125 8.28962 3.125 9V14.375H11.0417V7.91667C11.0417 7.04271 11.3582 6.24268 11.8829 5.625H6.5ZM14.5833 5.625C13.3177 5.625 12.2917 6.65101 12.2917 7.91667V14.375H16.875V7.91667C16.875 6.65101 15.849 5.625 14.5833 5.625ZM5.20833 10C5.20833 9.65482 5.48816 9.375 5.83333 9.375H8.33333C8.67851 9.375 8.95833 9.65482 8.95833 10C8.95833 10.3452 8.67851 10.625 8.33333 10.625H5.83333C5.48816 10.625 5.20833 10.3452 5.20833 10Z"
            fill="currentColor"
        />
    </IconBase>
);
