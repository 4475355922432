import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const EmojiIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM6.75 7.75C6.75 7.19771 7.19771 6.75 7.75 6.75C8.30228 6.75 8.75 7.19771 8.75 7.75C8.75 8.30228 8.30228 8.75 7.75 8.75C7.19771 8.75 6.75 8.30228 6.75 7.75ZM11.25 7.75C11.25 7.19771 11.6977 6.75 12.25 6.75C12.8023 6.75 13.25 7.19771 13.25 7.75C13.25 8.30228 12.8023 8.75 12.25 8.75C11.6977 8.75 11.25 8.30228 11.25 7.75ZM6.625 11C6.90046 10.7934 7.29097 10.8486 7.49847 11.123L7.50312 11.1289C7.50829 11.1354 7.51753 11.1469 7.53074 11.1627C7.55719 11.1942 7.59932 11.2425 7.65632 11.3019C7.77084 11.4214 7.94239 11.583 8.16448 11.7445C8.61108 12.0693 9.23471 12.375 10 12.375C10.7653 12.375 11.3889 12.0693 11.8355 11.7445C12.0576 11.583 12.2292 11.4214 12.3437 11.3019C12.4007 11.2425 12.4428 11.1942 12.4693 11.1627C12.4825 11.1469 12.4917 11.1354 12.4969 11.1289L12.5015 11.123C12.709 10.8486 13.0995 10.7934 13.375 11C13.6511 11.2071 13.7071 11.5989 13.5 11.875L13.015 11.5113C13.5 11.875 13.4998 11.8752 13.4997 11.8754L13.4994 11.8759L13.4986 11.8768L13.4968 11.8792L13.4919 11.8857L13.4769 11.9048C13.4647 11.9202 13.4479 11.941 13.4267 11.9662C13.3843 12.0168 13.3239 12.0857 13.2462 12.1668C13.0912 12.3286 12.8643 12.542 12.5707 12.7555C11.9861 13.1807 11.1097 13.625 10 13.625C8.89029 13.625 8.01392 13.1807 7.42927 12.7555C7.13574 12.542 6.90884 12.3286 6.75384 12.1668C6.67607 12.0857 6.61566 12.0168 6.57327 11.9662C6.55205 11.941 6.53529 11.9202 6.52306 11.9048L6.50812 11.8857L6.5032 11.8792L6.50139 11.8768L6.50064 11.8759L6.50031 11.8754C6.50015 11.8752 6.50001 11.875 6.96583 11.5256L6.5 11.875C6.29289 11.5989 6.34886 11.2071 6.625 11ZM12.5016 11.1229C12.5018 11.1226 12.5018 11.1226 12.5015 11.1229L12.5016 11.1229Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
