import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const UploadIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5744 4.79163C8.89721 4.79163 7.44216 5.86094 6.82611 7.32561C6.74258 7.52421 6.56265 7.66578 6.34997 7.70025C4.40325 8.01569 3.125 9.6393 3.125 11.3744C3.125 13.4864 4.85448 15.2083 7 15.2083L13.75 15.2083C15.4755 15.2083 16.875 13.8084 16.875 12.0807C16.875 10.7804 16.0876 9.58996 14.9996 9.11428C14.7839 9.01998 14.6395 8.8125 14.626 8.57749C14.505 6.47132 12.7415 4.79163 10.5744 4.79163ZM5.81596 6.53133C6.6793 4.79796 8.47801 3.54163 10.5744 3.54163C13.272 3.54163 15.506 5.54086 15.8397 8.13673C17.2134 8.89751 18.125 10.4354 18.125 12.0807C18.125 14.498 16.1667 16.4583 13.75 16.4583L7 16.4583C4.17496 16.4583 1.875 14.1875 1.875 11.3744C1.875 9.13129 3.44771 7.07054 5.81596 6.53133ZM9.55806 7.47468C9.80214 7.23061 10.1979 7.23061 10.4419 7.47468L12.1086 9.14135C12.3527 9.38543 12.3527 9.78116 12.1086 10.0252C11.8645 10.2693 11.4688 10.2693 11.2247 10.0252L10.625 9.42551V12.9166C10.625 13.2618 10.3452 13.5416 10 13.5416C9.65482 13.5416 9.375 13.2618 9.375 12.9166V9.42551L8.77528 10.0252C8.5312 10.2693 8.13547 10.2693 7.89139 10.0252C7.64731 9.78116 7.64731 9.38543 7.89139 9.14135L9.55806 7.47468Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
