import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const USFlagIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.2026 4.13794H1.79742C0.804703 4.13794 0 4.94264 0 5.93536V18.0647C0 19.0574 0.804703 19.8621 1.79742 19.8621H22.2026C23.1952 19.8621 24 19.0574 24 18.0647V5.93536C24 4.94264 23.1953 4.13794 22.2026 4.13794Z"
                fill="#F5F5F5"
            />
            <path
                d="M0.0988464 5.3475H23.9011C23.6575 4.64358 22.9893 4.13794 22.2026 4.13794H1.79741C1.01066 4.13794 0.342456 4.64358 0.0988464 5.3475Z"
                fill="#FF4B55"
            />
            <path d="M24 8.97607H0V10.1856H24V8.97607Z" fill="#FF4B55" />
            <path d="M24 6.55688H0V7.7664H24V6.55688Z" fill="#FF4B55" />
            <path
                d="M0 12.191C0 12.4195 0.185297 12.6048 0.413813 12.6048H24V11.3952H0V12.191Z"
                fill="#FF4B55"
            />
            <path d="M24 16.2338H0V17.4433H24V16.2338Z" fill="#FF4B55" />
            <path
                d="M23.9011 18.6525H0.0988464C0.342456 19.3564 1.01071 19.8621 1.79741 19.8621H22.2026C22.9893 19.8621 23.6575 19.3564 23.9011 18.6525Z"
                fill="#FF4B55"
            />
            <path d="M24 13.8145H0V15.024H24V13.8145Z" fill="#FF4B55" />
            <path
                d="M0.413813 12.6048H10.7587C10.9872 12.6048 11.1725 12.4195 11.1725 12.191V4.55169C11.1725 4.32313 10.9872 4.13788 10.7587 4.13788H1.79742C0.804703 4.13793 0 4.94263 0 5.93535V12.191C0 12.4195 0.185297 12.6048 0.413813 12.6048Z"
                fill="#41479B"
            />
            <path
                d="M1.16198 5.08777L1.25358 5.36246L1.54312 5.36466C1.58062 5.36499 1.59614 5.41281 1.566 5.43507L1.33308 5.60705L1.42045 5.8831C1.4318 5.91882 1.39106 5.94845 1.36055 5.9266L1.125 5.75827L0.889451 5.92665C0.858936 5.94849 0.818295 5.91887 0.829545 5.88315L0.91692 5.6071L0.683998 5.43512C0.653858 5.4128 0.669373 5.36499 0.706873 5.36471L0.99642 5.36251L1.08801 5.08782C1.09983 5.0522 1.15008 5.0522 1.16198 5.08777Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.16198 6.52457L1.25358 6.79925L1.54312 6.80146C1.58062 6.80179 1.59614 6.84955 1.566 6.87186L1.33308 7.0439L1.42045 7.31994C1.4318 7.35566 1.39106 7.38529 1.36055 7.36344L1.125 7.19511L0.889451 7.36349C0.858936 7.38529 0.818295 7.35571 0.829545 7.31999L0.91692 7.04394L0.683998 6.87191C0.653858 6.8496 0.669373 6.80183 0.706873 6.8015L0.99642 6.7993L1.08801 6.52461C1.09983 6.48904 1.15008 6.48904 1.16198 6.52457Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.16198 7.96149L1.25358 8.23618L1.54312 8.23838C1.58062 8.23871 1.59614 8.28648 1.566 8.30879L1.33308 8.48082L1.42045 8.75687C1.4318 8.79259 1.39106 8.82221 1.36055 8.80037L1.125 8.63204L0.889451 8.80041C0.858936 8.82221 0.818295 8.79268 0.829545 8.75691L0.91692 8.48087L0.683998 8.30884C0.653858 8.28652 0.669373 8.23876 0.706873 8.23843L0.99642 8.23623L1.08801 7.96154C1.09983 7.92591 1.15008 7.92591 1.16198 7.96149Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.16198 9.39844L1.25358 9.67313L1.54312 9.67533C1.58062 9.67566 1.59614 9.72347 1.566 9.74574L1.33308 9.91777L1.42045 10.1938C1.4318 10.2295 1.39106 10.2592 1.36055 10.2373L1.125 10.069L0.889451 10.2374C0.858936 10.2592 0.818295 10.2296 0.829545 10.1939L0.91692 9.91782L0.683998 9.74579C0.653858 9.72357 0.669373 9.67571 0.706873 9.67538L0.99642 9.67318L1.08801 9.39849C1.09983 9.36286 1.15008 9.36286 1.16198 9.39844Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.16198 10.8354L1.25358 11.11L1.54312 11.1122C1.58062 11.1126 1.59614 11.1603 1.566 11.1827L1.33308 11.3547L1.42045 11.6307C1.4318 11.6665 1.39106 11.6961 1.36055 11.6742L1.125 11.5059L0.889451 11.6743C0.858936 11.6961 0.818295 11.6665 0.829545 11.6308L0.91692 11.3547L0.683998 11.1827C0.653858 11.1604 0.669373 11.1126 0.706873 11.1123L0.99642 11.1101L1.08801 10.8354C1.09983 10.7998 1.15008 10.7998 1.16198 10.8354Z"
                fill="#F5F5F5"
            />
            <path
                d="M2.27728 5.79215L2.36887 6.06684L2.65842 6.06904C2.69592 6.06937 2.71143 6.11718 2.68129 6.13945L2.44837 6.31148L2.53575 6.58753C2.54709 6.62324 2.50636 6.65287 2.47584 6.63103L2.24029 6.46265L2.00475 6.63103C1.97423 6.65282 1.93359 6.62324 1.94484 6.58753L2.03222 6.31148L1.79929 6.13945C1.76915 6.11714 1.78467 6.06937 1.82217 6.06904L2.11172 6.06684L2.20331 5.79215C2.21512 5.75662 2.26542 5.75662 2.27728 5.79215Z"
                fill="#F5F5F5"
            />
            <path
                d="M2.27728 7.22908L2.36887 7.50376L2.65842 7.50597C2.69592 7.5063 2.71143 7.55411 2.68129 7.57637L2.44837 7.7484L2.53575 8.02445C2.54709 8.06017 2.50636 8.0898 2.47584 8.06795L2.24029 7.89958L2.00475 8.06795C1.97423 8.08975 1.93359 8.06022 1.94484 8.02445L2.03222 7.7484L1.79929 7.57637C1.76915 7.55415 1.78467 7.5063 1.82217 7.50597L2.11172 7.50376L2.20331 7.22908C2.21512 7.1935 2.26542 7.1935 2.27728 7.22908Z"
                fill="#F5F5F5"
            />
            <path
                d="M2.27728 8.66603L2.36887 8.94071L2.65842 8.94292C2.69592 8.94325 2.71143 8.99101 2.68129 9.01332L2.44837 9.18535L2.53575 9.4614C2.54709 9.49712 2.50636 9.52675 2.47584 9.5049L2.24029 9.33653L2.00475 9.5049C1.97423 9.5267 1.93359 9.49712 1.94484 9.4614L2.03222 9.18535L1.79929 9.01332C1.76915 8.99101 1.78467 8.94325 1.82217 8.94292L2.11172 8.94071L2.20331 8.66603C2.21512 8.63045 2.26542 8.63045 2.27728 8.66603Z"
                fill="#F5F5F5"
            />
            <path
                d="M2.27728 10.1029L2.36887 10.3776L2.65842 10.3798C2.69592 10.3801 2.71143 10.4279 2.68129 10.4502L2.44837 10.6222L2.53575 10.8983C2.54709 10.934 2.50636 10.9636 2.47584 10.9418L2.24029 10.7734L2.00475 10.9418C1.97423 10.9636 1.93359 10.934 1.94484 10.8983L2.03222 10.6222L1.79929 10.4502C1.76915 10.4279 1.78467 10.3801 1.82217 10.3798L2.11172 10.3776L2.20331 10.1029C2.21512 10.0674 2.26542 10.0674 2.27728 10.1029Z"
                fill="#F5F5F5"
            />
            <path
                d="M3.39257 5.08766L3.48417 5.36235L3.77371 5.36455C3.81121 5.36488 3.82673 5.41269 3.79659 5.43495L3.56367 5.60694L3.65104 5.88299C3.66239 5.9187 3.62165 5.94833 3.59114 5.92649L3.35559 5.75811L3.12004 5.92649C3.08953 5.94833 3.04889 5.9187 3.06014 5.88299L3.14751 5.60694L2.91459 5.43495C2.88445 5.41264 2.89996 5.36483 2.93746 5.36455L3.22701 5.36235L3.3186 5.08766C3.33042 5.05208 3.38071 5.05208 3.39257 5.08766Z"
                fill="#F5F5F5"
            />
            <path
                d="M3.39257 6.52457L3.48417 6.79926L3.77371 6.80146C3.81121 6.80179 3.82673 6.84956 3.79659 6.87187L3.56367 7.0439L3.65104 7.31995C3.66239 7.35567 3.62165 7.38529 3.59114 7.36345L3.35559 7.19507L3.12004 7.36345C3.08953 7.38525 3.04889 7.35567 3.06014 7.31995L3.14751 7.0439L2.91459 6.87187C2.88445 6.84956 2.89996 6.80179 2.93746 6.80146L3.22701 6.79926L3.3186 6.52457C3.33042 6.48904 3.38071 6.48904 3.39257 6.52457Z"
                fill="#F5F5F5"
            />
            <path
                d="M3.39257 7.9615L3.48417 8.23619L3.77371 8.23839C3.81121 8.23872 3.82673 8.28648 3.79659 8.3088L3.56367 8.48083L3.65104 8.75687C3.66239 8.79259 3.62165 8.82222 3.59114 8.80037L3.35559 8.632L3.12004 8.80037C3.08953 8.82217 3.04889 8.79264 3.06014 8.75687L3.14751 8.48083L2.91459 8.3088C2.88445 8.28648 2.89996 8.23872 2.93746 8.23839L3.22701 8.23619L3.3186 7.9615C3.33042 7.92592 3.38071 7.92592 3.39257 7.9615Z"
                fill="#F5F5F5"
            />
            <path
                d="M3.39257 9.39845L3.48417 9.67314L3.77371 9.67534C3.81121 9.67567 3.82673 9.72348 3.79659 9.74575L3.56367 9.91778L3.65104 10.1938C3.66239 10.2295 3.62165 10.2592 3.59114 10.2373L3.35559 10.0689L3.12004 10.2373C3.08953 10.2591 3.04889 10.2295 3.06014 10.1938L3.14751 9.91778L2.91459 9.74575C2.88445 9.72353 2.89996 9.67567 2.93746 9.67534L3.22701 9.67314L3.3186 9.39845C3.33042 9.36287 3.38071 9.36287 3.39257 9.39845Z"
                fill="#F5F5F5"
            />
            <path
                d="M3.39257 10.8353L3.48417 11.11L3.77371 11.1122C3.81121 11.1125 3.82673 11.1603 3.79659 11.1826L3.56367 11.3546L3.65104 11.6307C3.66239 11.6664 3.62165 11.696 3.59114 11.6742L3.35559 11.5058L3.12004 11.6742C3.08953 11.696 3.04889 11.6664 3.06014 11.6307L3.14751 11.3546L2.91459 11.1826C2.88445 11.1603 2.89996 11.1125 2.93746 11.1122L3.22701 11.11L3.3186 10.8353C3.33042 10.7998 3.38071 10.7998 3.39257 10.8353Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.50793 5.79215L4.59952 6.06684L4.88907 6.06904C4.92657 6.06937 4.94209 6.11718 4.91195 6.13945L4.67902 6.31148L4.7664 6.58753C4.77774 6.62324 4.73701 6.65287 4.70649 6.63103L4.47095 6.46265L4.2354 6.63103C4.20488 6.65282 4.16424 6.62324 4.17549 6.58753L4.26287 6.31148L4.02995 6.13945C3.9998 6.11714 4.01532 6.06937 4.05282 6.06904L4.34237 6.06684L4.43396 5.79215C4.44573 5.75662 4.49607 5.75662 4.50793 5.79215Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.50793 7.22908L4.59952 7.50376L4.88907 7.50597C4.92657 7.5063 4.94209 7.55411 4.91195 7.57637L4.67902 7.7484L4.7664 8.02445C4.77774 8.06017 4.73701 8.0898 4.70649 8.06795L4.47095 7.89958L4.2354 8.06795C4.20488 8.08975 4.16424 8.06022 4.17549 8.02445L4.26287 7.7484L4.02995 7.57637C3.9998 7.55415 4.01532 7.5063 4.05282 7.50597L4.34237 7.50376L4.43396 7.22908C4.44573 7.1935 4.49607 7.1935 4.50793 7.22908Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.50793 8.66603L4.59952 8.94071L4.88907 8.94292C4.92657 8.94325 4.94209 8.99101 4.91195 9.01332L4.67902 9.18535L4.7664 9.4614C4.77774 9.49712 4.73701 9.52675 4.70649 9.5049L4.47095 9.33653L4.2354 9.5049C4.20488 9.5267 4.16424 9.49712 4.17549 9.4614L4.26287 9.18535L4.02995 9.01332C3.9998 8.99101 4.01532 8.94325 4.05282 8.94292L4.34237 8.94071L4.43396 8.66603C4.44573 8.63045 4.49607 8.63045 4.50793 8.66603Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.50793 10.1029L4.59952 10.3776L4.88907 10.3798C4.92657 10.3801 4.94209 10.4279 4.91195 10.4502L4.67902 10.6222L4.7664 10.8983C4.77774 10.934 4.73701 10.9636 4.70649 10.9418L4.47095 10.7734L4.2354 10.9418C4.20488 10.9636 4.16424 10.934 4.17549 10.8983L4.26287 10.6222L4.02995 10.4502C3.9998 10.4279 4.01532 10.3801 4.05282 10.3798L4.34237 10.3776L4.43396 10.1029C4.44573 10.0674 4.49607 10.0674 4.50793 10.1029Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.62321 5.08777L5.7148 5.36246L6.00435 5.36466C6.04185 5.36499 6.05737 5.41281 6.02723 5.43507L5.7943 5.60705L5.88168 5.8831C5.89302 5.91882 5.85229 5.94845 5.82177 5.9266L5.58618 5.75827L5.35063 5.92665C5.32012 5.94849 5.27948 5.91887 5.29073 5.88315L5.3781 5.6071L5.14518 5.43512C5.11504 5.4128 5.13055 5.36499 5.16805 5.36471L5.4576 5.36251L5.5492 5.08782C5.56106 5.0522 5.61135 5.0522 5.62321 5.08777Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.62321 6.52457L5.7148 6.79926L6.00435 6.80146C6.04185 6.80179 6.05737 6.84956 6.02723 6.87187L5.7943 7.0439L5.88168 7.31995C5.89302 7.35567 5.85229 7.38529 5.82177 7.36345L5.58618 7.19507L5.35063 7.36345C5.32012 7.38525 5.27948 7.35567 5.29073 7.31995L5.3781 7.0439L5.14518 6.87187C5.11504 6.84956 5.13055 6.80179 5.16805 6.80146L5.4576 6.79926L5.5492 6.52457C5.56106 6.48904 5.61135 6.48904 5.62321 6.52457Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.62321 7.9615L5.7148 8.23619L6.00435 8.23839C6.04185 8.23872 6.05737 8.28649 6.02723 8.3088L5.7943 8.48083L5.88168 8.75688C5.89302 8.7926 5.85229 8.82222 5.82177 8.80038L5.58618 8.63196L5.35063 8.80033C5.32012 8.82213 5.27948 8.79255 5.29073 8.75683L5.3781 8.48079L5.14518 8.30875C5.11504 8.28644 5.13055 8.23868 5.16805 8.23835L5.4576 8.23614L5.5492 7.96146C5.56106 7.92593 5.61135 7.92593 5.62321 7.9615Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.62321 9.39845L5.7148 9.67314L6.00435 9.67534C6.04185 9.67567 6.05737 9.72348 6.02723 9.74575L5.7943 9.91778L5.88168 10.1938C5.89302 10.2295 5.85229 10.2592 5.82177 10.2373L5.58618 10.0689L5.35063 10.2373C5.32012 10.2591 5.27948 10.2295 5.29073 10.1938L5.3781 9.91778L5.14518 9.74575C5.11504 9.72353 5.13055 9.67567 5.16805 9.67534L5.4576 9.67314L5.5492 9.39845C5.56106 9.36287 5.61135 9.36287 5.62321 9.39845Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.62321 10.8353L5.7148 11.11L6.00435 11.1122C6.04185 11.1125 6.05737 11.1603 6.02723 11.1826L5.7943 11.3546L5.88168 11.6307C5.89302 11.6664 5.85229 11.696 5.82177 11.6742L5.58618 11.5058L5.35063 11.6742C5.32012 11.696 5.27948 11.6664 5.29073 11.6307L5.3781 11.3546L5.14518 11.1826C5.11504 11.1603 5.13055 11.1125 5.16805 11.1122L5.4576 11.11L5.5492 10.8353C5.56106 10.7998 5.61135 10.7998 5.62321 10.8353Z"
                fill="#F5F5F5"
            />
            <path
                d="M6.73852 5.79215L6.83011 6.06684L7.11966 6.06904C7.15716 6.06937 7.17268 6.11718 7.14254 6.13945L6.90961 6.31148L6.99699 6.58753C7.00833 6.62324 6.9676 6.65287 6.93708 6.63103L6.70154 6.46265L6.46599 6.63103C6.43547 6.65282 6.39483 6.62324 6.40608 6.58753L6.49346 6.31148L6.26054 6.13945C6.2304 6.11714 6.24591 6.06937 6.28341 6.06904L6.57296 6.06684L6.66455 5.79215C6.67641 5.75662 6.72666 5.75662 6.73852 5.79215Z"
                fill="#F5F5F5"
            />
            <path
                d="M6.73852 7.22908L6.83011 7.50376L7.11966 7.50597C7.15716 7.5063 7.17268 7.55411 7.14254 7.57637L6.90961 7.7484L6.99699 8.02445C7.00833 8.06017 6.9676 8.0898 6.93708 8.06795L6.70154 7.89958L6.46599 8.06795C6.43547 8.08975 6.39483 8.06022 6.40608 8.02445L6.49346 7.7484L6.26054 7.57637C6.2304 7.55415 6.24591 7.5063 6.28341 7.50597L6.57296 7.50376L6.66455 7.22908C6.67641 7.1935 6.72666 7.1935 6.73852 7.22908Z"
                fill="#F5F5F5"
            />
            <path
                d="M6.73852 8.66603L6.83011 8.94071L7.11966 8.94292C7.15716 8.94325 7.17268 8.99101 7.14254 9.01332L6.90961 9.18535L6.99699 9.4614C7.00833 9.49712 6.9676 9.52675 6.93708 9.5049L6.70154 9.33653L6.46599 9.5049C6.43547 9.5267 6.39483 9.49712 6.40608 9.4614L6.49346 9.18535L6.26054 9.01332C6.2304 8.99101 6.24591 8.94325 6.28341 8.94292L6.57296 8.94071L6.66455 8.66603C6.67641 8.63045 6.72666 8.63045 6.73852 8.66603Z"
                fill="#F5F5F5"
            />
            <path
                d="M6.73852 10.1029L6.83011 10.3776L7.11966 10.3798C7.15716 10.3801 7.17268 10.4279 7.14254 10.4502L6.90961 10.6222L6.99699 10.8983C7.00833 10.934 6.9676 10.9636 6.93708 10.9418L6.70154 10.7734L6.46599 10.9418C6.43547 10.9636 6.39483 10.934 6.40608 10.8983L6.49346 10.6222L6.26054 10.4502C6.2304 10.4279 6.24591 10.3801 6.28341 10.3798L6.57296 10.3776L6.66455 10.1029C6.67641 10.0674 6.72666 10.0674 6.73852 10.1029Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.85385 5.08766L7.94544 5.36235L8.23499 5.36455C8.27249 5.36488 8.288 5.41269 8.25786 5.43495L8.02494 5.60694L8.11232 5.88299C8.12366 5.9187 8.08292 5.94833 8.05241 5.92649L7.81686 5.75811L7.58132 5.92649C7.5508 5.94833 7.51016 5.9187 7.52141 5.88299L7.60878 5.60694L7.37586 5.43495C7.34572 5.41264 7.36124 5.36483 7.39874 5.36455L7.68828 5.36235L7.77988 5.08766C7.79164 5.05208 7.84199 5.05208 7.85385 5.08766Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.85385 6.52457L7.94544 6.79926L8.23499 6.80146C8.27249 6.80179 8.288 6.84956 8.25786 6.87187L8.02494 7.0439L8.11232 7.31995C8.12366 7.35567 8.08292 7.38529 8.05241 7.36345L7.81686 7.19507L7.58132 7.36345C7.5508 7.38525 7.51016 7.35567 7.52141 7.31995L7.60878 7.0439L7.37586 6.87187C7.34572 6.84956 7.36124 6.80179 7.39874 6.80146L7.68828 6.79926L7.77988 6.52457C7.79164 6.48904 7.84199 6.48904 7.85385 6.52457Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.85385 7.9615L7.94544 8.23619L8.23499 8.23839C8.27249 8.23872 8.288 8.28648 8.25786 8.3088L8.02494 8.48083L8.11232 8.75687C8.12366 8.79259 8.08292 8.82222 8.05241 8.80037L7.81686 8.632L7.58132 8.80037C7.5508 8.82217 7.51016 8.79264 7.52141 8.75687L7.60878 8.48083L7.37586 8.3088C7.34572 8.28648 7.36124 8.23872 7.39874 8.23839L7.68828 8.23619L7.77988 7.9615C7.79164 7.92592 7.84199 7.92592 7.85385 7.9615Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.85385 9.39845L7.94544 9.67314L8.23499 9.67534C8.27249 9.67567 8.288 9.72348 8.25786 9.74575L8.02494 9.91778L8.11232 10.1938C8.12366 10.2295 8.08292 10.2592 8.05241 10.2373L7.81686 10.0689L7.58132 10.2373C7.5508 10.2591 7.51016 10.2295 7.52141 10.1938L7.60878 9.91778L7.37586 9.74575C7.34572 9.72353 7.36124 9.67567 7.39874 9.67534L7.68828 9.67314L7.77988 9.39845C7.79164 9.36287 7.84199 9.36287 7.85385 9.39845Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.85385 10.8353L7.94544 11.11L8.23499 11.1122C8.27249 11.1125 8.288 11.1603 8.25786 11.1826L8.02494 11.3546L8.11232 11.6307C8.12366 11.6664 8.08292 11.696 8.05241 11.6742L7.81686 11.5058L7.58132 11.6742C7.5508 11.696 7.51016 11.6664 7.52141 11.6307L7.60878 11.3546L7.37586 11.1826C7.34572 11.1603 7.36124 11.1125 7.39874 11.1122L7.68828 11.11L7.77988 10.8353C7.79164 10.7998 7.84199 10.7998 7.85385 10.8353Z"
                fill="#F5F5F5"
            />
            <path
                d="M8.96914 5.79215L9.06074 6.06684L9.35028 6.06904C9.38778 6.06937 9.4033 6.11718 9.37316 6.13945L9.14024 6.31148L9.22761 6.58753C9.23895 6.62324 9.19822 6.65287 9.1677 6.63103L8.93216 6.46265L8.69661 6.63103C8.6661 6.65282 8.62545 6.62324 8.6367 6.58753L8.72408 6.31148L8.49116 6.13945C8.46102 6.11714 8.47653 6.06937 8.51403 6.06904L8.80358 6.06684L8.89517 5.79215C8.90699 5.75662 8.95728 5.75662 8.96914 5.79215Z"
                fill="#F5F5F5"
            />
            <path
                d="M8.96914 7.22908L9.06074 7.50376L9.35028 7.50597C9.38778 7.5063 9.4033 7.55411 9.37316 7.57637L9.14024 7.7484L9.22761 8.02445C9.23895 8.06017 9.19822 8.0898 9.1677 8.06795L8.93216 7.89958L8.69661 8.06795C8.6661 8.08975 8.62545 8.06022 8.6367 8.02445L8.72408 7.7484L8.49116 7.57637C8.46102 7.55415 8.47653 7.5063 8.51403 7.50597L8.80358 7.50376L8.89517 7.22908C8.90699 7.1935 8.95728 7.1935 8.96914 7.22908Z"
                fill="#F5F5F5"
            />
            <path
                d="M8.96914 8.66603L9.06074 8.94071L9.35028 8.94292C9.38778 8.94325 9.4033 8.99101 9.37316 9.01332L9.14024 9.18535L9.22761 9.4614C9.23895 9.49712 9.19822 9.52675 9.1677 9.5049L8.93216 9.33653L8.69661 9.5049C8.6661 9.5267 8.62545 9.49712 8.6367 9.4614L8.72408 9.18535L8.49116 9.01332C8.46102 8.99101 8.47653 8.94325 8.51403 8.94292L8.80358 8.94071L8.89517 8.66603C8.90699 8.63045 8.95728 8.63045 8.96914 8.66603Z"
                fill="#F5F5F5"
            />
            <path
                d="M8.96914 10.1029L9.06074 10.3776L9.35028 10.3798C9.38778 10.3801 9.4033 10.4279 9.37316 10.4502L9.14024 10.6222L9.22761 10.8983C9.23895 10.934 9.19822 10.9636 9.1677 10.9418L8.93216 10.7734L8.69661 10.9418C8.6661 10.9636 8.62545 10.934 8.6367 10.8983L8.72408 10.6222L8.49116 10.4502C8.46102 10.4279 8.47653 10.3801 8.51403 10.3798L8.80358 10.3776L8.89517 10.1029C8.90699 10.0674 8.95728 10.0674 8.96914 10.1029Z"
                fill="#F5F5F5"
            />
            <path
                d="M10.0845 5.08766L10.1761 5.36235L10.4656 5.36455C10.5031 5.36488 10.5187 5.41269 10.4885 5.43495L10.2556 5.60694L10.343 5.88299C10.3543 5.9187 10.3136 5.94833 10.2831 5.92649L10.0475 5.75811L9.81197 5.92649C9.78145 5.94833 9.74081 5.9187 9.75206 5.88299L9.83944 5.60694L9.60651 5.43495C9.57637 5.41264 9.59189 5.36483 9.62939 5.36455L9.91894 5.36235L10.0105 5.08766C10.0223 5.05208 10.0726 5.05208 10.0845 5.08766Z"
                fill="#F5F5F5"
            />
            <path
                d="M10.0845 6.52457L10.1761 6.79926L10.4656 6.80146C10.5031 6.80179 10.5187 6.84956 10.4885 6.87187L10.2556 7.0439L10.343 7.31995C10.3543 7.35567 10.3136 7.38529 10.2831 7.36345L10.0475 7.19507L9.81197 7.36345C9.78145 7.38525 9.74081 7.35567 9.75206 7.31995L9.83944 7.0439L9.60651 6.87187C9.57637 6.84956 9.59189 6.80179 9.62939 6.80146L9.91894 6.79926L10.0105 6.52457C10.0223 6.48904 10.0726 6.48904 10.0845 6.52457Z"
                fill="#F5F5F5"
            />
            <path
                d="M10.0845 7.9615L10.1761 8.23619L10.4656 8.23839C10.5031 8.23872 10.5187 8.28648 10.4885 8.3088L10.2556 8.48083L10.343 8.75687C10.3543 8.79259 10.3136 8.82222 10.2831 8.80037L10.0475 8.632L9.81197 8.80037C9.78145 8.82217 9.74081 8.79264 9.75206 8.75687L9.83944 8.48083L9.60651 8.3088C9.57637 8.28648 9.59189 8.23872 9.62939 8.23839L9.91894 8.23619L10.0105 7.9615C10.0223 7.92592 10.0726 7.92592 10.0845 7.9615Z"
                fill="#F5F5F5"
            />
            <path
                d="M10.0845 9.39845L10.1761 9.67314L10.4656 9.67534C10.5031 9.67567 10.5187 9.72348 10.4885 9.74575L10.2556 9.91778L10.343 10.1938C10.3543 10.2295 10.3136 10.2592 10.2831 10.2373L10.0475 10.0689L9.81197 10.2373C9.78145 10.2591 9.74081 10.2295 9.75206 10.1938L9.83944 9.91778L9.60651 9.74575C9.57637 9.72353 9.59189 9.67567 9.62939 9.67534L9.91894 9.67314L10.0105 9.39845C10.0223 9.36287 10.0726 9.36287 10.0845 9.39845Z"
                fill="#F5F5F5"
            />
            <path
                d="M10.0845 10.8353L10.1761 11.11L10.4656 11.1122C10.5031 11.1125 10.5187 11.1603 10.4885 11.1826L10.2556 11.3546L10.343 11.6307C10.3543 11.6664 10.3136 11.696 10.2831 11.6742L10.0475 11.5058L9.81197 11.6742C9.78145 11.696 9.74081 11.6664 9.75206 11.6307L9.83944 11.3546L9.60651 11.1826C9.57637 11.1603 9.59189 11.1125 9.62939 11.1122L9.91894 11.11L10.0105 10.8353C10.0223 10.7998 10.0726 10.7998 10.0845 10.8353Z"
                fill="#F5F5F5"
            />
        </svg>
    </SvgIcon>
);
