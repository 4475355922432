import IconBase, { IconBaseProps } from '../IconBase';

export const AddressIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0001 3.125C7.14008 3.125 4.79175 5.51476 4.79175 8.5C4.79175 9.96417 5.434 11.3327 6.48997 12.743C7.38124 13.9334 8.52193 15.0971 9.7392 16.339C9.82579 16.4273 9.91278 16.5161 10.0001 16.6053C10.0874 16.5161 10.1744 16.4273 10.261 16.339C11.4782 15.0971 12.6189 13.9334 13.5102 12.743C14.5662 11.3327 15.2084 9.96417 15.2084 8.5C15.2084 5.51476 12.8601 3.125 10.0001 3.125ZM3.54175 8.5C3.54175 4.85783 6.41676 1.875 10.0001 1.875C13.5834 1.875 16.4584 4.85783 16.4584 8.5C16.4584 10.3495 15.6423 11.981 14.5108 13.4922C13.565 14.7554 12.3543 15.99 11.1381 17.2301C10.9078 17.465 10.6772 17.7001 10.4482 17.9357C10.3305 18.0567 10.1689 18.125 10.0001 18.125C9.83127 18.125 9.66964 18.0567 9.55196 17.9357C9.32296 17.7001 9.09239 17.465 8.86202 17.2301C7.64583 15.9899 6.43517 14.7554 5.48936 13.4922C4.35783 11.981 3.54175 10.3495 3.54175 8.5ZM10.0001 7.29167C9.42479 7.29167 8.95841 7.75804 8.95841 8.33333C8.95841 8.90863 9.42479 9.375 10.0001 9.375C10.5754 9.375 11.0417 8.90863 11.0417 8.33333C11.0417 7.75804 10.5754 7.29167 10.0001 7.29167ZM7.70841 8.33333C7.70841 7.06768 8.73443 6.04167 10.0001 6.04167C11.2657 6.04167 12.2917 7.06768 12.2917 8.33333C12.2917 9.59899 11.2657 10.625 10.0001 10.625C8.73443 10.625 7.70841 9.59899 7.70841 8.33333Z"
            fill="currentColor"
        />
    </IconBase>
);
