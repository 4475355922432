import IconBase, { IconBaseProps } from '../IconBase';

export const CustomCloudIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4595 3.75C11.2104 3.75 10.1374 4.51834 9.68745 5.54985C9.58488 5.785 9.36925 5.95141 9.11578 5.99101C7.66174 6.2182 6.75 7.3743 6.75 8.56026C6.75 10.0139 7.99344 11.25 9.6 11.25L15 11.25C16.2681 11.25 17.25 10.2647 17.25 9.10513C17.25 8.21992 16.6905 7.39571 15.9086 7.06609C15.6456 6.95519 15.4683 6.70459 15.4513 6.41963C15.3643 4.96022 14.0861 3.75 12.4595 3.75ZM11.25 12.75L9.6 12.75C7.23011 12.75 5.25 10.906 5.25 8.56026C5.25 6.71946 6.55869 5.06342 8.49119 4.58783C9.24725 3.21866 10.7396 2.25 12.4595 2.25C14.672 2.25 16.5514 3.80231 16.8988 5.88764C18.0122 6.53681 18.75 7.77274 18.75 9.10513C18.75 11.1431 17.0456 12.75 15 12.75L12.75 12.75V14.325C14.2193 14.6233 15.3767 15.7807 15.675 17.25H21C21.4142 17.25 21.75 17.5858 21.75 18C21.75 18.4142 21.4142 18.75 21 18.75H15.675C15.3275 20.4617 13.8142 21.75 12 21.75C10.1858 21.75 8.67247 20.4617 8.32501 18.75H3C2.58579 18.75 2.25 18.4142 2.25 18C2.25 17.5858 2.58579 17.25 3 17.25H8.32501C8.62328 15.7807 9.78065 14.6233 11.25 14.325V12.75ZM12 15.75C10.7574 15.75 9.75 16.7574 9.75 18C9.75 19.2426 10.7574 20.25 12 20.25C13.2426 20.25 14.25 19.2426 14.25 18C14.25 16.7574 13.2426 15.75 12 15.75Z"
            fill="currentColor"
        />
    </IconBase>
);
