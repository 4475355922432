import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ResendIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.34687 2.99668C5.89157 1.74843 7.85869 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 9.57395 1.34538 9.22857 1.77143 9.22857C2.19748 9.22857 2.54286 9.57395 2.54286 10C2.54286 14.1185 5.88153 17.4571 10 17.4571C14.1185 17.4571 17.4571 14.1185 17.4571 10C17.4571 5.88153 14.1185 2.54286 10 2.54286C8.28792 2.54286 6.71103 3.11925 5.45198 4.08973H6.66116C7.08721 4.08973 7.43259 4.43511 7.43259 4.86116C7.43259 5.28721 7.08721 5.63259 6.66116 5.63259H3.57545C3.1494 5.63259 2.80402 5.28721 2.80402 4.86116V1.77545C2.80402 1.3494 3.1494 1.00402 3.57545 1.00402C4.00149 1.00402 4.34687 1.3494 4.34687 1.77545V2.99668ZM10 5.11429C10.426 5.11429 10.7714 5.45967 10.7714 5.88571V9.68046L13.1169 12.0259C13.4182 12.3272 13.4182 12.8156 13.1169 13.1169C12.8156 13.4182 12.3272 13.4182 12.0259 13.1169L9.45452 10.5455C9.30985 10.4008 9.22857 10.2046 9.22857 10V5.88571C9.22857 5.45967 9.57395 5.11429 10 5.11429Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
