import { inputBaseClasses, SxProps, Theme } from '@mui/material';

export const plusN: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(0.5, 1),
    border: '1px solid',
    borderRadius: 1,
    borderColor: 'custom.gray.light400',
});

export const select: SxProps = {
    [`& .${inputBaseClasses.input}`]: {
        padding: 0,
    },

    '&:hover': {
        color: 'info.main',
    },

    '& svg': {
        margin: 0,
    },
};
