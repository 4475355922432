import cc from 'classcat';
import styles from './WorkflowAside.module.scss';
import { WorkflowAsideItem } from './WorkflowAsideItem';
import { WorkflowActionTypes, WorkflowConditionTypes } from '../types';

export const WorkflowAsideSteps = () => {
    return (
        <div className={styles['aside__content']}>
            <section className={styles['aside__box']}>
                <h5 className={styles['aside__box-title']}>
                    <span
                        className={cc([
                            styles['aside__box-title-dot'],
                            styles['purple'],
                        ])}
                    />
                    Assigned Inbox
                </h5>
                <ul className={styles['aside__box-list']}>
                    <WorkflowAsideItem label={WorkflowActionTypes.SendSMS} />
                    <WorkflowAsideItem label={WorkflowActionTypes.Webhook} />
                    <WorkflowAsideItem
                        label={WorkflowActionTypes.AddToCohort}
                    />
                    <WorkflowAsideItem
                        label={WorkflowActionTypes.AssignTeamMember}
                    />
                </ul>
            </section>

            <section className={styles['aside__box']}>
                <h5 className={styles['aside__box-title']}>
                    <span
                        className={cc([
                            styles['aside__box-title-dot'],
                            styles['green'],
                        ])}
                    />
                    Time
                </h5>
                <ul className={styles['aside__box-list']}>
                    <WorkflowAsideItem
                        label={WorkflowActionTypes.Sleep}
                        disabled
                    />
                </ul>
            </section>

            <section className={styles['aside__box']}>
                <h5 className={styles['aside__box-title']}>
                    <span
                        className={cc([
                            styles['aside__box-title-dot'],
                            styles['orange'],
                        ])}
                    />
                    Flow control
                </h5>
                <ul className={styles['aside__box-list']}>
                    <WorkflowAsideItem
                        label={WorkflowConditionTypes.LastContacted}
                    />
                    <WorkflowAsideItem
                        label={WorkflowConditionTypes.Schedule}
                    />
                    <WorkflowAsideItem
                        label={WorkflowConditionTypes.TimeFrame}
                    />
                    <WorkflowAsideItem label={WorkflowConditionTypes.Filter} />
                </ul>
            </section>
        </div>
    );
};
