import { useMutation } from '@tanstack/react-query';
import { sendOTP, SendOtpResp, verifyOTP } from '../api/auth';
import { Verification } from '../api/types';

export function useSendOTP() {
    return useMutation<SendOtpResp, unknown, string, unknown>({
        mutationKey: ['auth_send_otp'],
        mutationFn: (email) => sendOTP({ email: email.trim() }),
    });
}

export type VerifyOtpDto = {
    email: string;
};

export function useVerifyOTP() {
    return useMutation<unknown, unknown, Verification, unknown>({
        mutationKey: ['auth_verify_otp'],
        mutationFn: (dto) =>
            verifyOTP({
                email: dto.email.trim(),
                method_id: dto.method_id,
                code: dto.code,
                microsoftTenantId: dto.microsoftTenantId,
            }),
    });
}
