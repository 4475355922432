import styles from './AttachmentItem.module.scss';
import { PublicFile } from '../../api/types';
import Icon from '../../icons/Icon';
import { FilesIcons } from '../../icons/iconsMap';
import { formatFileSize } from '../../utils/format';
import { downloadResource } from '../../utils/downloads';
import AttachmentImageItem from '../AttachmentImageItem';

const getFileType = (attachment: PublicFile): FilesIcons => {
    switch (attachment.type) {
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'xls';

        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'doc';

        case 'application/pdf':
            return 'pdf';
    }

    const name = attachment.name || '';

    if (/.*\.doc(x?)$/g.test(name)) {
        return 'doc';
    }

    if (/.*\.xls(x?)$/g.test(name)) {
        return 'xls';
    }

    if (/.*\.pdf$/g.test(name)) {
        return 'pdf';
    }

    return 'other';
};

export interface AttachmentFileItemProps {
    attachment: PublicFile;
    downloadUrl: string;
}

export const AttachmentFileItem = (props: AttachmentFileItemProps) => (
    <div
        className={styles['root']}
        onClick={() =>
            downloadResource(
                `${import.meta.env.VITE_API_BASE_URL}/files/${
                    props.attachment.id
                }`,
                props.attachment.name || 'file',
            )
        }
    >
        <div className={styles['root__icon']}>
            <Icon
                className={styles['root__icon-download']}
                name="full-arrow-down"
            />
            <Icon
                className={styles['root__icon-file']}
                size="32px"
                viewBox="0 0 32 32"
                name={getFileType(props.attachment)}
            />
        </div>
        <div className={styles['root__meta-data']}>
            <div className={styles['root__name']}>{props.attachment.name}</div>
            <div className={styles['root__size']}>
                {formatFileSize(props.attachment.size)}
            </div>
        </div>
    </div>
);

export interface AttachmentItemProps {
    attachment: PublicFile;
}

export const AttachmentItem = (props: AttachmentItemProps) => {
    const isImage =
        props.attachment?.type && props.attachment.type.includes('image');
    const downloadUrl = `${import.meta.env.VITE_API_BASE_URL}/files/${
        props.attachment.id
    }`;

    return isImage ? (
        <AttachmentImageItem attachment={props.attachment} />
    ) : (
        <AttachmentFileItem
            attachment={props.attachment}
            downloadUrl={downloadUrl}
        />
    );
};

export default AttachmentItem;
