import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const RefreshIcon = (props: SvgIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.57692 2.5C3.89555 2.5 4.15385 2.7583 4.15385 3.07692V5.625L5.49985 4.40986C6.82636 3.22261 8.57948 2.5 10.5 2.5C14.6421 2.5 18 5.85786 18 10C18 10.3186 17.7417 10.5769 17.4231 10.5769C17.1045 10.5769 16.8462 10.3186 16.8462 10C16.8462 6.49512 14.0049 3.65385 10.5 3.65385C8.8747 3.65385 7.39331 4.26408 6.27028 5.26883C6.26998 5.2691 6.26968 5.26937 6.26937 5.26964L5.07693 6.34615H7.42308C7.7417 6.34615 8 6.60445 8 6.92308C8 7.2417 7.7417 7.5 7.42308 7.5H3.57692C3.2583 7.5 3 7.2417 3 6.92308V3.07692C3 2.7583 3.2583 2.5 3.57692 2.5ZM3.57692 9.42308C3.89555 9.42308 4.15385 9.68137 4.15385 10C4.15385 13.5049 6.99512 16.3462 10.5 16.3462C12.1253 16.3462 13.6067 15.7359 14.7297 14.7312C14.73 14.7309 14.7303 14.7306 14.7306 14.7304L15.9231 13.6538H13.5769C13.2583 13.6538 13 13.3955 13 13.0769C13 12.7583 13.2583 12.5 13.5769 12.5H17.4231C17.7417 12.5 18 12.7583 18 13.0769V16.9231C18 17.2417 17.7417 17.5 17.4231 17.5C17.1045 17.5 16.8462 17.2417 16.8462 16.9231V14.375L15.502 15.5885L15.5001 15.5901C14.1736 16.7774 12.4205 17.5 10.5 17.5C6.35786 17.5 3 14.1421 3 10C3 9.68137 3.2583 9.42308 3.57692 9.42308Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
