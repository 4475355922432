import IconBase, { IconBaseProps } from '../IconBase';

export const DeIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M22.2026 4.13794H1.79742C0.804703 4.13794 0 4.94264 0 5.93536V9.37931H24V5.93536C24 4.94264 23.1953 4.13794 22.2026 4.13794Z"
            fill="#464655"
        />
        <path
            d="M0 18.0646C0 19.0573 0.804703 19.862 1.79742 19.862H22.2026C23.1952 19.862 24 19.0573 24 18.0646V14.6207H0V18.0646Z"
            fill="#FFE15A"
        />
        <path d="M24 9.37921H0V14.6203H24V9.37921Z" fill="#FF4B55" />
    </IconBase>
);
