import IconBase, { IconBaseProps } from '../IconBase';

export const CampaignIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M3 8.2004C3 7.08029 3 6.52024 3.21799 6.09242C3.40973 5.71609 3.71569 5.41013 4.09202 5.21838C4.51984 5.0004 5.0799 5.0004 6.2 5.0004H9.67452C10.1637 5.0004 10.4083 5.0004 10.6385 5.05566C10.8425 5.10465 11.0376 5.18546 11.2166 5.29512C11.4184 5.4188 11.5914 5.59175 11.9373 5.93766L12.0627 6.06314C12.4086 6.40904 12.5816 6.58199 12.7834 6.70568C12.9624 6.81533 13.1575 6.89614 13.3615 6.94514C13.5917 7.0004 13.8363 7.0004 14.3255 7.0004H17.8C18.9201 7.0004 19.4802 7.0004 19.908 7.21838C20.2843 7.41013 20.5903 7.71609 20.782 8.09242C21 8.52024 21 9.08029 21 10.2004V15.8004C21 16.9205 21 17.4806 20.782 17.9084C20.5903 18.2847 20.2843 18.5907 19.908 18.7824C19.4802 19.0004 18.9201 19.0004 17.8 19.0004H6.2C5.07989 19.0004 4.51984 19.0004 4.09202 18.7824C3.71569 18.5907 3.40973 18.2847 3.21799 17.9084C3 17.4806 3 16.9205 3 15.8004V8.2004Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
