import IconBase, { IconBaseProps } from '../IconBase';

export const CountryIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M12 18C16.9706 18 21 13.9706 21 9C21 7.15984 20.4477 5.44866 19.4999 4.02317M12 18C8.87958 18 6.13007 16.412 4.51555 14M12 18V21M7 21H17M21 3L19.5 4M4.5 14L3 15M17 9C17 11.7614 14.7614 14 12 14C9.23858 14 7 11.7614 7 9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
