import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const ScheduledIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.54167 2.5C8.54167 2.15482 8.82149 1.875 9.16667 1.875H12.5C12.8452 1.875 13.125 2.15482 13.125 2.5C13.125 2.84518 12.8452 3.125 12.5 3.125H9.16667C8.82149 3.125 8.54167 2.84518 8.54167 2.5ZM10.833 4.79167C7.75254 4.79167 5.20969 7.09769 4.83813 10.0773C4.79542 10.4199 4.48312 10.6629 4.1406 10.6202C3.79807 10.5775 3.55503 10.2652 3.59774 9.92266C4.04635 6.32512 7.11415 3.54167 10.833 3.54167C14.8601 3.54167 18.1247 6.80626 18.1247 10.8333C18.1247 14.8604 14.8601 18.125 10.833 18.125H6.66667C6.32149 18.125 6.04167 17.8452 6.04167 17.5C6.04167 17.1548 6.32149 16.875 6.66667 16.875H10.833C14.1697 16.875 16.8747 14.1701 16.8747 10.8333C16.8747 7.49661 14.1697 4.79167 10.833 4.79167ZM10.8333 6.875C11.1785 6.875 11.4583 7.15482 11.4583 7.5V10.8333C11.4583 11.1785 11.1785 11.4583 10.8333 11.4583C10.4882 11.4583 10.2083 11.1785 10.2083 10.8333V7.5C10.2083 7.15482 10.4882 6.875 10.8333 6.875ZM1.875 12.5C1.875 12.1548 2.15482 11.875 2.5 11.875H6.66667C7.01184 11.875 7.29167 12.1548 7.29167 12.5C7.29167 12.8452 7.01184 13.125 6.66667 13.125H2.5C2.15482 13.125 1.875 12.8452 1.875 12.5ZM3.54167 15C3.54167 14.6548 3.82149 14.375 4.16667 14.375H8.33333C8.67851 14.375 8.95833 14.6548 8.95833 15C8.95833 15.3452 8.67851 15.625 8.33333 15.625H4.16667C3.82149 15.625 3.54167 15.3452 3.54167 15Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
