import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ScheduledIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.83333 2C6.83333 1.72386 7.05719 1.5 7.33333 1.5H10C10.2761 1.5 10.5 1.72386 10.5 2C10.5 2.27614 10.2761 2.5 10 2.5H7.33333C7.05719 2.5 6.83333 2.27614 6.83333 2ZM8.66642 3.83333C6.20203 3.83333 4.16775 5.67815 3.87051 8.06187C3.83634 8.33589 3.5865 8.53033 3.31248 8.49616C3.03846 8.46199 2.84402 8.21215 2.87819 7.93813C3.23708 5.0601 5.69132 2.83333 8.66642 2.83333C11.8881 2.83333 14.4998 5.44501 14.4998 8.66667C14.4998 11.8883 11.8881 14.5 8.66642 14.5H5.33333C5.05719 14.5 4.83333 14.2761 4.83333 14C4.83333 13.7239 5.05719 13.5 5.33333 13.5H8.66642C11.3358 13.5 13.4998 11.336 13.4998 8.66667C13.4998 5.99729 11.3358 3.83333 8.66642 3.83333ZM8.66667 5.5C8.94281 5.5 9.16667 5.72386 9.16667 6V8.66667C9.16667 8.94281 8.94281 9.16667 8.66667 9.16667C8.39052 9.16667 8.16667 8.94281 8.16667 8.66667V6C8.16667 5.72386 8.39052 5.5 8.66667 5.5ZM1.5 10C1.5 9.72386 1.72386 9.5 2 9.5H5.33333C5.60948 9.5 5.83333 9.72386 5.83333 10C5.83333 10.2761 5.60948 10.5 5.33333 10.5H2C1.72386 10.5 1.5 10.2761 1.5 10ZM2.83333 12C2.83333 11.7239 3.05719 11.5 3.33333 11.5H6.66667C6.94281 11.5 7.16667 11.7239 7.16667 12C7.16667 12.2761 6.94281 12.5 6.66667 12.5H3.33333C3.05719 12.5 2.83333 12.2761 2.83333 12Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
