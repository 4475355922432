import { Box, Skeleton } from '@mui/material';
import { MessageSkeleton } from '../MessageSkeleton';
import { arrow } from '../CampaignMessage/message.styles';

export const HistorySkeleton = () => (
    <Box sx={{ width: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: 1 }}>
            <Skeleton width={45} height={14} />
        </Box>
        <MessageSkeleton>
            <Box display="flex" alignItems="center" ml={2}>
                <Box sx={arrow} role="presentation" />
                <Skeleton width={105} height={14} sx={{ ml: 1, mt: 3 }} />
            </Box>
        </MessageSkeleton>
    </Box>
);
