import TableViewIcon from '@mui/icons-material/TableView';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import { isAllowed } from './mime-types';
import { MimeType } from '../../../../constants';
import {
    PdfSourceIcon,
    WordSourceIcon,
} from '../../../../icons/common/files/ExtensionIcon';

export const getMimeIcon = (mimeType?: string | MimeType) => {
    if (!isAllowed(mimeType)) {
        return FilePresentOutlinedIcon;
    }
    return (
        {
            [MimeType.PDF]: PdfSourceIcon,
            [MimeType.CSV]: TableViewIcon,
            [MimeType.DOCX]: WordSourceIcon,
            [MimeType.DOC]: WordSourceIcon,
        }[mimeType] || FilePresentOutlinedIcon
    );
};
