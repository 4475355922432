import client from './http';
import { Role, RoleTypes } from './types';

export function setRole({
    userId,
    role,
}: {
    userId: string;
    role: RoleTypes;
}): Promise<Role> {
    return client()
        .post<Role>('/roles/updateForUser', { userId, role })
        .then((r) => r.data);
}
