import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const SelectComponentIcon = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6534 4.44301L18.058 6.88974C18.6429 7.4849 18.2344 8.50516 17.4081 8.50516H12.5988C11.7725 8.50516 11.3547 7.4849 11.9396 6.88974L14.3443 4.44301C14.7064 4.07458 15.2913 4.07458 15.6534 4.44301ZM14.3466 15.5569L11.9419 13.1102C11.357 12.5151 11.7655 11.4948 12.5918 11.4948H17.4011C18.2274 11.4948 18.6452 12.5151 18.0603 13.1102L15.6557 15.5569C15.2936 15.9254 14.7086 15.9254 14.3466 15.5569Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
