import IconBase, { IconBaseProps } from '../IconBase';

export const MicrosoftTeamsLogoIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M15.9503 9.5H21.1163C21.6044 9.5 22 9.92532 22 10.45V15.5085C22 17.4368 20.5459 19 18.7521 19H18.7368C16.943 19.0003 15.4887 17.4373 15.4884 15.509C15.4884 15.5088 15.4884 15.5087 15.4884 15.5085V9.9965C15.4884 9.72229 15.6952 9.5 15.9503 9.5Z"
            fill="#5059C9"
        />
        <path
            d="M19.4419 8.50006C20.5978 8.50006 21.5349 7.4927 21.5349 6.25006C21.5349 5.00742 20.5978 4.00006 19.4419 4.00006C18.286 4.00006 17.3489 5.00742 17.3489 6.25006C17.3489 7.4927 18.286 8.50006 19.4419 8.50006Z"
            fill="#5059C9"
        />
        <path
            d="M12.9302 8.5C14.5999 8.5 15.9535 7.04493 15.9535 5.25C15.9535 3.45508 14.5999 2 12.9302 2C11.2605 2 9.90698 3.45508 9.90698 5.25C9.90698 7.04493 11.2605 8.5 12.9302 8.5Z"
            fill="#7B83EB"
        />
        <path
            d="M16.9614 9.5H8.43396C7.95171 9.51283 7.57021 9.94306 7.5814 10.4615V16.231C7.51406 19.3421 9.80371 21.9239 12.6977 22C15.5917 21.9239 17.8813 19.3421 17.814 16.231V10.4615C17.8251 9.94306 17.4437 9.51283 16.9614 9.5Z"
            fill="#7B83EB"
        />
        <path
            opacity="0.1"
            d="M13.1628 9.5V17.585C13.1605 17.9558 12.9515 18.2888 12.6326 18.43C12.531 18.4762 12.4219 18.5 12.3117 18.5H7.99072C7.93026 18.335 7.87445 18.17 7.82793 18C7.66512 17.4263 7.58204 16.8298 7.58142 16.23V10.46C7.57024 9.94239 7.9511 9.51283 8.43258 9.5H13.1628Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M12.6977 9.5V18.085C12.6977 18.2035 12.6755 18.3208 12.6326 18.43C12.5013 18.7728 12.1914 18.9975 11.8465 19H8.20933C8.13026 18.835 8.05584 18.67 7.99072 18.5C7.92561 18.33 7.87445 18.17 7.82793 18C7.66512 17.4263 7.58204 16.8298 7.58142 16.23V10.46C7.57024 9.94239 7.9511 9.51283 8.43258 9.5H12.6977Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M12.6977 9.5V17.085C12.6942 17.5888 12.3152 17.9962 11.8465 18H7.82793C7.66512 17.4263 7.58204 16.8298 7.58142 16.23V10.46C7.57024 9.94239 7.9511 9.51283 8.43258 9.5H12.6977Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M12.2326 9.5V17.085C12.229 17.5888 11.85 17.9962 11.3814 18H7.82793C7.66512 17.4263 7.58204 16.8298 7.58142 16.23V10.46C7.57024 9.94239 7.9511 9.51283 8.43258 9.5H12.2326Z"
            fill="black"
        />
        <path
            opacity="0.1"
            d="M13.1628 6.91502V8.49002C13.0838 8.49502 13.0093 8.50002 12.9303 8.50002C12.8512 8.50002 12.7768 8.49503 12.6977 8.49002C12.5407 8.47882 12.385 8.45204 12.2326 8.41002C11.2907 8.17025 10.5126 7.46016 10.1396 6.50003C10.0754 6.33879 10.0255 6.17137 9.99072 6.00003H12.3117C12.781 6.00194 13.161 6.41047 13.1628 6.91502Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M12.6977 7.41505V8.49005C12.5407 8.47885 12.385 8.45208 12.2326 8.41006C11.2907 8.17028 10.5126 7.4602 10.1395 6.50006H11.8465C12.3158 6.50197 12.6959 6.91051 12.6977 7.41505Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M12.6977 7.41505V8.49005C12.5407 8.47885 12.385 8.45208 12.2326 8.41006C11.2907 8.17028 10.5126 7.4602 10.1395 6.50006H11.8465C12.3158 6.50197 12.6959 6.91051 12.6977 7.41505Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M12.2326 7.41506V8.41006C11.2907 8.17028 10.5126 7.4602 10.1395 6.50006H11.3814C11.8507 6.50198 12.2308 6.91052 12.2326 7.41506Z"
            fill="black"
        />
        <path
            d="M2.85255 6.50006H11.38C11.8509 6.50006 12.2326 6.9104 12.2326 7.41656V16.5836C12.2326 17.0897 11.8508 17.5 11.38 17.5H2.85255C2.3817 17.5 2 17.0897 2 16.5836V7.41656C2 6.9104 2.38171 6.50006 2.85255 6.50006Z"
            fill="#5A62C3"
        />
        <path
            d="M9.36 9.98893H7.65534V14.9789H6.5693V9.98893H4.87256V9.02094H9.36V9.98893Z"
            fill="white"
        />
    </IconBase>
);
