import { SxProps } from '@mui/material';

export const toggleButton: SxProps = {
    cursor: 'pointer',
    userSelect: 'none',
    marginBottom: 4,
};

export const expandIcon: SxProps = { width: 20, height: 20 };

export const collapseIcon: SxProps = {
    width: 20,
    height: 20,
    backgroundColor: 'custom.gray.actionSelected',
    borderRadius: 1,
};

export const label: SxProps = { height: 20, lineHeight: '20px' };
