import client from './http';
import { PlanType } from '../containers/PaymentFlow/plans';

interface StripeIntent {
    secret: string;
}

export const createIntent = (
    planType: PlanType,
    priceId: string,
): Promise<StripeIntent> => {
    return client()
        .post('stripe/intent', {
            planType,
            priceId,
        })
        .then<StripeIntent>((response) => response.data);
};
