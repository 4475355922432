import IconBase, { IconBaseProps } from '../IconBase';

export const FullArrowDownIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M12 19L12 5M12 19L7 14M12 19L17 14"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);
