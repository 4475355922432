import IconBase, { IconBaseProps } from '../IconBase';

export const PowerOffIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M16 6.07026C18.3912 7.45349 20 10.0389 20 13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13C4 10.0389 5.60879 7.45349 8 6.07026M12 3V13"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </IconBase>
);
